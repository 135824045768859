import { EFinancingProgram } from '@src/data/types'
import allApi from '../../api'
import { ElectronicSignature } from '../../types/ElectronicSignature'
import { makeSave } from '../effects/standard-side-effects'
import { RESEND_LINK_ELECTRONIC_SIGN, electronicSignatureSlice } from './electronic-signature-store'

const electronicSignatureEffects = {
  resendLinkForElectronicSignature: (financingProgramId: EFinancingProgram, id: string) =>
    makeSave<ElectronicSignature>(
      RESEND_LINK_ELECTRONIC_SIGN,
      allApi.electronicSignature.resendLink,
      electronicSignatureSlice.actions.setElectronicSignature,
      { financingProgramId, creditApplicationId: id },
    ),
}

export default electronicSignatureEffects
