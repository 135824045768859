import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { CreditReportCollection } from '@src/data/types'
import { FormatCurrencyNoDecimals, formatDate } from '@src/services/Formatter'
import { uniqueId } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import Row from './tableRow'

interface CollectionItemsProps {
  collectionItems: CreditReportCollection[]
}

const CollectionItems = ({ collectionItems }: CollectionItemsProps) => {
  const { t } = useTranslation()
  return (
    <Row id="collectionItems" name={t('creditReport.collectionItems')}>
      <TableContainer>
        <Table sx={{ display: 'contents' }} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{t('creditReport.code')}</TableCell>
              <TableCell align="left">{t('creditReport.creditor')}</TableCell>
              <TableCell align="left">{t('creditReport.description')}</TableCell>
              <TableCell align="left">{t('creditReport.registrationDate')}</TableCell>
              <TableCell align="left">{t('creditReport.originalAmount')}</TableCell>
              <TableCell align="left">{t('creditReport.balanceAmount')}</TableCell>
              <TableCell align="left">{t('creditReport.dateOfLastPmt')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collectionItems.map((item) => (
              <TableRow key={uniqueId()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" align="left">
                  {item.code}
                </TableCell>
                <TableCell align="left">
                  {item.collectionCreditorNameOrAccountNumber ?? t('creditReport.creditorUnknown')}
                </TableCell>
                <TableCell align="left">{item.codeDescription}</TableCell>
                <TableCell align="left">{item.assignedYearMonth}</TableCell>
                <TableCell align="left">{FormatCurrencyNoDecimals(item.originalAmount)}</TableCell>
                <TableCell align="left">{FormatCurrencyNoDecimals(item.balanceAmount)}</TableCell>
                <TableCell align="left">{formatDate(item.dateOfLastPmt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Row>
  )
}

export default CollectionItems
