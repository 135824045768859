import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { setDefaultOptions } from 'date-fns'
import { enCA, frCA } from 'date-fns/locale'
import { type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  children: ReactNode
}

// le format de date CA n'est pas défini correctement dans date-fns
const dateFormats = {
  full: 'EEEE d MMMM yyyy',
  long: 'd MMMM yyyy',
  medium: 'd MMM yyyy',
  short: 'yyyy-MM-dd',
}

// Original source function. Import do not work
const buildFormatLongFn = (args: { formats: Record<string, string>; defaultWidth: string }) => {
  return (options: { width?: string } = {}) => {
    const width = options.width ? String(options.width) : args.defaultWidth
    const format = args.formats[width] || args.formats[args.defaultWidth]
    return format
  }
}

frCA.formatLong!.date = buildFormatLongFn({
  formats: dateFormats,
  defaultWidth: 'full',
})

/**
 * third party lib, except MUI Core and plugins translation, locale initialisation
 * MUI components translation needs to be set in the theme
 */
const LocaleProvider = ({ children }: Props) => {
  const { i18n } = useTranslation()

  const locale = i18n.resolvedLanguage === 'fr' ? frCA : enCA
  setDefaultOptions({ locale })

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      {children}
    </LocalizationProvider>
  )
}

export default LocaleProvider
