import React from 'react'
import { Typography } from '@mui/material'
import { ActionsDialog } from '.'

interface Props {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
  title: string
  text: string
}

const ConfirmActionDialog = ({ onConfirm, onCancel, open, title, text }: Props) => {
  return (
    <ActionsDialog onCancel={onCancel} onConfirm={onConfirm} open={open} title={title}>
      <div style={{ width: 400 }}>
        <Typography sx={{ mt: 2 }}>{text}</Typography>
      </div>
    </ActionsDialog>
  )
}

export default React.memo(ConfirmActionDialog)
