import { useSideEffect } from '@src/data/store/effects/side-effects'
import { reportErrorToConsole } from '@src/services/error-logger'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../data/store'
import { appStoreEffects } from '../../data/store/AppStore'
import { userActions, userEffects } from '../../data/store/UserStore'

const AuthPage = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const dispatchEffect = useSideEffect()
  const navigate = useNavigate()

  // dispatchEffect change ici car on load le user dans la config, ce qui fait que le useEffect serait déclenché en boucle infinie
  useEffect(() => {
    dispatch(userActions.loadPreferences())
    Promise.all([dispatchEffect(appStoreEffects.initialize()), dispatchEffect(userEffects.loadUser())])
      .then(() => navigate('/home', { replace: true }))
      .catch(reportErrorToConsole)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate])

  return <div />
}

export default AuthPage
