import { Divider, FormControlLabel, Grid, Paper, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoanRefinancingOutputDto } from '../../../data/api/credit-application/LoanRefinancingInputDto'
import { FormatCurrency, formatDate, formatNumber, formatPhone } from '../../../services/Formatter'

interface Props {
  loans: LoanRefinancingOutputDto[]
  handleSelectedloan: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleClickTotalLoss: (e: React.ChangeEvent<HTMLInputElement>) => void
  selectedLoan: string
  selectedLoss: boolean
  handleEngagement: () => void
  applyEngagement: boolean
}

const LoanDetails = ({
  loans,
  handleSelectedloan,
  selectedLoan,
  handleClickTotalLoss,
  selectedLoss,
  handleEngagement,
  applyEngagement,
}: Props) => {
  const { t } = useTranslation()
  return (
    <div style={{ textAlign: 'left', width: '55rem' }}>
      <RadioGroup value={selectedLoan} onChange={handleSelectedloan}>
        <Paper
          elevation={3}
          sx={{ width: '100%', height: 'auto', padding: 2, marginBottom: 2, marginTop: 2, textAlign: 'center' }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography margin={1} sx={{ fontWeight: 'bold' }}>
              {t('credit.noRefinancingApplicable')}
            </Typography>
            <FormControlLabel
              control={<Radio name="item" sx={{ float: 'right' }} value="noRefinancing" />}
              label={undefined}
            />
          </Stack>
        </Paper>

        <Stack spacing={2} direction="column">
          {loans.map((item) => (
            <Paper
              elevation={3}
              sx={{ width: '100%', height: 'auto', paddingTop: 2, paddingLeft: 3, paddingRight: 0, paddingBottom: 2 }}
              key={item.loanId}
            >
              <Typography variant="h6" component="p">
                {`${item.firstName} ${item.lastName}`}
              </Typography>
              <Divider sx={{ width: '100%', marginBottom: 1 }} />
              <Grid container>
                <Grid item xs={6} width="100%" marginTop={2}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography component="p">{t('credit.fileNumber')}: </Typography>
                      <Typography component="p">{t('credit.shortBirthdate')}: </Typography>
                      <Typography component="p">{t('creditReport.sin')}: </Typography>
                      <Typography component="p">{t('credit.homePhone')}: </Typography>
                      <Typography component="p">{t('credit.mobilePhone')}: </Typography>
                      <Typography component="p">{t('credit.address')}: </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography component="p">{item.cvtNumber}</Typography>
                      <Typography component="p">{formatDate(item.birthDate)}</Typography>
                      <Typography component="p">{item.nas ? item.nas : 'N/A'}</Typography>
                      <Typography component="p">{formatPhone(item.phoneHome)}</Typography>
                      <Typography component="p">{formatPhone(item.phoneCell)}</Typography>
                      <Typography component="p">{item.address}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5} width="50%">
                  <Stack direction="column" spacing={2}>
                    <div>
                      <Typography component="p" variant="h6">
                        {t('credit.commitment')}
                      </Typography>
                      <Grid container>
                        <Grid item xs={5}>
                          <Typography component="p"> Admissible:</Typography>
                          <Typography component="p">{t('credit.suggestedRate')}: </Typography>
                          <Typography component="p">{t('credit.commitmentDiscount')}: </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <span>
                            <input
                              type="radio"
                              name="engagementYes"
                              id="oui"
                              onChange={handleEngagement}
                              checked={item.loanId === selectedLoan && applyEngagement}
                            />
                            <label htmlFor="oui">{t('common.yes')}</label>
                            <input
                              type="radio"
                              name="engagementNo"
                              id="non"
                              onChange={handleEngagement}
                              checked={item.loanId === selectedLoan && !applyEngagement}
                            />
                            <label htmlFor="non">{t('common.no')}</label>
                          </span>

                          <Typography component="p">{`${formatNumber(item.suggestedInterestRate)}%`}</Typography>
                          <Typography component="p">
                            {FormatCurrency(item.suggestedEngagementDiscountAmount)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                    <div>
                      <Typography component="p" variant="h6">
                        {t('credit.totalLoss')}
                      </Typography>
                      <Grid container>
                        <Grid item xs={5}>
                          <Typography component="p"> {t('credit.totalLoss')}: </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <span>
                            <input
                              type="radio"
                              name={item.loanId}
                              id="oui"
                              onChange={handleClickTotalLoss}
                              checked={item.loanId === selectedLoan && selectedLoss}
                            />
                            <label htmlFor="oui">{t('common.yes')}</label>
                            <input
                              type="radio"
                              name={item.loanId}
                              id="non"
                              onChange={handleClickTotalLoss}
                              checked={item.loanId === selectedLoan && !selectedLoss}
                            />
                            <label htmlFor="non">{t('common.no')}</label>
                          </span>
                        </Grid>
                      </Grid>
                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={1} sx={{ marginTop: '8%' }}>
                  <RadioGroup value={selectedLoan} onChange={handleSelectedloan} sx={{ margin: 0, padding: 0 }}>
                    <FormControlLabel
                      sx={{ margin: 0, padding: 0 }}
                      control={<Radio value={item.loanId} name="item" key={item.loanId} />}
                      label={undefined}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Stack>
      </RadioGroup>
    </div>
  )
}

export default LoanDetails
