import { Box, Grid, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import { NormsMessage } from '../../../data/types'

interface Props {
  messageTitle: string
  normsMessages: NormsMessage[] | null
  size: string | null
}

const WarningMessageList = ({ normsMessages, messageTitle, size }: Props) => {
  const theme = useTheme()
  const borderColor = theme.palette.divider

  return (
    <Grid item xs={12} md={12} lg={size === 'half' ? 6 : 12}>
      <Box
        sx={{
          border: '1px solid',
          borderColor,
          borderRadius: 2,
          p: 1,
        }}
      >
        <Typography>{messageTitle}</Typography>
        <ul>{normsMessages?.map((x) => <li key={x.id}>{x.message}</li>)}</ul>
      </Box>
    </Grid>
  )
}

export default WarningMessageList
