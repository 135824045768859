import { EPaymentFrequency } from '@src/data/types/constants'
import { normalizeDateTime } from '@src/services/Formatter'
import { addDays, addWeeks, addMonths, isBefore } from 'date-fns'

export const getProductsMinMaxPaymentDates = (
  deliveryDateStr: string,
  paymentFrequency: EPaymentFrequency,
): [Date, Date] => {
  const parsedDate = normalizeDateTime(deliveryDateStr)
  const minDate = addDays(parsedDate, 2)
  let maxDate: Date
  switch (paymentFrequency) {
    // 2 day buffer between delivery and first payment
    // Up to 1 month from the delivery for monthly payments
    // Up to 2 weeks from the delivery for every two weeks payments
    case EPaymentFrequency.Monthly:
      maxDate = addMonths(parsedDate, 1)
      break
    case EPaymentFrequency.BiWeekly:
      maxDate = addWeeks(parsedDate, 2)
      break
    default:
      maxDate = addDays(parsedDate, 5)
      break
  }
  return [minDate, maxDate]
}

export const isValidFirstPayment = (deliveryDateStr: string, firstPaymentOn: Date) => {
  const minValidPaymentDate = addDays(normalizeDateTime(deliveryDateStr), 2)
  const normalizedFirstPaymentDate = normalizeDateTime(firstPaymentOn)
  return !isBefore(normalizedFirstPaymentDate, minValidPaymentDate)
}
