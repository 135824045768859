import { BlockBlobClient } from '@azure/storage-blob'
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import allApi from '@src/data/api'
import { apiStateActions } from '@src/data/store/ApiStateStore'
import { appActions } from '@src/data/store/AppStore'
import {
  makeDelete,
  makeDownloadWithObject,
  makeGetListWithIds,
  makeSave,
} from '@src/data/store/effects/standard-side-effects'
import {
  Constants,
  Document,
  DocumentDecision,
  DocumentPage,
  DocumentRequired,
  EFinancingProgram,
} from '@src/data/types'
import { DocumentPageDisplayInfo } from '@src/data/types/DocumentPageDisplayInfo'
import { DocumentTransformationResult } from '@src/data/types/DocumentTransformationResult'
import convertFileToArrayBuffer from '@src/services/BlobStorage'
import { AxiosInstance } from 'axios'
import type { TRootState } from '..'
import { BusinessError } from '../../types/BusinessError'
import {
  APPLY_TRANSFORMATION,
  DELETE_DOCUMENT_REQUEST,
  documentSlice,
  DOWNLOAD_DOCUMENT,
  GET_ALL_DOCUMENT,
  GET_ALL_PAGES,
  GET_ALL_REQUIRED_DOCUMENT,
  GET_THUMBNAILS,
  MAKE_DECISIONS,
  POST_CREATE_DOCUMENT_REQUEST,
  QUICK_MERGE,
  SEND_DOCUMENT,
  UPDATE_DOCUMENT_REQUEST,
} from './document-store'

// 10MB
const MAX_FILE_SIZE = 10485760
const documentEffects = {
  create: (data: DocumentRequired[], creditApplicationId: string, financingProgramId: EFinancingProgram) => {
    return async (
      apiClient: AxiosInstance,
      dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
      /* select: TypedUseSelectorHook<TRootState>, */
    ): Promise<DocumentRequired[]> => {
      try {
        // log api is loading
        dispatch(apiStateActions.logApiCallInitiated(POST_CREATE_DOCUMENT_REQUEST))
        dispatch(appActions.setBusinessErrors([]))

        // perform actual request
        const ret = await allApi.document.create(apiClient, data, creditApplicationId, financingProgramId)
        dispatch(documentSlice.actions.addMultipleRequiredDocuments(ret))
        return ret
      } finally {
        dispatch(apiStateActions.logApiCallCompleted(POST_CREATE_DOCUMENT_REQUEST))
      }
    }
  },

  getDocumentList: (creditApplicationId: string, financingProgramId: EFinancingProgram) =>
    makeGetListWithIds<Document>(
      GET_ALL_DOCUMENT,
      allApi.document.getDocumentList,
      documentSlice.actions.setDocumentList,
      {
        creditApplicationId,
        financingProgramId,
      },
    ),

  getDocumentPages: (creditApplicationId: string, financingProgramId: EFinancingProgram) =>
    makeGetListWithIds<DocumentPage>(
      GET_ALL_PAGES,
      allApi.document.getDocumentPageList,
      documentSlice.actions.setDocumentPageList,
      {
        creditApplicationId,
        financingProgramId,
      },
    ),

  getRequiredDocumentList: (creditApplicationId: string) =>
    makeGetListWithIds<DocumentRequired>(
      GET_ALL_REQUIRED_DOCUMENT,
      allApi.document.getRequiredDocumentList,
      documentSlice.actions.setRequiredDocumentList,
      {
        creditApplicationId,
      },
    ),

  updateRequireDocument: (data: DocumentRequired) =>
    makeSave<DocumentRequired>(
      UPDATE_DOCUMENT_REQUEST,
      allApi.document.updateRequiredDocument,
      documentSlice.actions.setCurrentDocumentRequired,
      data,
    ),

  sendDocument: (data: Document[], files: File[]) => {
    return async (
      apiClient: AxiosInstance,
      dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
      /* select: TypedUseSelectorHook<TRootState>, */
    ): Promise<Document[]> => {
      try {
        // log api is loading
        dispatch(apiStateActions.logApiCallInitiated(SEND_DOCUMENT))
        dispatch(appActions.setBusinessErrors([]))

        // Check file sizes before uploading
        const oversizedFiles = files.filter((file) => file.size > MAX_FILE_SIZE)
        const unsupportedFiles = files.filter((file) => {
          const fileExtension = file.name.split('.').pop()?.toLowerCase() ?? ''
          const isExtensionSupported = Constants.SupportedDocumentTypes.includes(fileExtension)
          return !isExtensionSupported
        })

        if (oversizedFiles.length > 0 || unsupportedFiles.length > 0) {
          const errors: BusinessError[] = []

          if (oversizedFiles.length > 0) errors.push({ message: 'document.fileSizeTooBig' })

          if (unsupportedFiles.length > 0)
            errors.push({
              message: 'document.unsupportedFileType',
              params: { supportedTypes: Constants.SupportedDocumentTypes },
            })

          dispatch(appActions.setBusinessErrors(errors))
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }, 100)
          return []
        }
        // perform actual request
        const ret = await allApi.document.uploadDocV2(
          apiClient,
          data,
          data[0].creditApplicationId,
          data[0].financingProgramId,
        )

        ret.forEach((doc) => dispatch(documentSlice.actions.setCurrentDocument(doc)))
        files.forEach(async (file, index) => {
          await convertFileToArrayBuffer(file).then((fileArrayBuffer) => {
            // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
            if (fileArrayBuffer === null || fileArrayBuffer.byteLength < 1) return Promise.reject()

            const concernedFileSASUrl = ret.find((doc) => doc.fileName === `${index}-${file.name}`)?.sasTokenUrl
            const blockBlobClient = new BlockBlobClient(concernedFileSASUrl!)
            return blockBlobClient.uploadData(fileArrayBuffer)
          })
        })
        return ret
      } finally {
        dispatch(apiStateActions.logApiCallCompleted(SEND_DOCUMENT))
      }
    }
  },

  updatePrivateDocument: (data: FormData, creditApplicationId: string, document: Document) => {
    return async (
      apiClient: AxiosInstance,
      dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
      /* select: TypedUseSelectorHook<TRootState>, */
    ): Promise<Document> => {
      try {
        // log api is loading
        dispatch(apiStateActions.logApiCallInitiated(UPDATE_DOCUMENT_REQUEST))
        dispatch(appActions.setBusinessErrors([]))

        // perform actual request
        const ret = await allApi.document.updatePrivateDocument(apiClient, data, creditApplicationId, document)
        dispatch(documentSlice.actions.removePrivateDocument(document))
        dispatch(documentSlice.actions.setCurrentDocument(ret))
        return ret
      } finally {
        dispatch(apiStateActions.logApiCallCompleted(UPDATE_DOCUMENT_REQUEST))
      }
    }
  },

  sendPrivateDocument: (data: FormData) => {
    return async (
      apiClient: AxiosInstance,
      dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
      /* select: TypedUseSelectorHook<TRootState>, */
    ): Promise<Document> => {
      try {
        // log api is loading
        dispatch(apiStateActions.logApiCallInitiated(SEND_DOCUMENT))
        dispatch(appActions.setBusinessErrors([]))

        const doc: Document = JSON.parse(data.get('payload') as string) as Document

        // perform actual request
        const ret = await allApi.document.uploadDocPrivate(
          apiClient,
          data,
          doc.creditApplicationId,
          doc.financingProgramId,
        )
        dispatch(documentSlice.actions.setCurrentDocument(ret))
        return ret
      } finally {
        dispatch(apiStateActions.logApiCallCompleted(SEND_DOCUMENT))
      }
    }
  },
  deletePrivateDocument: (data: Document) =>
    makeDelete<Document>(
      DELETE_DOCUMENT_REQUEST,
      allApi.document.deletePrivateDocument,
      documentSlice.actions.removePrivateDocument,
      data,
    ),

  deleteRequiredDocument: (data: DocumentRequired) =>
    makeDelete<DocumentRequired>(
      DELETE_DOCUMENT_REQUEST,
      allApi.document.deleteRequiredDocument,
      documentSlice.actions.removeRequiredDocument,
      data,
    ),

  getDocumentsThumbnailsForSplit: (
    data: string[],
    creditApplicationId: string,
    financingProgramId: EFinancingProgram,
  ) =>
    makeSave<string[], DocumentPageDisplayInfo[]>(
      GET_THUMBNAILS,
      allApi.document.getThumbnailsForSplit(creditApplicationId, financingProgramId),
      null,
      data,
    ),

  applyDocsTransformation: (data: Document[], creditApplicationId: string, financingProgramId: EFinancingProgram) =>
    makeSave<Document[], DocumentTransformationResult>(
      APPLY_TRANSFORMATION,
      allApi.document.applyDocsTransformation(creditApplicationId, financingProgramId),
      documentSlice.actions.setDocumentTransformationResult,
      data,
    ),

  quickMerge: (data: string[], creditApplicationId: string, financingProgramId: EFinancingProgram) =>
    makeSave<string[], DocumentTransformationResult>(
      QUICK_MERGE,
      allApi.document.quickMerge(creditApplicationId, financingProgramId),
      documentSlice.actions.setDocumentTransformationResult,
      data,
    ),

  makeDecisions: (data: DocumentDecision[], creditApplicationId: string, financingProgramId: EFinancingProgram) =>
    makeSave<DocumentDecision[], DocumentTransformationResult>(
      MAKE_DECISIONS,
      allApi.document.makeDecisions(creditApplicationId, financingProgramId),
      documentSlice.actions.setDocumentTransformationResult,
      data,
    ),

  downloadDocument: (documentId: string, creditApplicationId: string, financingProgramId: EFinancingProgram) =>
    makeDownloadWithObject(
      DOWNLOAD_DOCUMENT,
      allApi.document.downloadDocument(creditApplicationId, financingProgramId, documentId),
      document,
    ),
}

export default documentEffects
