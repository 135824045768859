import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Toolbar, Button, TextField, Grid } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import MainActions from './MainActions'
import { CreditDashboardFilters } from '../../data/types/CreditDashboardEntry'
import SearchFilter from './SearchFilters'

interface Props {
  filterValues: CreditDashboardFilters
  resetFilter: () => void
  applyFilter: (data: CreditDashboardFilters) => void
  addDays: (date: Date, days: number) => Date
}

const DataGridHeader = ({ filterValues, resetFilter: resetFilters, applyFilter, addDays }: Props): JSX.Element => {
  const { t } = useTranslation()

  const [contains, setContains] = useState<string>(filterValues.contains)
  const [openSearchFilters, setOpenSearchFilters] = React.useState<boolean>(false)

  const onCancel = useCallback(() => {
    setOpenSearchFilters(false)
  }, [setOpenSearchFilters])

  useEffect(() => {
    setContains(filterValues.contains)
  }, [filterValues])

  const onApplyFilters = () => {
    applyFilter({
      ...filterValues,
      contains,
    })
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onApplyFilters()
      }}
    >
      <Toolbar disableGutters sx={{ m: 3 }}>
        <Grid container columnSpacing={2}>
          <Grid item sx={{ mt: 1 }}>
            <MainActions />
          </Grid>
          <Grid item md={3}>
            <TextField
              label={t('browseCreditApplications.dataGridHeader.appClientName')}
              onChange={(e) => setContains(e.target.value)}
              value={contains}
              fullWidth
            />
          </Grid>
          <Grid item md={3}>
            <Button type="submit" size="large">
              <SearchIcon fontSize="large" color="action" />
            </Button>
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                setOpenSearchFilters(true)
              }}
            >
              <FilterAltIcon fontSize="medium" sx={{ color: 'white' }} />
            </Button>
          </Grid>
          {openSearchFilters ? (
            <SearchFilter
              open={openSearchFilters}
              filterValues={filterValues}
              resetFilter={resetFilters}
              applyFilter={applyFilter}
              addDays={addDays}
              onCancel={onCancel}
            />
          ) : null}
        </Grid>
      </Toolbar>
    </form>
  )
}

export default DataGridHeader
