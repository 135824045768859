import { Button, Card, CardActions, CardContent, Typography } from '@mui/material'
import { creditEffects } from '@src/data/store/CreditApplication'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { CreditApplication, EApplicantType, ECreditApplicationStatus } from '@src/data/types'
import { reportErrorToConsole } from '@src/services/error-logger'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  creditApplication: CreditApplication
}

const VouchedSection = ({ creditApplication }: Props) => {
  const { t } = useTranslation()
  const dispatchEffect = useSideEffect()
  const [loading, setLoading] = useState(false)

  const resetVouched = () => {
    setLoading(true)
    dispatchEffect(
      creditEffects.resetVouched({
        creditApplicationId: creditApplication.id,
        financingProgramId: creditApplication.financingProgramId,
        applicantType: EApplicantType.Applicant,
      }),
    )
      .then(() => {
        setLoading(false)
      })
      .catch(reportErrorToConsole)
  }

  return (
    <Card elevation={3} sx={{ mt: 3, mb: 3 }}>
      <CardContent>
        <Typography component="div" variant="h5">
          {t('credit.vouched.title')}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          disabled={creditApplication.status !== ECreditApplicationStatus.Draft || loading}
          onClick={resetVouched}
        >
          {t('credit.vouched.reset')}
        </Button>
      </CardActions>
    </Card>
  )
}

export default memo(VouchedSection)
