import { CircularProgress, Stack, Typography } from '@mui/material'
import { ProductsComputeResultDto } from '@src/data/types/ProductsComputeResultDto'
import { ProductsWorksheet } from '@src/data/types/ProductsWorksheetSchema'
import { useTranslation } from 'react-i18next'
import { FormatCurrency } from '../../../services/Formatter'

interface Props {
  worksheet: ProductsWorksheet
  computedInfo: ProductsComputeResultDto
  interestsRate: number
  isFetching?: boolean
}

const ProductsSummaryBox = ({ worksheet, computedInfo, interestsRate, isFetching }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      {isFetching && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
      {computedInfo && (
        <>
          <Typography variant="h6" component="h3" gutterBottom>
            {t('worksheet.sideSection.loanSummary')}
          </Typography>
          <Stack direction="row" justifyContent="space-between" marginLeft={5} marginRight={5}>
            <Typography variant="subtitle1">{t('worksheetCommon.amountRequested')} :</Typography>
            <Typography variant="subtitle1">{FormatCurrency(worksheet?.amountRequested)}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" marginLeft={5} marginRight={5}>
            <Typography>{`${t('worksheetCommon.interest')} (${interestsRate ?? 0}%)`}: </Typography>
            <Typography>{FormatCurrency(worksheet?.totalInterestAmount)}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" marginLeft={5} marginRight={5}>
            <Typography variant="subtitle1">{t('worksheet.totalAmountFinanced')} :</Typography>
            <Typography variant="subtitle1">{FormatCurrency(worksheet?.totalAmountFinanced)}</Typography>
          </Stack>
        </>
      )}
    </>
  )
}

export default ProductsSummaryBox
