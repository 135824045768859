import { EFinancingCompany } from '@src/data/types'
import { AgentActionLogEntrySDA, AgentLogEntryDetail, AgentLogEntrySummary } from '@src/data/types/AgentLogEntry'
import { getApiClient } from '@src/services/api-client'
import { useQuery } from '@tanstack/react-query'

const SCOPE = 'reports/agentLogs'

export interface AgentLogFilter {
  financingCompanyId: EFinancingCompany
  startDate: Date
  endDate: Date
}

export interface AgentLogEntrySummaryResult {
  list: AgentLogEntrySummary[]
  standardDeviationAveragePairs: AgentActionLogEntrySDA | null
}

const agentLogSummaries = async (filters: AgentLogFilter) => {
  const response = await getApiClient().get<AgentLogEntrySummaryResult>('/Reports/AgentLogs', {
    params: filters,
  })
  return response.data
}

export function useAgentLogSummaries(filters: AgentLogFilter): [AgentLogEntrySummaryResult | null, boolean] {
  const query = useQuery({
    queryKey: [SCOPE, filters],
    queryFn: () => agentLogSummaries(filters),
  })
  return [query.data ?? null, query.isLoading]
}

const agentLogDetails = async (userId: string, filters: AgentLogFilter) => {
  const response = await getApiClient().get<AgentLogEntryDetail[]>(`/Reports/AgentLogs/${userId}`, {
    params: filters,
  })
  return response.data
}

export function useAgentLogDetails(
  userId: string | null,
  filters: AgentLogFilter,
): [AgentLogEntryDetail[] | null, boolean] {
  const query = useQuery({
    queryKey: [SCOPE, userId, filters],
    queryFn: () => (userId ? agentLogDetails(userId, filters) : null),
    enabled: !!userId,
  })
  return [query.data ?? null, query.isLoading]
}
