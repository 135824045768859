import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { findIndex } from 'lodash-es'
import type { TRootState } from '..'
import type { CreditApplicationMessage } from '../../types'
import { apiStateSelectors } from '../ApiStateStore'

export const messageSlice = createSlice({
  name: 'message',
  initialState: {
    messageList: [] as CreditApplicationMessage[],
  },
  reducers: {
    setMessageList: (state, action: PayloadAction<CreditApplicationMessage[]>) => {
      state.messageList = action.payload
    },
    addMessage: (state, action: PayloadAction<CreditApplicationMessage | null>) => {
      if (action.payload === null) return
      state.messageList.push(action.payload)
    },
    removeMessage: (state, action: PayloadAction<CreditApplicationMessage>) => {
      const index = findIndex(state.messageList, { id: action.payload.id })
      state.messageList.splice(index, 1)
    },
  },
})

export default messageSlice.reducer

export const messageActions = messageSlice.actions

export const GET_MESSAGE = 'message/getMessageList'
export const SAVE_MESSAGE = 'message/save'
export const SEND_REQUIRED_DOCUMENTS_MESSAGE = 'message/SendRequiredDocumentsMessage'
export const DELETE_MESSAGE = 'message/delete'

const messageSelectorsPartial = {
  isLoadingMessageList: (state: TRootState) => apiStateSelectors.isLoading(state, GET_MESSAGE),
  isSavingMessage: (state: TRootState) => apiStateSelectors.isLoading(state, SAVE_MESSAGE),
  isSendingRequiredDocumentsMessage: (state: TRootState) =>
    apiStateSelectors.isLoading(state, SEND_REQUIRED_DOCUMENTS_MESSAGE),
  getMessageList: (state: TRootState) => state.message.messageList,
  getPrivateMessages: (state: TRootState) => state.message.messageList.filter((x) => x.isPrivate),
}

export const messageSelectors = {
  ...messageSelectorsPartial,
}
