import {
  Info as InfoIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material/'
import {
  Collapse,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { IconButtonWithTooltip } from '../../../components'
import { TRootState, useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import {
  CreditApplication,
  CreditApplicationTask,
  EApplicantType,
  EFinancingProgram,
  ETasksStatus,
} from '../../../data/types'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'
import { formatDateTime } from '../../../services/Formatter'
import ActionsTasks from '../../BrowseCreditApplicationsPage/inlineActionsTasks'
import StatusTasks from '../../BrowseCreditApplicationsPage/inlineStatusTasks'
import TaskDescription from './TaskDescription'

interface RowProps {
  task: CreditApplicationTask
  onOpenTaskDialog: (task: CreditApplicationTask) => void
  onOpenCommentDialog: (task: CreditApplicationTask) => void
  onClickStatusButton: (status: ETasksStatus, task: CreditApplicationTask) => void
  onOpenTaskSuppressionDialog: (task: CreditApplicationTask) => void
  creditApplication: CreditApplication
  userCanEditTask: boolean
  editDisabled: boolean
}

const TaskRow = ({
  task,
  onOpenTaskDialog,
  onOpenCommentDialog,
  onClickStatusButton,
  onOpenTaskSuppressionDialog,
  creditApplication,
  userCanEditTask,
  editDisabled,
}: RowProps) => {
  const { t } = useTranslation()
  const [showComments, setShowComments] = React.useState(false)
  const { financingProgramId } = useParams()
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum
  const taskTypes = useAppSelector((state: TRootState) =>
    appSelectors.getTaskTypes(state, financingProgramId as EFinancingProgram),
  )

  const isTaskForApplicant = task?.applicantType === EApplicantType.Applicant
  const isTaskForCoApplicant = task?.applicantType === EApplicantType.CoApplicant
  let taskNameSuffix = ''
  if (isTaskForApplicant) taskNameSuffix = ` (${t('common.applicant')})`
  else if (isTaskForCoApplicant) taskNameSuffix = ` (${t('common.coApplicant')})`

  return (
    <>
      <TableRow>
        <TableCell>
          {taskTypes[task.typeId][lang] as string}
          {taskNameSuffix}
        </TableCell>
        <TableCell align="center">
          <TaskDescription task={task} creditApplication={creditApplication} />
        </TableCell>
        <TableCell align="center">
          {task.requiredBeforeStep && t(`enum.eCreditApplicationSteps.${task.requiredBeforeStep}`)}
        </TableCell>
        <TableCell align="center">{formatDateTime(task.reminderOn)}</TableCell>
        <TableCell align="center">
          <StatusTasks
            task={task}
            onClickStatusButton={onClickStatusButton}
            userCanEditTask={userCanEditTask}
            editDisabled={editDisabled}
          />
        </TableCell>
        {userCanEditTask && (
          <TableCell component="th" scope="row" align="center">
            <ActionsTasks
              editTask={onOpenTaskDialog}
              newComment={onOpenCommentDialog}
              removeTask={onOpenTaskSuppressionDialog}
              task={task}
              editDisabled={editDisabled}
            />
          </TableCell>
        )}
        {(task.status === ETasksStatus.Completed || task.status === ETasksStatus.Cancelled) && (
          <TableCell>
            <IconButtonWithTooltip
              tooltip={`${t('common.updatedOn')} ${formatDateTime(task.updatedOn)} ${t('common.by').toLowerCase()} ${
                task.updatedByUserFullname
              } `}
              icon={<InfoIcon />}
              disabled
            />
          </TableCell>
        )}

        <TableCell>
          {task.comments && task.comments.length > 0 && (
            <IconButton aria-label="expand row" size="small" onClick={() => setShowComments(!showComments)}>
              {showComments ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      {task.comments && task.comments.length > 0 && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={showComments} unmountOnExit>
              <Stack margin={1}>
                {task.comments.map((commentsRow) => (
                  <Paper variant="outlined" square sx={{ p: 1 }} key={commentsRow.id}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <p>{commentsRow.value}</p>
                      <p>
                        {commentsRow.createdByUserFullname}, {formatDateTime(commentsRow.createdOn)}
                      </p>
                    </Stack>
                  </Paper>
                ))}
              </Stack>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

interface Props {
  tasks: CreditApplicationTask[]
  onOpenTaskDialog: (task: CreditApplicationTask) => void
  onOpenCommentDialog: (task: CreditApplicationTask) => void
  onClickStatusButton: (status: ETasksStatus, task: CreditApplicationTask) => void
  onOpenTaskSuppressionDialog: (task: CreditApplicationTask) => void
  creditApplication: CreditApplication
  userCanEditTask: boolean
  editDisabled: boolean
}

const TaskList = ({
  tasks,
  onOpenTaskDialog,
  onOpenCommentDialog,
  onClickStatusButton,
  onOpenTaskSuppressionDialog,
  creditApplication,
  userCanEditTask,
  editDisabled,
}: Props) => {
  const { t } = useTranslation()

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="justify">{t('tasks.typeId')}</TableCell>
            <TableCell align="center">{t('tasks.description')}</TableCell>
            <TableCell align="center">{t('common.requiredFor')}</TableCell>
            <TableCell align="center">{t('tasks.reminder')}</TableCell>
            <TableCell align="center">{t('common.status')}</TableCell>
            {userCanEditTask && <TableCell align="center">{t('tasks.actions')}</TableCell>}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks.map((task) => (
            <TaskRow
              key={task.id}
              task={task}
              onOpenTaskDialog={onOpenTaskDialog}
              onOpenCommentDialog={onOpenCommentDialog}
              onClickStatusButton={onClickStatusButton}
              onOpenTaskSuppressionDialog={onOpenTaskSuppressionDialog}
              creditApplication={creditApplication}
              userCanEditTask={userCanEditTask}
              editDisabled={editDisabled}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TaskList
