import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import EditIcon from '@mui/icons-material/Edit'
import EmailIcon from '@mui/icons-material/Email'
import { Box, IconButton, Link, Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import LanguagdProvinceIndicator from '../../../components/LanguageProvinceIndicator'
import { useAppSelector } from '../../../data/store'
import { creditSelectors } from '../../../data/store/CreditApplication'
import { Applicant, EFinancingProgram, EProvince } from '../../../data/types'
import { CreditDecision } from '../../../data/types/CreditDecisionSchema'
import { PrequalificationDecision } from '../../../data/types/PrequalificationDecision'
import { FormatCurrency, formatAddress, formatDate } from '../../../services/Formatter'
import PhoneNumber from './PhoneNumber'

interface Props {
  creditApplicationId: string
  financingProgramId: EFinancingProgram
  applicant: Applicant
  currentApplicant: 'applicant' | 'coApplicant'
  handleEditBankruptciesClicked: (data: 'applicant' | 'coApplicant') => void
  editDisabled: boolean
  userCanEditCreditApplication: boolean
  applicationIsDraft: boolean
  finalDecision: CreditDecision
  prequalificationDecision: PrequalificationDecision
}

const CreditSectionInformation = ({
  applicant,
  currentApplicant,
  creditApplicationId,
  financingProgramId,
  handleEditBankruptciesClicked,
  editDisabled,
  userCanEditCreditApplication,
  applicationIsDraft,
  finalDecision,
  prequalificationDecision,
}: Props) => {
  const { t } = useTranslation()
  const bankruptcies =
    applicant.hardHitReport !== null
      ? applicant.hardHitReport.bankruptciesCount + applicant.additionnalBankruptcies.length
      : applicant.additionnalBankruptcies.length
  const bankruptciesColor = bankruptcies === 0 ? 'primary' : 'error'
  const theme = useTheme()
  const borderColor = theme.palette.divider
  const navigate = useNavigate()

  const isPrequalification = useAppSelector(creditSelectors.isOnlyPrequalificationDone)
  const shouldDisplayHardHit =
    financingProgramId !== EFinancingProgram.Products ||
    (financingProgramId === EFinancingProgram.Products && applicant.currentAddress.stateIso === EProvince.quebec)

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor,
        borderRadius: 2,
        p: 1,
      }}
    >
      <Stack justifyContent="space-between" alignItems="center" direction="row">
        <Stack direction="row" alignItems="center">
          <Typography variant="body1" component="div">
            {t(`common.${currentApplicant}`)}
          </Typography>
          <IconButton
            onClick={() => handleEditBankruptciesClicked(currentApplicant)}
            disabled={editDisabled || !userCanEditCreditApplication || applicationIsDraft}
          >
            <AccountBalanceIcon
              fontSize="medium"
              color={
                editDisabled || !userCanEditCreditApplication || applicationIsDraft ? 'inherit' : bankruptciesColor
              }
            />
          </IconButton>
        </Stack>
        <LanguagdProvinceIndicator
          correspondanceLanguageId={applicant.languageId}
          applicantStateIso={applicant.currentAddress.stateIso ?? null}
        />
        <IconButton
          onClick={() =>
            navigate(
              `/Applications/${financingProgramId}/${creditApplicationId}/edit?showTabIndex=${
                currentApplicant === 'applicant' ? 0 : 1
              }`,
            )
          }
          disabled={!userCanEditCreditApplication}
        >
          <EditIcon fontSize="large" color={editDisabled || !userCanEditCreditApplication ? 'inherit' : 'primary'} />
        </IconButton>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Stack spacing={1}>
          <Typography variant="body1" component="div">
            {t('credit.name')} : {applicant.firstName} {applicant.middleName} {applicant.lastName}
          </Typography>
          <Typography variant="body1" component="div">
            {t('editCreditApplication.information.birthDate')} : {formatDate(applicant.birthDate)}
          </Typography>
        </Stack>
        <Stack>
          <PhoneNumber phone={applicant.cellPhone} applyPhoneFormatting />
          <Typography variant="body1" component="div">
            <IconButton>
              <EmailIcon fontSize="medium" />
            </IconButton>
            <Link href={`mailto:${applicant.email}`}>{applicant.email}</Link>
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Typography variant="body1" component="div">
          {t('credit.address')} : {formatAddress(applicant.currentAddress)}
        </Typography>
      </Stack>
      {shouldDisplayHardHit && (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Stack spacing={1}>
            <Typography variant="body1" component="div">
              {t('credit.normDecision')} :{' '}
              {isPrequalification ? (
                <>Prequal {t(`credit.${prequalificationDecision.decision}`)}</>
              ) : (
                t(`credit.${finalDecision.decision}`)
              )}
            </Typography>
            <Typography variant="body1" component="div">
              {t('credit.internalScore')} :{' '}
              {applicant.creditDecision?.score === 0 ? 'N/A' : applicant.creditDecision?.score}
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="body1" component="div">
              ERS : {applicant.softHitReport?.ers ?? applicant.hardHitReport?.ers}
            </Typography>
            <Typography variant="body1" component="div">
              BNI : {applicant.hardHitReport?.bni}
            </Typography>
          </Stack>
          <div />
        </Stack>
      )}
      {applicant.creditDecision?.totalDebtService?.totalDebtRatioBeforeNewLoan !== undefined &&
        shouldDisplayHardHit && (
          <Typography variant="body1" component="div">
            {t('credit.tds')} : {applicant.creditDecision?.totalDebtService.totalDebtRatioBeforeNewLoan.toFixed(2)}
          </Typography>
        )}

      {(applicant.expenses.rentMonthly ?? 0) > 0 && (
        <Typography variant="body1" component="div">
          {t('editCreditApplication.expenses.rentMonthlyPayment')} : {FormatCurrency(applicant.expenses.rentMonthly)}
        </Typography>
      )}

      {(applicant.expenses.mortgageMonthly ?? 0) > 0 && (
        <Typography variant="body1" component="div">
          {t('editCreditApplication.expenses.mortgageMonthlyPayment')} :{' '}
          {FormatCurrency(applicant.expenses.mortgageMonthly)}
        </Typography>
      )}
    </Box>
  )
}

export default CreditSectionInformation
