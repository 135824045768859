import { Gavel as GavelIcon } from '@mui/icons-material'
import { Button, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EDecision, EWorksheetDecision, EWorksheetStatus } from '../../../data/types'
import { IFinanceBaseWorksheet } from '../../../data/types/IFinanceBaseWorksheetSchema'
import { FormatCurrency } from '../../../services/Formatter'
import DecisonIcon from '../../CreditDecisionPage/components/decisonIcon'

interface Props {
  worksheet: IFinanceBaseWorksheet
  onClick: () => void
  editDisabled: boolean
  userCanEditWorksheet: boolean
}

const WorksheetDecisionSection = ({ worksheet, onClick, editDisabled, userCanEditWorksheet }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isApproved = worksheet.finalDecision.decision === EWorksheetDecision.Approved

  return (
    <>
      <Typography variant="h6" component="h3" gutterBottom>
        {t('credit.finalDecision')}
      </Typography>
      <DecisonIcon decision={worksheet.finalDecision.decision as unknown as EDecision} />
      <Typography
        variant="subtitle1"
        sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
        component="p"
      >
        {t('worksheet.paymentAmount')}: {FormatCurrency(worksheet?.paymentAmountForFrequency)} /{' '}
        {t('worksheet.monthLowerCase')}
      </Typography>
      {isApproved && worksheet.fundingThresholdExtend && (
        <>
          {worksheet.fundingThresholdExtend.maxTermDuration !== 0 && (
            <Typography
              variant="subtitle1"
              sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
              component="p"
            >
              {t('worksheet.maxTerme')}: +{worksheet.fundingThresholdExtend.maxTermDuration} {t('worksheet.month')}
            </Typography>
          )}
          {worksheet.fundingThresholdExtend.maxPmtAmount !== 0 && (
            <Typography
              variant="subtitle1"
              sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
              component="p"
            >
              {t('credit.maxPayment')}: + {FormatCurrency(worksheet.fundingThresholdExtend.maxPmtAmount)} /{' '}
              {t('worksheet.month')}
            </Typography>
          )}
          {worksheet.fundingThresholdExtend.maxTotalAmountFinanced && (
            <Typography
              variant="subtitle1"
              sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
              component="p"
            >
              {t('editDecisionDialog.maxTotalAmountFinanced')}:{' '}
              {FormatCurrency(worksheet.fundingThresholdExtend.maxTotalAmountFinanced)}
            </Typography>
          )}
        </>
      )}
      <Button
        variant="contained"
        startIcon={<GavelIcon />}
        size="large"
        sx={{ mt: 2 }}
        onClick={onClick}
        disabled={worksheet.status !== EWorksheetStatus.Active || editDisabled || !userCanEditWorksheet}
      >
        {t('credit.decision')}
      </Button>
    </>
  )
}

export default WorksheetDecisionSection
