import { Divider, Typography } from '@mui/material'
import { FraudWarning } from '@src/data/types'
import { useTranslation } from 'react-i18next'
import Row from './tableRow'

interface FraudAlertsProps {
  fraudWarnings: FraudWarning[]
}

const FraudWarnings = ({ fraudWarnings }: FraudAlertsProps) => {
  const { t } = useTranslation()
  return (
    <Row id="fraudAlert" name={t('creditReport.fraudAlert')}>
      {fraudWarnings.map((item) => {
        return (
          <span key={item.code}>
            <Typography variant="subtitle2" gutterBottom component="div">
              {item.warningDescription}
            </Typography>
            <Typography variant="body1" gutterBottom component="div">
              {t('creditReport.reason')}: {item.description}
            </Typography>
            <Divider />
          </span>
        )
      })}
    </Row>
  )
}

export default FraudWarnings
