import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Vehicle } from '../../../data/types'
import VehicleInformation from './vehicleInformation'

interface Props {
  vehicle: Vehicle | null
}

const VehicleSummary = ({ vehicle }: Props) => {
  const { t } = useTranslation()

  return (
    <div aria-hidden="true">
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Typography variant="h6" component="h6" sx={{ paddingLeft: 2, paddingTop: 2 }}>
          {t('worksheet.vehicle')}
        </Typography>
        <Box sx={{ flex: '1 1 auto' }} />
      </Box>

      {vehicle && (
        <Grid container>
          <VehicleInformation vehicle={vehicle} title={t('common.merchant')} />
          <Grid item>
            <VehicleInformation vehicle={vehicle} title="Canadian Black Book" />
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default VehicleSummary
