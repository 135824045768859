import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ActionsDialog, InputTextField, PageError } from '../../../components'
import { useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import { CreditTaskComment, creditTaskCommentSchema } from '../../../data/types'
import { reportErrorToConsole } from '../../../services/error-logger'

interface Props {
  open: boolean
  onConfirm: (comment: CreditTaskComment) => void
  onCancel: () => void
  title: string
  disableConfirm?: boolean
}

const NewTaskDialogComponent = ({ onConfirm, onCancel, open, title, disableConfirm }: Props) => {
  const { t } = useTranslation()
  const error = useAppSelector(appSelectors.getBusinessError)

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<CreditTaskComment>({
    mode: 'onBlur',
    defaultValues: creditTaskCommentSchema.getDefault(),
    resolver: yupResolver(creditTaskCommentSchema),
  })

  React.useEffect(() => {
    reset(creditTaskCommentSchema.getDefault())
  }, [open, reset])

  return (
    <ActionsDialog
      onCancel={onCancel}
      onConfirm={handleSubmit(onConfirm, reportErrorToConsole)}
      open={open}
      title={title}
      disabled={disableConfirm}
    >
      <PageError errors={error} />
      <div style={{ width: 600 }}>
        <InputTextField sx={{ mt: 1 }} error={errors?.value} {...register('value')} label={t('common.comment')} />
      </div>
    </ActionsDialog>
  )
}

export default React.memo(NewTaskDialogComponent)
