import { Paper, Typography, useTheme } from '@mui/material'
import { AutoWorksheet, CreditApplication } from '../../../data/types'
import TransactionSummary from './transactionSummary'

interface Props {
  worksheet: AutoWorksheet
  creditApplication: CreditApplication
}

const TransactionSection = ({ worksheet, creditApplication }: Props) => {
  const theme = useTheme()

  return (
    <Paper
      elevation={3}
      sx={{
        borderColor: theme.palette.divider,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 2,
        p: 1,
        mb: 3,
      }}
    >
      <Typography variant="h5" component="div" gutterBottom>
        Transaction
      </Typography>

      <TransactionSummary worksheet={worksheet} creditApplication={creditApplication} />
    </Paper>
  )
}

export default TransactionSection
