import CallIcon from '@mui/icons-material/Call'
import EmailIcon from '@mui/icons-material/Email'
import { Grid, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CreditApplication, Merchant } from '../../../data/types'

interface Props {
  currentApplication: CreditApplication
  merchant: Merchant
}
const MessageHeader = ({ currentApplication, merchant }: Props) => {
  const { t } = useTranslation()

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" textAlign="center">
          {t(`enum.eLanguageAbbreviation.${merchant.languageId}`)} | {merchant.province}
        </Typography>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Link
          variant="body1"
          href={`/Applications/${currentApplication.financingProgramId}/${currentApplication.id}/view`}
        >
          {t('common.application')} : #{currentApplication.referenceNumber}
        </Link>
        <Typography variant="body1" component="div">
          {currentApplication.applicant.firstName} {currentApplication.applicant.middleName}{' '}
          {currentApplication.applicant.lastName} {' - '} {currentApplication.coApplicant?.firstName}{' '}
          {currentApplication.coApplicant?.middleName} {currentApplication.coApplicant?.lastName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="div" textAlign="center">
          {merchant.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="div" textAlign="center">
          <CallIcon /> {merchant.phone}
        </Typography>
      </Grid>
      {merchant.email && (
        <Grid item xs={12}>
          <Typography variant="body1" component="div" textAlign="center">
            <EmailIcon />
            <Link href={`mailto:${merchant.email}`}>{merchant.email}</Link>
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}
export default MessageHeader
