import {
  CheckCircle,
  DocumentScanner,
  FindInPage,
  HighlightOff,
  NotificationsActive,
  PendingActions,
  RemoveCircle,
} from '@mui/icons-material'
import ErrorIcon from '@mui/icons-material/Error'
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SelectComponent } from '../../../components'
import LanguageProvinceIndicator from '../../../components/LanguageProvinceIndicator'
import OutlinedTextWithIcon from '../../../components/OutlinedTextWithIcon'
import { DocumentDecision, EApplicantType, EDocumentStatus, EDocumentStatusList, EProvince } from '../../../data/types'

interface Props {
  documentDecisions: DocumentDecision[]
  activeDocumentIndex: number
  handleNext: (selectedDocumentId: string) => void
  status: EDocumentStatus
  setStatus: React.Dispatch<React.SetStateAction<EDocumentStatus | undefined>>
  comment: string | null
  setComment: React.Dispatch<React.SetStateAction<string | null>>
  applicantStateIso: EProvince | null
  isRefusedDocumentMissingComment: () => boolean
  setHasDecisionMade: React.Dispatch<React.SetStateAction<boolean>>
  getDecisionTitle: (index: number) => string
  applicantLang: number
  coapplicantLang: number
  getDecisionLabelSuffix: (index: number) => string
}

const DocumentDecisionSection = ({
  documentDecisions,
  activeDocumentIndex,
  handleNext,
  status,
  setStatus,
  comment,
  setComment,
  applicantStateIso,
  isRefusedDocumentMissingComment,
  setHasDecisionMade,
  getDecisionTitle,
  applicantLang,
  coapplicantLang,
  getDecisionLabelSuffix,
}: Props) => {
  const { t } = useTranslation()
  React.useEffect(() => {
    if (documentDecisions.length > 0) {
      setStatus(documentDecisions[activeDocumentIndex]?.status)
      setComment(documentDecisions[activeDocumentIndex]?.refusalReason)
    }
  }, [activeDocumentIndex, documentDecisions, setStatus, setComment])

  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: '1rem',
        paddingBottom: '1rem',
        marginTop: -3,
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <List
          sx={{
            borderRadius: '0.5rem',
            maxHeight: '25rem',
            position: 'relative',
            overflow: 'auto',
            width: '100%',
            scrollbarWidth: 'thin',
            '::-webkit-scrollbar': { width: 5, background: 'lightgray' },
            '::-webkit-scrollbar-thumb': {
              background: 'darkgray',
            },
            '& ul': { padding: 0 },
          }}
          subheader={<li />}
        >
          <li key="section-required">
            <ul>
              {documentDecisions.length !== 0 && (
                <>
                  <ListSubheader>
                    <Typography variant="h6" gutterBottom component="div">
                      {`${t('document.currentDecisions')} (${documentDecisions?.length})`}
                    </Typography>
                  </ListSubheader>
                  {documentDecisions.map((item, i) => (
                    <ListItemButton
                      selected={item.documentId === documentDecisions[activeDocumentIndex]?.documentId}
                      onClick={() => handleNext(item.documentId)}
                      key={`section-required-${item.documentId}`}
                    >
                      <ListItemIcon>
                        {!item.status && (
                          <Tooltip title={t('document.awaitingDocument')} arrow placement="top">
                            <PendingActions color="warning" />
                          </Tooltip>
                        )}
                        {item.status === EDocumentStatus.AwaitingScan && (
                          <Tooltip title={t('document.awaitingScan')} arrow placement="top">
                            <DocumentScanner />
                          </Tooltip>
                        )}
                        {item.status === EDocumentStatus.AwaitingApproval && (
                          <Tooltip title={t('document.awaitingApproval')} arrow placement="top">
                            <FindInPage color="primary" />
                          </Tooltip>
                        )}
                        {item.status === EDocumentStatus.Approved && (
                          <Tooltip title={t('document.approved')} arrow placement="top">
                            <CheckCircle color="success" />
                          </Tooltip>
                        )}
                        {item.status === EDocumentStatus.Refused && (
                          <Tooltip title={t('document.declined')} arrow placement="top">
                            <RemoveCircle color="error" />
                          </Tooltip>
                        )}
                        {item.status === EDocumentStatus.Incomplete && (
                          <Tooltip title={t('enum.eDocumentDecisionStatus.incomplete')} arrow placement="top">
                            <NotificationsActive color="warning" />
                          </Tooltip>
                        )}
                        {item.status === EDocumentStatus.Deleted && (
                          <Tooltip title={t('enum.eDocumentDecisionStatus.deleted')} arrow placement="top">
                            <HighlightOff color="error" />
                          </Tooltip>
                        )}
                      </ListItemIcon>
                      <ListItemText primary={getDecisionTitle(i)} />
                    </ListItemButton>
                  ))}
                </>
              )}
            </ul>
          </li>
        </List>
        <Divider />
        <Box sx={{ height: 'auto', width: '100%', p: 1 }}>
          <Typography sx={{ mt: 2, mb: 3 }} variant="h6">
            {documentDecisions[activeDocumentIndex] ? getDecisionTitle(activeDocumentIndex) : ''}
            <Typography variant="body2">
              {documentDecisions[activeDocumentIndex]?.applicantType
                ? String(
                    t(`enum.beneficiaryType.${documentDecisions[activeDocumentIndex].applicantType}`),
                  ).toUpperCase()
                : getDecisionLabelSuffix(activeDocumentIndex).toUpperCase()}
            </Typography>
          </Typography>

          <SelectComponent
            items={EDocumentStatusList.map((item) => ({
              label: `enum.eDocumentDecisionStatus.${item}`,
              value: item,
            }))}
            label={t('document.decision') as string}
            onChange={(val: { target: { value: React.SetStateAction<EDocumentStatus> } }) => {
              if (documentDecisions[activeDocumentIndex]?.status !== val.target.value) setHasDecisionMade(true)
              else setHasDecisionMade(false)
              const status = val.target.value.toString() as EDocumentStatus
              documentDecisions[activeDocumentIndex].status = status
              return setStatus(status)
            }}
            value={status}
          />
          <Box height="auto">
            {(status === EDocumentStatus.Incomplete || status === EDocumentStatus.Refused) && (
              <>
                <Stack direction="row" justifyContent="space-between">
                  <OutlinedTextWithIcon
                    icon={<ErrorIcon sx={{ color: 'red' }} />}
                    messageText={t('document.decisionCommentLabel')}
                    border="none"
                  />

                  <LanguageProvinceIndicator
                    correspondanceLanguageId={
                      documentDecisions[activeDocumentIndex]?.applicantType === EApplicantType.CoApplicant
                        ? coapplicantLang
                        : applicantLang
                    }
                    applicantStateIso={applicantStateIso}
                  />
                </Stack>

                <TextField
                  fullWidth
                  multiline
                  minRows={2}
                  maxRows={2}
                  label={t('document.decisionComment')}
                  value={comment || ''}
                  onChange={(val) => setComment(val.target.value)}
                  error={isRefusedDocumentMissingComment()}
                />
              </>
            )}
            {status === EDocumentStatus.Approved && (
              <div style={{ textAlign: 'center' }}>
                <CheckCircle color="success" fontSize="large" />
                <Stack style={{ textAlign: 'center' }} direction="column">
                  <Typography>{t('document.approvalCompleted')}</Typography>
                </Stack>
              </div>
            )}
          </Box>
        </Box>
        {documentDecisions[activeDocumentIndex] && documentDecisions[activeDocumentIndex]?.lastUpdate !== '' && (
          <Typography sx={{ mt: 1 }} variant="body2">
            {`${t('common.updatedOn')} ${documentDecisions[activeDocumentIndex].lastUpdate} ${String(
              t('common.by'),
            ).toLowerCase()} ${documentDecisions[activeDocumentIndex].updatedBy}`}{' '}
          </Typography>
        )}
      </div>
    </Paper>
  )
}

export default DocumentDecisionSection
