import { CreditDecisionOverrideLimits } from '@src/data/types/CreditDecisionOverrideLimits'
import { CreateLoanStatusDto } from '@src/data/types/CreateLoanStatusDto'
import { QueryFunctionContext, QueryObserverResult, RefetchOptions, useQuery } from '@tanstack/react-query'
import { getApiClient } from '@src/services/api-client'
import {
  createEntityApi,
  makeUpdate,
  makeGetListFromApi,
  makeDownloadWithObject,
  makeGetSingleFromApi,
  makeCreate,
  makeDeleteWithReturn,
} from '../make-api'
import {
  transformCreditApplicationFromApi,
  transformCreditApplicationToApi,
  transformEditIncomesDtoToApi,
} from './credit-transform'
import {
  CreditApplication,
  CreditApplicationTask,
  CreditApplicationReservation,
  ProofsOfRelease,
  EFinancingProgram,
  EApplicantType,
  FullCreditApplication,
} from '../../types'
import { EditIncomesDto } from './EditIncomesDto'
import { EditBankAccountDto } from './EditBankAccountDto'
import { RefinanceCreditApplicationDto } from './RefinanceCreditApplicationDto'
import { CreditReportDto } from '../../types/CreditReportDto'
import { UpdateCreditApplicationMerchantDto } from '../../types/UpdateCreditApplicationMerchantDto'
import { CopyCreditApplicationAsDraftDto } from './CopyCreditApplicationAsDraftDto'
import transformFullCreditApplicationFromApi from '../full-credit-application/full-credit-application-transform'

const SCOPE = 'credit-application'
const DETAIL = 'detail'

const keysFactory = {
  detail: (financingProgramId: string, creditApplicationId: string) =>
    [{ scope: SCOPE, entity: DETAIL, financingProgramId, id: creditApplicationId }] as const,
}
const baseUrl = '/Applications/{financingProgramId}'
const creditDecisionOverrideLimitsUrl =
  '/Applications/{financingProgramId}/{creditApplicationId}/DecisionOverrideLimits'
const creditReportUrl = '/Applications/{financingProgramId}/{creditApplicationId}/RefreshCreditReport'
const getCreditReportUrl = '/Applications/{financingProgramId}/{creditApplicationId}/CreditReport/{creditReportId}'
const creditApplicationStatusUrl = '/Applications/{financingProgramId}/{creditApplicationId}/status'
const creditApplicationDownloadCreditPdf = '/Applications/{financingProgramId}/DownloadCreditApplicationPDF'
const confirmAllIncomesUrl = 'Applications/{financingProgramId}/{id}/IncomeConfirmation'
const lockCreditApplicationUrl = 'Applications/{financingProgramId}/{creditApplicationId}/LockEdit'
const unlockCreditApplicationUrl = 'Applications/{financingProgramId}/{creditApplicationId}/UnlockEdit'
const proofOfReleaseUrl = '/Applications/{financingProgramId}/{creditApplicationId}/ProofOfRelease'
const LockEntityUrl = '/Applications/{financingProgramId}/{creditApplicationId}/Reservation'
const updateIncomesUrl = 'Applications/{financingProgramId}/{creditApplicationId}/{applicantType}/incomes'
const payOutCreditApplicationUrl = 'Applications/{financingProgramId}/{creditApplicationId}/PayOut'
const setPaymentAccountUrl = 'Applications/{financingProgramId}/{creditApplicationId}/PaymentAccount'
const sendFundDepositConfirmationUrl = 'Applications/{financingProgramId}/{creditApplicationId}/FundDepositConfirmation'
const refinancingUrl = '/Applications/{financingProgramId}/{creditApplicationId}/Refinancing'
const copyAsDraftUrl = '/Applications/{financingProgramId}/{creditApplicationId}/CopyAsDraft'
const updateMerchantUrl = '/Applications/{financingProgramId}/{creditApplicationId}/Merchant'
const updateRushUrl = '/Applications/{financingProgramId}/{creditApplicationId}/rush'
const resetVouchedUrl = '/Applications/{financingProgramId}/{creditApplicationId}/{applicantType}/ResetVouched'
const loanCreationStatusUrl = '/Applications/{financingProgramId}/{creditApplicationId}/LoanCreationStatus'

const creditApplicationApi = {
  ...createEntityApi<CreditApplication>(baseUrl, transformCreditApplicationFromApi, transformCreditApplicationToApi),
  getByFilters: makeGetListFromApi<CreditApplication>(baseUrl, transformCreditApplicationFromApi),
  refreshCreditReport: makeUpdate<
    { creditApplicationId: string; isApplicant: boolean; versionTag: string },
    CreditApplication
  >(creditReportUrl),
  changeStatus: makeUpdate<{ creditApplicationId: string; status: string; versionTag: string }, CreditApplication>(
    creditApplicationStatusUrl,
    undefined,
    transformCreditApplicationFromApi,
  ),
  changeRush: makeUpdate<{ creditApplicationId: string; isRush: boolean; versionTag: string }, CreditApplication>(
    updateRushUrl,
    undefined,
    transformCreditApplicationFromApi,
  ),
  getCreditDecisionOverrideLimits: makeGetSingleFromApi<CreditDecisionOverrideLimits>(creditDecisionOverrideLimitsUrl),
  getCreditReportByCreditReportId: makeGetSingleFromApi<CreditReportDto>(getCreditReportUrl),
  downloadCreditPDF: makeDownloadWithObject(creditApplicationDownloadCreditPdf),
  getCreditApplicationLock: makeGetSingleFromApi<CreditApplicationReservation>(LockEntityUrl),
  createCreditApplicationLock: makeCreate<CreditApplicationReservation>(LockEntityUrl),
  deleteCreditApplicationLock: makeDeleteWithReturn<CreditApplicationReservation>(LockEntityUrl),
  updateCreditApplicationLock: makeUpdate<CreditApplicationReservation>(LockEntityUrl),
  confirmAllIncomes: makeCreate<CreditApplication, CreditApplicationTask>(confirmAllIncomesUrl),
  getCreateLoanStatus: makeGetSingleFromApi<CreateLoanStatusDto>(loanCreationStatusUrl),

  lockCreditApplication: makeUpdate<{ creditApplicationId: string }, CreditApplication>(
    lockCreditApplicationUrl,
    undefined,
    transformCreditApplicationFromApi,
  ),
  unlockCreditApplication: makeUpdate<{ creditApplicationId: string }, CreditApplication>(
    unlockCreditApplicationUrl,
    undefined,
    transformCreditApplicationFromApi,
  ),
  payOutCreditApplication: makeUpdate<{ creditApplicationId: string }>(payOutCreditApplicationUrl),
  updateProofOfRelease: makeUpdate<ProofsOfRelease, CreditApplication>(
    proofOfReleaseUrl,
    undefined,
    transformCreditApplicationFromApi,
  ),
  updateIncomes: makeUpdate<EditIncomesDto, CreditApplication>(
    updateIncomesUrl,
    transformEditIncomesDtoToApi,
    transformCreditApplicationFromApi,
  ),
  setPaymentAccount: makeUpdate<EditBankAccountDto, FullCreditApplication>(
    setPaymentAccountUrl,
    undefined,
    transformFullCreditApplicationFromApi,
  ),
  sendFundDepositConfirmation: makeUpdate<{ creditApplicationId: string; financingProgramId: EFinancingProgram }>(
    sendFundDepositConfirmationUrl,
  ),
  refinanceCreditApplication: makeCreate<RefinanceCreditApplicationDto, CreditApplication>(
    refinancingUrl,
    undefined,
    transformCreditApplicationFromApi,
  ),
  copyCreditApplicationAsDraft: makeCreate<CopyCreditApplicationAsDraftDto, CreditApplication>(
    copyAsDraftUrl,
    undefined,
    transformCreditApplicationFromApi,
  ),
  updateCreditApplicationMerchant: makeUpdate<UpdateCreditApplicationMerchantDto, CreditApplication>(
    updateMerchantUrl,
    undefined,
    transformCreditApplicationFromApi,
  ),
  resetVouched: makeCreate<{
    creditApplicationId: string
    financingProgramId: EFinancingProgram
    applicantType: EApplicantType
  }>(resetVouchedUrl),
}

const getCreateLoanStatus = async ({
  queryKey: [{ financingProgramId, id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['detail']>>) => {
  const response = await getApiClient().get<CreateLoanStatusDto>(
    `/Applications/${encodeURIComponent(financingProgramId)}/${encodeURIComponent(id)}/LoanCreationStatus`,
  )
  return response.data
}

export function useGetCreateLoanStatus(
  financingProgramId: string,
  creditApplicationId: string,
  shouldPoll: boolean,
): [
  CreateLoanStatusDto | null,
  boolean,
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<CreateLoanStatusDto, Error>>,
] {
  const { isFetching, data, refetch } = useQuery({
    queryKey: keysFactory.detail(financingProgramId, creditApplicationId),
    queryFn: getCreateLoanStatus,
    enabled: !!creditApplicationId && !!financingProgramId && shouldPoll,
    refetchInterval: shouldPoll ? 5000 : undefined,
    staleTime: 5000,
    gcTime: 5000,
  })
  return [data ?? null, isFetching, refetch]
}

export default creditApplicationApi
