import { Edit as EditIcon, History as HistoryIcon, TaxiAlert as TaxiAlertIcon } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Divider, IconButton, Link, Paper, Stack, Typography } from '@mui/material'
import { worksheetEffects } from '@src/data/store/AutoWorksheet'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { reportErrorToConsole } from '@src/services/error-logger'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AutoWorksheet, CreditApplication, EDecision, User } from '../../../data/types'
import { FormatCurrency, formatDateTime } from '../../../services/Formatter'

interface Props {
  creditApplication: CreditApplication
  worksheet: AutoWorksheet
  onWorksheetHistoryClicked: () => void
  user: User | null
  editDisabled: boolean
}

const AutoWorksheetInformationSection = ({
  creditApplication,
  worksheet,
  user,
  editDisabled,
  onWorksheetHistoryClicked,
}: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const dispatchEffect = useSideEffect()

  const computeFeesAndAccessories = () => {
    let ret = 0

    if (worksheet) {
      if (worksheet.dealerAdminFee) {
        ret += worksheet.dealerAdminFee
      }

      if (worksheet.lenderFee) {
        ret += worksheet.lenderFee
      }

      if (worksheet.icebergPPSA) {
        ret += worksheet.icebergPPSA
      }

      if (worksheet.installationDelivery) {
        ret += worksheet.installationDelivery
      }

      if (worksheet.licenseFee) {
        ret += worksheet.licenseFee
      }

      if (worksheet.otherNonTaxable) {
        ret += worksheet.otherNonTaxable
      }

      if (worksheet.otherTaxable) {
        ret += worksheet.otherTaxable
      }
    }

    return ret
  }

  const computeAssurance = () => {
    let ret = 0

    if (worksheet) {
      if (worksheet.accidentAndHealthInsurance) {
        ret += worksheet.accidentAndHealthInsurance
      }

      if (worksheet.gapInsurance) {
        ret += worksheet.gapInsurance
      }

      if (worksheet.lifeInsurance) {
        ret += worksheet.lifeInsurance
      }
    }

    return ret
  }

  const viewWorksheetDisabled = worksheet === null
  const editWorksheetDisabled =
    creditApplication.finalCreditDecision.decision !== EDecision.accept ||
    !user?.rights.canEditWorksheet ||
    editDisabled

  // event handlers
  const createWorksheet = useCallback(() => {
    const route = `/Applications/auto/${creditApplication.id}/worksheet/edit`

    if (worksheet === null) {
      dispatchEffect(worksheetEffects.create(creditApplication.id))
        .then(() => navigate(route))
        .catch(reportErrorToConsole)
    } else {
      navigate(route)
    }
  }, [creditApplication?.id, worksheet, dispatchEffect, navigate])

  return (
    <Paper elevation={3} sx={{ borderRadius: 2, p: 2, mb: 3 }}>
      <Box sx={{ m: 1 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={2}>
            <ButtonGroup variant="outlined" size="large">
              <Button
                component={Link}
                href={`/Applications/auto/${creditApplication.id}/worksheet`}
                variant="outlined"
                disabled={viewWorksheetDisabled}
              >
                {t('worksheet.financing')}
              </Button>
              <Button onClick={createWorksheet} disabled={editWorksheetDisabled} size="small">
                <EditIcon fontSize="large" />
              </Button>
            </ButtonGroup>

            <TaxiAlertIcon
              fontSize="large"
              color={
                worksheet?.carfaxValue === null || worksheet?.carfaxValue === 0 || worksheet?.carfaxValue === undefined
                  ? 'primary'
                  : 'error'
              }
            />
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            alignContent="center"
            divider={<Divider orientation="vertical" flexItem sx={{ height: 25, alignSelf: 'center' }} />}
          >
            <Typography variant="body1" component="div" gutterBottom>
              {t('common.LastModification')} : {worksheet && formatDateTime(worksheet?.updatedOn)}
            </Typography>

            <IconButton
              onClick={onWorksheetHistoryClicked}
              disabled={worksheet === null}
              sx={{
                color: worksheet === null ? '' : 'primary.main',
                fontSize: 'large',
              }}
            >
              <HistoryIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Divider />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          alignItems="top"
          marginTop={1}
          justifyContent="space-evenly"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <span>
            <Typography variant="body1" component="div" gutterBottom>
              {t('common.status')} :{' '}
              {worksheet?.finalDecision && t(`enum.eWorkSheetDecision.${worksheet.finalDecision.decision}`)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.vehicle')} : {worksheet?.vehicle?.make} {worksheet?.vehicle?.model}{' '}
              {worksheet?.vehicle?.series} {worksheet?.vehicle?.year}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.vin')} : {worksheet?.vehicle?.vin}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.mileage')} : {worksheet?.vehicle?.mileage}{' '}
              {worksheet?.vehicle?.mileage !== undefined && 'KM'}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.sideSection.carfax')} : {FormatCurrency(worksheet?.carfaxValue)}
            </Typography>
          </span>
          <span>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.paymentFrequency')} : {worksheet && t(`worksheet.${worksheet.paymentFrequency}`)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.term')} : {worksheet?.term} {worksheet?.term !== undefined && t('worksheet.month')}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.paymentAmount')} : {FormatCurrency(worksheet?.paymentAmountForFrequency)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('credit.refinancing')} : TODO
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.monthlyLeave')} : TODO
            </Typography>
          </span>
          <span>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.price')} : {FormatCurrency(worksheet?.price)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.feesAndAccessories')} : {FormatCurrency(computeFeesAndAccessories())}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.warranty')} : {FormatCurrency(worksheet?.extendedService)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.insurance')} : {FormatCurrency(computeAssurance())}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.merchantBalance')} : TODO
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.totalAmountFinanced')} : {FormatCurrency(worksheet?.totalAmountFinanced)}
            </Typography>
          </span>
        </Stack>
      </Box>
    </Paper>
  )
}

export default AutoWorksheetInformationSection
