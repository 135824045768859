import DeleteIcon from '@mui/icons-material/Delete'
import { Divider, Grid, IconButton } from '@mui/material'
import { Control, FieldError, FieldErrorsImpl, Merge, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DatePicker, InputTextField, SelectComponent } from '../../../components'
import { AdditionnalBankruptcies, Applicant, EBankruptcyTypesList, SelectValueListItem } from '../../../data/types'

interface Props {
  index: number
  isSyndicReported?: boolean
  register: UseFormRegister<Applicant>
  error: Merge<FieldError, (Merge<FieldError, FieldErrorsImpl<AdditionnalBankruptcies>> | undefined)[]> | undefined
  control: Control<Applicant>
  fieldName: 'additionnalBankruptcies'
  onDeleteClicked: () => void
}

const Bankruptcy = ({ index, register, error, control, isSyndicReported, fieldName, onDeleteClicked }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <Grid container item xs={12} spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={2}>
          <DatePicker
            name={`${fieldName}.${index}.filedD`}
            control={control}
            error={error ? error[index]?.filedD : undefined}
            label="Date"
            disableFuture
            {...{ disabled: !isSyndicReported }}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <InputTextField
            error={error ? error[index]?.liabilityAmount : undefined}
            label={t('browseCreditApplications.dataGridColumnHeaders.amount')}
            {...register(`${fieldName}.${index}.liabilityAmount`)}
            disabled={!isSyndicReported}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectComponent
            items={EBankruptcyTypesList.map((item) => {
              const data: SelectValueListItem = { label: `enum.eBankruptcyTypes.${item}`, value: item }
              return data
            })}
            label={t('browseCreditApplications.dataGridColumnHeaders.bankruptcyType') as string}
            {...(fieldName && register(`${fieldName}.${index}.intentCode`))}
            error={error ? error[index]?.intentCode : undefined}
            disabled={!isSyndicReported}
          />
        </Grid>
        <Grid item xs={11} md={3}>
          <InputTextField
            error={error ? error[index]?.trusteeName : undefined}
            label={t('browseCreditApplications.dataGridColumnHeaders.TrusteeName')}
            {...(fieldName && register(`${fieldName}.${index}.trusteeName`))}
            disabled={!isSyndicReported}
          />
        </Grid>
        <Grid item xs={11} md={2}>
          <InputTextField
            error={error ? error[index]?.caseNumber : undefined}
            label={t('browseCreditApplications.dataGridColumnHeaders.CaseNumber')}
            {...(fieldName && register(`${fieldName}.${index}.caseNumber`))}
            disabled={!isSyndicReported}
          />
        </Grid>
        {isSyndicReported && (
          <Grid item>
            <IconButton sx={{ mb: 3 }} aria-label="delete" onClick={onDeleteClicked}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Divider />
    </>
  )
}

export default Bankruptcy
