import { Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Merchant } from '../../../data/types'

interface MerchantPaymentInstructionsProps {
  merchant: Merchant
}

const MerchantPaymentInstructions = ({ merchant }: MerchantPaymentInstructionsProps) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        border: 1,
        borderColor: theme.palette.primary.main,
        padding: 1,
        backgroundColor: `${theme.palette.primary.main}20`,
        marginBottom: 2,
      }}
    >
      <Typography variant="subtitle1" fontWeight="bold">
        {t('worksheetCommon.merchantInstructions')}
      </Typography>
      <Typography>
        <>
          {merchant.paymentInstructions}
          {!merchant.paymentInstructions && t('worksheetCommon.noPaymentInstruction')}
        </>
      </Typography>
    </Box>
  )
}

export default MerchantPaymentInstructions
