import { Dialog, Paper, useTheme } from '@mui/material'
import React from 'react'
import { appSelectors } from '@src/data/store/AppStore'
import Layout from '../containers/App/AppHeader'
import { useAppSelector } from '../data/store'
import { userSelectors } from '../data/store/UserStore'

interface Props {
  children?: React.ReactNode
}

const FullScreenContainer = ({ children }: Props): JSX.Element => {
  const isIcebergGrDevs = useAppSelector(userSelectors.isIcebergGrDevs)
  const deprecated = useAppSelector(appSelectors.isDeprecated)
  const hasAlertBanner = isIcebergGrDevs || (import.meta.env.PROD && deprecated)
  const theme = useTheme()

  return (
    <Paper>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: theme.palette.background.default,
            background: theme.palette.background.default,
          },
        }}
        fullScreen
        open
      >
        <Layout hasAlertBanner={hasAlertBanner} />
        <div style={{ paddingLeft: '5rem', paddingRight: '5rem' }}>{children}</div>
      </Dialog>
    </Paper>
  )
}

export default FullScreenContainer
