import { Divider, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { InputTextField, SelectComponent } from '../../../components'
import { EBankruptcyTypesList } from '../../../data/types'
import { Bankruptcy } from '../../../data/types/CreditReportSchema'
import { formatIsoDate } from '../../../services/Formatter'

interface Props {
  bankruptcy: Bankruptcy
}

const ReportedBankruptcy = ({ bankruptcy }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <Grid container item xs={12} spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={2}>
          <InputTextField label="Date" value={formatIsoDate(new Date(bankruptcy.filedD ?? ''))} disabled />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <InputTextField
            label={t('browseCreditApplications.dataGridColumnHeaders.amount')}
            value={bankruptcy.liabilityAmount}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectComponent
            items={EBankruptcyTypesList.map((item) => ({
              label: `enum.eBankruptcyTypes.${item}`,
              value: item,
            }))}
            label={t('browseCreditApplications.dataGridColumnHeaders.bankruptcyType') as string}
            value={bankruptcy.intentCode}
            disabled
          />
        </Grid>
        <Grid item xs={11} md={3}>
          <InputTextField
            label={t('browseCreditApplications.dataGridColumnHeaders.TrusteeName')}
            value={bankruptcy.trusteeName}
            disabled
          />
        </Grid>
        <Grid item xs={11} md={2}>
          <InputTextField
            label={t('browseCreditApplications.dataGridColumnHeaders.CaseNumber')}
            value={bankruptcy.caseNumber}
            disabled
          />
        </Grid>
      </Grid>
      <Divider />
    </>
  )
}

export default ReportedBankruptcy
