import { Edit as EditIcon } from '@mui/icons-material'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Applicant, EFinancingProgram } from '../../../data/types'
import { formatAddress, formatPhone } from '../../../services/Formatter'

interface Props {
  applicant: Applicant
  creditApplicationId: string
  financingProgramId: EFinancingProgram
  isCoapplicant?: boolean
  onClickExpand: () => void
  editDisabled: boolean
}

const ApplicantSummary = ({
  applicant,
  creditApplicationId,
  onClickExpand,
  financingProgramId,
  isCoapplicant,
  editDisabled,
}: Props) => {
  const address = applicant.currentAddress
  const navigate = useNavigate()

  const { t } = useTranslation()
  return (
    <div aria-hidden="true" onClick={onClickExpand} style={{ cursor: 'pointer' }}>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Typography variant="h6" component="h6" sx={{ p: 2 }}>
          {isCoapplicant ? t('common.coApplicant') : t('common.applicant')}
        </Typography>
        <Box sx={{ flex: '1 1 auto' }} />
        <IconButton
          onClick={() =>
            navigate(
              `/Applications/${financingProgramId}/${creditApplicationId}/edit?showTabIndex=${isCoapplicant ? 1 : 0}`,
            )
          }
          disabled={editDisabled}
        >
          <EditIcon fontSize="large" />
        </IconButton>
      </Box>
      <Stack padding={2} direction="column" alignItems="flex-start" justifyContent="space-between">
        <Typography variant="body1" component="div">
          {applicant.firstName} {applicant.lastName}
        </Typography>
        <Typography variant="body1" component="div">
          {formatPhone(applicant.homePhone)}, {formatPhone(applicant.cellPhone)}, {applicant.email}
        </Typography>
        <Typography variant="body1" component="div">
          {formatAddress(address)}
        </Typography>
      </Stack>
    </div>
  )
}

export default ApplicantSummary
