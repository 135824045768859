import { TextField, InputBaseComponentProps, useTheme } from '@mui/material'
import React from 'react'
import { FieldError } from 'react-hook-form'
import translate from '@src/services/translate'
import translateErrorCode from './TranslateError'
import { SelectValueListItem } from '../data/types/SelectValueListItem'

interface Props extends InputBaseComponentProps {
  error?: FieldError
  items: SelectValueListItem[]
  label?: string
}
const SelectComponent = React.forwardRef<HTMLSelectElement, Props>(function SelectComponent(
  { error, items, label, ...otherProps },
  ref,
) {
  const theme = useTheme()

  return (
    <TextField
      inputRef={ref}
      label={label}
      error={error !== undefined}
      helperText={translateErrorCode(error)}
      variant="outlined"
      fullWidth
      select
      InputLabelProps={{ shrink: true }}
      SelectProps={{
        native: true,
        inputProps: { ...otherProps },
      }}
    >
      <option aria-label="None" value="" style={{ display: 'none' }} hidden />
      {items.map((item) => (
        <option
          aria-label={item.label ? translate(item.label) : 'Empty'}
          style={{
            color: item.label.toString().includes('**') ? theme.palette.grey[500] : 'none',
          }}
          key={item.value}
          value={item.value}
        >
          {translate(item.label)}
        </option>
      ))}
    </TextField>
  )
})

export default SelectComponent
