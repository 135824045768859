import LinkIcon from '@mui/icons-material/Link'
import { Alert, Divider, Link, ListItem, ListItemIcon, ListItemText, Snackbar, Switch, Typography } from '@mui/material'
import { useAppSelector } from '@src/data/store'
import { appSelectors, appStoreEffects } from '@src/data/store/AppStore'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { FeatureSwitch } from '@src/data/types/constants'
import { TranslatedEnum } from '@src/data/types/TranslatedEnum'
import { formatDateTime } from '@src/services/Formatter'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  featureSwitch: FeatureSwitch
  setShowNextRefreshDate: Dispatch<SetStateAction<boolean>>
}

const FeaturesSwitch = ({ featureSwitch, setShowNextRefreshDate }: Props) => {
  const dispatchEffect = useSideEffect()
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum
  const [checked, setChecked] = useState(featureSwitch.isActivated)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success')
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [isUpdating, setIsUpdating] = useState(false)

  const { t } = useTranslation()

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
    setIsUpdating(true)
    try {
      await dispatchEffect(appStoreEffects.updateFeatureSwitch({ ...featureSwitch, isActivated: event.target.checked }))
      setSnackbarSeverity('success')
      setSnackbarMessage(t('featureSwitch.updatedSuccessfully'))
      setShowNextRefreshDate(true)
    } catch {
      setSnackbarSeverity('error')
      setSnackbarMessage(t('featureSwitch.updateFailed'))
    } finally {
      setSnackbarOpen(true)
      setIsUpdating(false)
    }
  }

  return (
    <>
      <ListItem key={featureSwitch.id}>
        <ListItemIcon>
          <Link
            href={`https://dev.azure.com/IcebergFinance/IcebergFinance/_workitems/edit/${featureSwitch.workItemId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkIcon />
          </Link>
        </ListItemIcon>
        <ListItemText
          primary={lang === 'fr' ? featureSwitch.nameFr : featureSwitch.nameEn}
          secondary={
            featureSwitch.activatedByUserFullName === null ? (
              <Typography component="div" variant="body2" sx={{ display: 'block' }}>
                {t('featureSwitch.neverActivated')}
              </Typography>
            ) : (
              <>
                <Typography component="div" variant="body2" sx={{ display: 'block' }}>
                  {t('featureSwitch.lastModifiedBy')} {featureSwitch.activatedByUserFullName}
                </Typography>
                <Typography component="div" variant="body2" sx={{ display: 'block' }}>
                  {t('featureSwitch.lastModifiedOn')} {formatDateTime(featureSwitch.activatedOn)}
                </Typography>
              </>
            )
          }
        />
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
          disabled={isUpdating}
        />
      </ListItem>
      <Divider />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default FeaturesSwitch
