import { Box, Button, ButtonGroup, Divider, IconButton, Link, Paper, Stack, Typography } from '@mui/material'
import { ProductsWorksheet, ProductsWorksheetDto } from '@src/data/types/ProductsWorksheetSchema'
import { FormatCurrency, formatDate, formatDateTime } from '@src/services/Formatter'
import { useTranslation } from 'react-i18next'
import { History as HistoryIcon, Edit as EditIcon } from '@mui/icons-material'
import {
  Constants,
  CreditApplication,
  EDecision,
  EPaymentFrequency,
  EPaymentMethod,
  EPaymentPlan,
  ETermMax,
  EWorksheetStatus,
} from '@src/data/types'
import { useNavigate } from 'react-router-dom'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { useAppSelector } from '@src/data/store'
import { creditSelectors } from '@src/data/store/CreditApplication'
import { reportErrorToConsole } from '@src/services/error-logger'
import productsWorksheetEffects from '@src/data/store/ProductsWorksheet/products-worksheet-effects'
import { getProductsMinMaxPaymentDates } from '@src/containers/EditWorksheet/EditProductsWorksheetPage/productWorksheet-selectors'

interface Props {
  worksheet: ProductsWorksheet | null
  creditApplication: CreditApplication
  defaultPaymentMethod: EPaymentMethod
  onWorksheetHistoryClicked: () => void
  editDisabled: boolean
}

const ProductsWorksheetInformationSection = ({
  worksheet,
  creditApplication,
  defaultPaymentMethod,
  onWorksheetHistoryClicked,
  editDisabled,
}: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatchEffect = useSideEffect()
  const merchantPaymentPlan = useAppSelector(creditSelectors.getMerchantPaymentPlan)
  const paymentFrequency = EPaymentFrequency.Monthly
  const deliveryDate = formatDate(new Date())
  const [minFirstPaymentOn] = getProductsMinMaxPaymentDates(deliveryDate, paymentFrequency)
  const editWorksheetDisabled = creditApplication.finalCreditDecision.decision !== EDecision.accept || editDisabled

  // event handlers
  const createWorksheet = () => {
    const route = `/Applications/products/${creditApplication.id}/worksheet/edit`
    if (worksheet === null) {
      const term = merchantPaymentPlan ? merchantPaymentPlan.loanTerm : ETermMax.twelve
      const newWorksheet: ProductsWorksheetDto = {
        creditApplicationId: creditApplication.id,
        amountRequested: Constants.MinimumProductsLoanAmount,
        deliveryOn: deliveryDate,
        firstPaymentOn: minFirstPaymentOn,
        paymentFrequency,
        term,
        includeInsurance: false,
        addFastPayments: false,
        fundConfirmationNote: null,
        paymentPlanId: merchantPaymentPlan ? merchantPaymentPlan.id : EPaymentPlan.regularDailyInterests,
        merchantPayments: [
          { merchantId: creditApplication.merchantId, amount: 750, paymentMethod: defaultPaymentMethod },
        ],
        status: EWorksheetStatus.Draft,
        vehicleFinancingTerm: merchantPaymentPlan ? merchantPaymentPlan.loanTerm : ETermMax.twelve,
        creditInsurance: null,
        extendedWarranty: null,
        replacementOrGapInsurance: null,
      }

      dispatchEffect(productsWorksheetEffects.create(newWorksheet))
        .then(() => navigate(route))
        .catch(reportErrorToConsole)
    } else {
      navigate(route)
    }
  }
  return (
    <Paper elevation={3} sx={{ borderRadius: 2, p: 2, mb: 3 }}>
      <Box sx={{ m: 1 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={2} marginBottom={0.5}>
            <ButtonGroup variant="outlined" size="large">
              <Button
                id="btnEditWorksheet"
                component={Link}
                variant="outlined"
                href={`/Applications/products/${creditApplication.id}/worksheet`}
                disabled={!worksheet}
              >
                {t('worksheet.financing')}
              </Button>
              <Button size="small" onClick={createWorksheet} disabled={editWorksheetDisabled}>
                <EditIcon fontSize="large" />
              </Button>
            </ButtonGroup>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            alignContent="center"
            divider={<Divider orientation="vertical" flexItem sx={{ height: 25, alignSelf: 'center' }} />}
          >
            <Typography variant="body1" component="div" gutterBottom>
              {t('common.LastModification')} : {worksheet && formatDateTime(worksheet?.updatedOn)}
            </Typography>

            <IconButton
              onClick={onWorksheetHistoryClicked}
              disabled={worksheet === null}
              sx={{
                color: worksheet === null ? '' : 'primary.main',
                fontSize: 'large',
              }}
            >
              <HistoryIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Divider />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          alignItems="top"
          marginTop={1}
          justifyContent="space-evenly"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <span>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.decision')} :{' '}
              {worksheet?.finalDecision && t(`enum.eWorkSheetDecision.${worksheet.finalDecision.decision}`)}
            </Typography>
          </span>
          <span>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.paymentFrequency')} : {worksheet && t(`worksheet.${worksheet?.paymentFrequency}`)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.term')} : {worksheet?.term} {worksheet?.term !== undefined && t('worksheet.month')}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.paymentAmount')} : {FormatCurrency(worksheet?.paymentAmountForFrequency)}
            </Typography>
          </span>
          <span>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheetCommon.amountRequested')} : {FormatCurrency(worksheet?.amountRequested)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.totalAmountFinanced')} : {FormatCurrency(worksheet?.totalAmountFinanced)}
            </Typography>
          </span>
        </Stack>
      </Box>
    </Paper>
  )
}

export default ProductsWorksheetInformationSection
