import React, { useEffect, useState } from 'react'
import {
  Stack,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Card,
  Paper,
  useTheme,
  IconButton,
  TextField,
} from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { CreditApplicationReservation } from '../../../data/types/CreditApplicationReservation'
import { CreditApplication, User } from '../../../data/types'
import { formatDateTime } from '../../../services/Formatter'

interface UnassignedProp {
  handleLockCreditApplication: (minutes: number) => void
  cardHeightCallback: (height: number) => void
}

const UnassignedCreditApplicationComponent = ({ handleLockCreditApplication, cardHeightCallback }: UnassignedProp) => {
  const [minutes, setMinutes] = React.useState(0)
  const { t } = useTranslation()
  const componentRef = React.useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (componentRef.current) {
      cardHeightCallback(componentRef.current.clientHeight)
    }
  }, [cardHeightCallback])
  return (
    <Stack alignItems="center" ref={componentRef}>
      <FormControl>
        <FormLabel id="Label">{t('lockCreditApplication.takingChargeFor')}</FormLabel>
        <RadioGroup
          value={minutes}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setMinutes(Number((event.target as HTMLInputElement).value))
          }
        >
          <FormControlLabel value="10" control={<Radio style={{ paddingLeft: '20px' }} />} label="10 minutes" />
          <FormControlLabel value="20" control={<Radio style={{ paddingLeft: '20px' }} />} label="20 minutes" />
          <FormControlLabel value="30" control={<Radio style={{ paddingLeft: '20px' }} />} label="30 minutes" />
        </RadioGroup>
        <Button
          sx={{ mt: 1, mr: 1 }}
          type="button"
          variant="outlined"
          disabled={minutes === 0}
          onClick={() => handleLockCreditApplication(minutes)}
        >
          {t('lockCreditApplication.takingCharge')}
        </Button>
      </FormControl>
    </Stack>
  )
}

interface AssignedProp {
  lock: CreditApplicationReservation
  currentUser: User | null
  handleDeleteLockCreditApplication: (creditLock: CreditApplicationReservation) => void
  handleAddTimeToLock: (creditLock: CreditApplicationReservation, minutes: number) => void
  cardHeightCallback: (height: number) => void
}

const AssignedCreditApplicationComponent = ({
  lock,
  currentUser,
  handleDeleteLockCreditApplication,
  handleAddTimeToLock,
  cardHeightCallback,
}: AssignedProp) => {
  const [minutes, setMinutes] = React.useState(0)
  const [hours, setHours] = React.useState(0)
  const componentRef = React.useRef<HTMLDivElement>(null)

  const handleMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const minutesFromEvent = Number(event.target.value)
    if (minutesFromEvent >= 0) setMinutes(Number(event.target.value))
  }

  const handleHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hoursFromEvent = Number(event.target.value)
    if (hoursFromEvent >= 0) setHours(Number(event.target.value))
  }

  useEffect(() => {
    if (componentRef.current) {
      cardHeightCallback(componentRef.current.clientHeight)
    }
  }, [cardHeightCallback])

  const { t } = useTranslation()
  const isOwnLock = currentUser?.id === lock.userId
  const canOverrideCreditAppLock = currentUser?.roles.includes('SystemAdmin') || isOwnLock
  return (
    <Stack alignItems="center" ref={componentRef}>
      <span style={{ width: '80%', display: 'flex', justifyContent: 'space-between' }}>
        <Typography fontWeight="bold" fontSize={14}>
          {t('lockCreditApplication.reservedBy')}
        </Typography>

        <Typography fontSize={14}>
          {currentUser?.id === lock.userId ? t('lockCreditApplication.yourself') : lock.userName}
        </Typography>
      </span>
      <span style={{ width: '80%', display: 'flex', justifyContent: 'space-between' }}>
        <Typography fontWeight="bold" fontSize={14}>
          {t('lockCreditApplication.start')}
        </Typography>

        <Typography fontSize={14}>{formatDateTime(lock.lockedOn)}</Typography>
      </span>
      <span style={{ width: '80%', display: 'flex', justifyContent: 'space-between' }}>
        <Typography fontWeight="bold" fontSize={14}>
          {t('lockCreditApplication.end')}
        </Typography>

        <Typography fontSize={14}>{formatDateTime(lock.expiresOn)}</Typography>
      </span>
      <Button
        sx={{ mt: 1, mb: 1 }}
        type="button"
        variant="outlined"
        disabled={!canOverrideCreditAppLock}
        onClick={() => handleDeleteLockCreditApplication(lock)}
      >
        {t('lockCreditApplication.done')}
      </Button>
      <Typography fontSize={14} fontWeight="bold">
        {t('lockCreditApplication.addTime')}
      </Typography>
      <span style={{ width: '80%', display: 'flex', justifyContent: 'space-around', margin: 10 }}>
        <TextField
          id="outlined-number"
          label={t('lockCreditApplication.minutes')}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={minutes}
          onChange={handleMinutesChange}
          sx={{ mr: 1 }}
        />
        <TextField
          id="outlined-number"
          label={t('lockCreditApplication.hours')}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={hours}
          onChange={handleHoursChange}
          sx={{ ml: 1 }}
        />
      </span>
      <Button
        type="button"
        variant="outlined"
        disabled={!canOverrideCreditAppLock}
        onClick={() => handleAddTimeToLock(lock, minutes + hours * 60)}
      >
        {t('lockCreditApplication.add')}
      </Button>
    </Stack>
  )
}

interface SideBarProps {
  lock: CreditApplicationReservation
  handleLockCreditApplication: (minutes: number) => void
  handleDeleteLockCreditApplication: (creditLock: CreditApplicationReservation) => void
  handleAddTimeToLock: (creditLock: CreditApplicationReservation, minutes: number) => void
  app: CreditApplication
  handleTopSideBarOpen: (topSideBarOpen: boolean) => void
  currentUser: User | null
  setCardHeight: (height: number) => void
}

const CreditApplicationLockSideBar = ({
  lock,
  handleLockCreditApplication,
  handleDeleteLockCreditApplication,
  handleAddTimeToLock,
  app,
  handleTopSideBarOpen,
  currentUser,
  setCardHeight,
}: SideBarProps) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false)
  const theme = useTheme()
  const borderColor = theme.palette.divider
  const { t } = useTranslation()

  const handleButtonClick = () => {
    const sideBarState = isSideBarOpen
    setIsSideBarOpen(!sideBarState)
    handleTopSideBarOpen(!sideBarState)
  }

  useEffect(() => {
    const shouldSideBarBeOpen = lock && currentUser?.id !== lock.userId && new Date(lock.expiresOn) > new Date()
    setIsSideBarOpen(shouldSideBarBeOpen)
    handleTopSideBarOpen(shouldSideBarBeOpen)
  }, [lock, currentUser, handleTopSideBarOpen])

  return (
    <Card
      style={{
        position: 'fixed',
        left: isSideBarOpen ? 300 : 0,
        top: '18em',
        zIndex: 999,
        padding: 0,
        margin: 0,
        msTransitionDuration: '300ms',
        transitionProperty: 'all',
        overflowX: 'hidden',
        display: 'flex',
        border: '1px solid',
        borderRadius: 2,
        borderColor,
      }}
    >
      <IconButton onClick={handleButtonClick}>
        <MenuIcon />
      </IconButton>
      <Paper
        elevation={3}
        style={{
          msTransitionDuration: '300ms',
          transitionProperty: 'all',
          position: 'fixed',
          width: 300,
          border: '1px solid',
          borderRadius: 2,
          borderColor,
          left: isSideBarOpen ? 0 : -300,
        }}
      >
        <Stack alignItems="center" padding={2}>
          <Typography fontWeight="bold" fontSize="h6.fontSize">{`${t('common.application')} #${
            app.referenceNumber
          }`}</Typography>
          {new Date(lock.expiresOn).getTime() > new Date().getTime() ? (
            <AssignedCreditApplicationComponent
              lock={lock}
              currentUser={currentUser}
              handleDeleteLockCreditApplication={handleDeleteLockCreditApplication}
              handleAddTimeToLock={handleAddTimeToLock}
              cardHeightCallback={setCardHeight}
            />
          ) : (
            <UnassignedCreditApplicationComponent
              handleLockCreditApplication={handleLockCreditApplication}
              cardHeightCallback={setCardHeight}
            />
          )}
        </Stack>
      </Paper>
    </Card>
  )
}

export default React.memo(CreditApplicationLockSideBar)
