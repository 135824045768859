import { Box, List, ListSubheader, Typography } from '@mui/material'
import { appSelectors } from '@src/data/store/AppStore'
import { formatDateTime } from '@src/services/Formatter'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import FeaturesSwitch from './FeaturesSwitch'

const FeaturesSwitchForm = () => {
  const [showNextRefreshDate, setShowNextRefreshDate] = useState(false)
  const featureSwitchInfo = useSelector(appSelectors.getFeatureSwitches)!

  const featuresSwitches = featureSwitchInfo.featureSwitches

  const { t } = useTranslation()

  return (
    <>
      {showNextRefreshDate && (
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          <Typography sx={{ textAlign: 'center' }}>
            {t('featureSwitch.nextActivationOn')} {formatDateTime(featureSwitchInfo.nextRefreshDate)}
          </Typography>
        </Box>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <List
          sx={{ minWidth: 350, maxWidth: '100%' }}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {t('featureSwitch.featureSwitchPanel')}
            </ListSubheader>
          }
        >
          {featuresSwitches.length === 0 ? (
            <Typography variant="body1" sx={{ textAlign: 'center', marginTop: 2 }}>
              {t('featureSwitch.noFeatureAvailable')}
            </Typography>
          ) : (
            featuresSwitches.map((featureSwitch) => (
              <FeaturesSwitch
                key={featureSwitch.id}
                featureSwitch={featureSwitch}
                setShowNextRefreshDate={setShowNextRefreshDate}
              />
            ))
          )}
        </List>
      </Box>
    </>
  )
}

export default FeaturesSwitchForm
