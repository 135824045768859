import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { ExistingOnGoingLoans } from '@src/data/types/CustomerReferenceSchema'
import { FormatCurrencyNoDecimals } from '@src/services/Formatter'
import { nanoid } from 'nanoid'
import { useTranslation } from 'react-i18next'
import Row from './tableRow'

interface ExistingOnGoingLoansProps {
  loans: ExistingOnGoingLoans[]
}

const OnGoingLoans = ({ loans }: ExistingOnGoingLoansProps) => {
  const { t } = useTranslation()
  const getJointLoanText = (isJointLoan: boolean | null) => {
    if (isJointLoan === null) return t('common.notAvailable')
    return isJointLoan ? t('creditReport.associationCode.J') : t('creditReport.associationCode.I')
  }
  const getMainApplicantText = (isMainApplicant: boolean | null) => {
    if (isMainApplicant === null) return t('common.notAvailable')
    return isMainApplicant ? t('enum.beneficiaryType.applicant') : t('enum.beneficiaryType.coApplicant')
  }
  return (
    <Row id="onGoingLoans" name={t('creditReport.onGoingLoans')}>
      <TableContainer>
        <Table sx={{ display: 'contents' }} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{t('creditReport.loanId')}</TableCell>
              <TableCell align="left">{t('creditReport.customerId')}</TableCell>
              <TableCell align="left">{t('creditReport.amountRequested')}</TableCell>
              <TableCell align="left">{t('creditReport.monthlyPayment')}</TableCell>
              <TableCell align="left">{t('creditReport.accountBalance')}</TableCell>
              <TableCell align="left">{t('common.applicantType')}</TableCell>
              <TableCell align="left">{t('creditReport.association')}</TableCell>
              <TableCell align="left">{t('creditReport.financingProgramId')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loans.map((item: ExistingOnGoingLoans) => (
              <TableRow key={nanoid()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left">{item.loanId}</TableCell>
                <TableCell align="left">{item.customerId}</TableCell>
                <TableCell align="left">{FormatCurrencyNoDecimals(item.amountRequested)}</TableCell>
                <TableCell align="left">{FormatCurrencyNoDecimals(item.monthlyPayment)}</TableCell>
                <TableCell align="left">{FormatCurrencyNoDecimals(item.accountBalance)}</TableCell>
                <TableCell align="left">{getMainApplicantText(item.isMainApplicant)}</TableCell>
                <TableCell align="left">{getJointLoanText(item.isJointLoan)}</TableCell>
                <TableCell align="left">{item.financingProgramId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Row>
  )
}

export default OnGoingLoans
