import CreateIcon from '@mui/icons-material/Create'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import InsertCommentIcon from '@mui/icons-material/InsertComment'
import { useTranslation } from 'react-i18next'
import IconButtonWithTooltip from '../../components/IconButtonWithTooltip'
import { Constants, CreditApplicationTask } from '../../data/types'

interface Props {
  task: CreditApplicationTask
  editTask: (task: CreditApplicationTask) => void
  newComment: (task: CreditApplicationTask) => void
  removeTask: (task: CreditApplicationTask) => void
  editDisabled: boolean
}

const ActionsTasks = ({ task, editTask, newComment, removeTask, editDisabled }: Props): JSX.Element | null => {
  const { t } = useTranslation()

  return (
    <div>
      <IconButtonWithTooltip
        tooltip={t('tasks.editTask')}
        icon={<CreateIcon />}
        onClick={() => editTask(task)}
        disabled={editDisabled}
      />

      <IconButtonWithTooltip
        tooltip={t('tasks.deleteTask')}
        icon={<DeleteForeverIcon />}
        onClick={() => removeTask(task)}
        disabled={task.createdByUserId === Constants.SystemUserId || editDisabled}
      />

      <IconButtonWithTooltip
        tooltip={t('tasks.addComment')}
        icon={<InsertCommentIcon />}
        onClick={() => newComment(task)}
        disabled={editDisabled}
      />
    </div>
  )
}

export default ActionsTasks
