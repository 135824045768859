import { Typography, useTheme } from '@mui/material'
import { creditSelectors } from '@src/data/store/CreditApplication'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import { NormsMessage, NormsMessageKey, ValueListItem } from '../../../data/types'
import { NormCreditDecision } from '../../../data/types/CreditDecisionSchema'
import DecisonIcon from './decisonIcon'

interface Props {
  normDecision: NormCreditDecision | null
  normsMessage: NormsMessage[]
  applicationSuggestedAmount: number
}

const NormIceberg = ({ normsMessage, normDecision, applicationSuggestedAmount }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const creditRefusalReasons = useAppSelector<ValueListItem[]>(appSelectors.getCreditRefusalReasons)

  const handleReasonsToDisplay = (norm: NormsMessage) => {
    const reason = creditRefusalReasons.find((r) => r.id === norm.id)

    return reason?.text ?? t(`CreditDecisionNorms.${norm.message as NormsMessageKey}`)
  }
  const shoulDisplaySuggestedLoan = useAppSelector(creditSelectors.getFinancingProgramConfigs).useSuggestedLoan

  return (
    <>
      <Typography variant="h6" component="h3" gutterBottom>
        {t('credit.icebergStandards')}
      </Typography>
      <DecisonIcon decision={normDecision?.decision} />
      <Typography variant="subtitle1" component="p">
        {t(`credit.${normDecision?.decision ?? 'pending'}`)}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
        component="p"
      >
        {t('credit.interestRate')}: {normDecision?.interestRate}%
      </Typography>
      {shoulDisplaySuggestedLoan && (
        <Typography
          variant="subtitle1"
          sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
          component="p"
        >
          {t('credit.suggestedLoanAmountForApplication')}: ${applicationSuggestedAmount}
        </Typography>
      )}
      <Typography
        variant="subtitle1"
        sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
        component="p"
      >
        {t('credit.maxPayment')}: ${normDecision?.maxPmtAmount}
      </Typography>
      {normsMessage.map((norm) => (
        <Typography
          key={norm.id}
          variant="subtitle1"
          sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
          component="p"
        >
          {norm.applicantType && t(`common.${norm.applicantType}`).concat(':')} {handleReasonsToDisplay(norm)}
        </Typography>
      ))}
    </>
  )
}

export default NormIceberg
