import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Grid, InputAdornment } from '@mui/material'
import { CreditDecisionOverrideLimits } from '@src/data/types/CreditDecisionOverrideLimits'
import { formatSignedNumber } from '@src/services/Formatter'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ActionsDialog, InputTextField, SelectComponent, buildSelectValueListFromEnum } from '../../../components'
import MultipleCheckboxList from '../../../components/MultipleCheckboxList'
import { useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import { creditSelectors } from '../../../data/store/CreditApplication'
import { personalLoanworksheetSelectors } from '../../../data/store/PersonalLoanWorksheet'
import {
  CreditApplication,
  EBankStatementsRequiredFor,
  EDecision,
  EDecisionList,
  EExistingCarLoanStatusList,
  EFinancingProgram,
  SelectValueListItem,
  ValueListItem,
} from '../../../data/types'
import { FinalCreditDecision, WrapperFinalCreditDecisionSchema } from '../../../data/types/CreditDecisionSchema'
import { reportErrorToConsole } from '../../../services/error-logger'

interface Props {
  creditApplication: CreditApplication
  creditApplicationDecisionOverrideLimits: CreditDecisionOverrideLimits | null
  open: boolean
  canApprove: boolean
  financingProgramId: EFinancingProgram
  onCancel: () => void
  onConfirm: (data: FinalCreditDecision) => void
}

const CreditDecisionDialog = ({
  open,
  creditApplication,
  creditApplicationDecisionOverrideLimits,
  canApprove,
  financingProgramId,
  onCancel,
  onConfirm,
}: Props) => {
  const { t } = useTranslation()
  const creditRefusalReasons = useAppSelector<ValueListItem[]>(appSelectors.getCreditRefusalReasons)

  const creditDecision = {
    creditApplicationId: creditApplication.id,
    interestRate: creditApplication.finalCreditDecision.interestRate,
    cashdownPercent: creditApplication.decisionOverrides?.cashdownPercent,
    maxPmtAmount: creditApplication.finalCreditDecision.maxPmtAmount,
    maxAmountFinanced: creditApplication.finalCreditDecision.maxAmountFinanced,
    maxTermDuration: creditApplication.finalCreditDecision.maxTermDuration,
    revisionId: creditApplication.currentRevisionId,
    decision: creditApplication.finalCreditDecision.decision,
    bankStatementsRequiredFor: creditApplication.decisionOverrides?.bankStatementsRequiredFor,
    noCoAplicant: !creditApplication.coApplicant,
    existingCarLoanStatus: creditApplication.decisionOverrides?.existingCarLoanStatus,
    loanPurpose: creditApplication.loanPurposeId,
  }

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<FinalCreditDecision>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: creditDecision,
    resolver: yupResolver(
      WrapperFinalCreditDecisionSchema(
        creditApplicationDecisionOverrideLimits?.paymentUpperLimit ??
          creditApplication.normsCreditDecision!.maxPmtAmount,
        creditApplicationDecisionOverrideLimits?.paymentLowerLimit ?? 0,
      ),
    ),
  })

  const decision = watch('decision')
  const createdWithPromotion = useAppSelector(creditSelectors.createdWithPromotion)
  const hasConfirmedPromotion = useAppSelector(personalLoanworksheetSelectors.hasConfirmedPromotion)
  const isWorksheetCreated = useAppSelector(personalLoanworksheetSelectors.isCreated)
  const hasMerchantRateModifier = useAppSelector(creditSelectors.hasMerchantRateModifier)

  const isInterestRateFieldDisabled = () => {
    if (isWorksheetCreated && hasConfirmedPromotion) return true
    if (isWorksheetCreated && !hasConfirmedPromotion) return false
    if (createdWithPromotion && !hasConfirmedPromotion) return true
    return false
  }

  useEffect(() => {
    if (decision !== EDecision.declined) setValue('refusalReasonCodes', [])
  }, [decision, setValue])

  const [resetList, setReset] = React.useState<boolean>(false)

  const selectedDecision = watch('decision')
  const [originalDecision] = React.useState<EDecision>(selectedDecision)

  const showDetail = selectedDecision !== EDecision.accept || canApprove

  const existingCarLoanStatusesList = useMemo(
    () => buildSelectValueListFromEnum(EExistingCarLoanStatusList, 'credit.existingCarLoanStatus', ''),
    [],
  )

  const decisionValuesList = useMemo(() => {
    return buildSelectValueListFromEnum(EDecisionList, 'credit', undefined, [EDecision.pending])
  }, [])

  const bankStatementValuesList = useMemo(() => {
    const ret = [] as SelectValueListItem[]
    ret.push({ label: '', value: '' })
    ret.push({
      label: `common.${EBankStatementsRequiredFor.Applicant}`,
      value: EBankStatementsRequiredFor.Applicant,
    })
    if (creditApplication.coApplicant) {
      ret.push({
        label: `common.${EBankStatementsRequiredFor.Coapplicant}`,
        value: EBankStatementsRequiredFor.Coapplicant,
      })
      ret.push({
        label: `common.${EBankStatementsRequiredFor.Both}`,
        value: EBankStatementsRequiredFor.Both,
      })
    }
    return ret
  }, [creditApplication.coApplicant])

  useEffect(() => {
    if (financingProgramId === EFinancingProgram.Personal) {
      setValue('existingCarLoanStatus', null)
      setValue('cashdownPercent', null)
    }
  }, [financingProgramId, setValue])
  const shoulDisplayDialogFields = useAppSelector(
    creditSelectors.getFinancingProgramConfigs,
  ).useCreditDecisionDialogFields

  return (
    <ActionsDialog
      disabled={selectedDecision === EDecision.accept && !canApprove}
      title={`${t('credit.icebergDecision') as string} - ${t(`credit.${creditApplication.finalCreditDecision.decision}`) as string}`}
      onCancel={() => {
        setReset(!resetList)
        onCancel()
      }}
      onConfirm={handleSubmit(onConfirm, reportErrorToConsole)}
      open={open}
    >
      <Grid container spacing={2} sx={{ mt: 1, maxWidth: selectedDecision === EDecision.declined ? '100%' : 500 }}>
        <Grid item xs={12}>
          <SelectComponent
            label={t('credit.decision') as string}
            items={decisionValuesList}
            {...register('decision')}
            error={errors?.decision}
            disabled={originalDecision === EDecision.declined || originalDecision === EDecision.error}
          />
          {selectedDecision === EDecision.accept && !canApprove && (
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Alert severity="error">{t('credit.taskAndDocumentRequiredMissing')}</Alert>
            </Grid>
          )}
          {selectedDecision === EDecision.declined && (
            <MultipleCheckboxList
              name="refusalReasonCodes"
              control={control}
              title={t('credit.refusalReason')}
              content={creditRefusalReasons}
              defaultValues={creditApplication.finalCreditDecision.refusalReasonCodes ?? []}
              reset={resetList}
            />
          )}
        </Grid>
        {selectedDecision !== EDecision.declined && showDetail && shoulDisplayDialogFields && (
          <>
            <Grid item xs={12} md={6}>
              <InputTextField
                error={errors?.interestRate}
                {...register('interestRate')}
                label={
                  hasMerchantRateModifier
                    ? t('credit.hasRateModifier', {
                        rate: formatSignedNumber(creditApplication.normsCreditDecision?.interestRateModifier),
                      })
                    : t('credit.interestRate')
                }
                InputProps={{
                  endAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
                required
                disabled={isInterestRateFieldDisabled()}
                forceBaseColor={hasMerchantRateModifier!}
                color={hasMerchantRateModifier ? 'warning' : 'primary'}
              />
            </Grid>

            {financingProgramId === EFinancingProgram.Auto && (
              <Grid item xs={12} md={6}>
                <InputTextField
                  error={errors?.cashdownPercent}
                  {...register('cashdownPercent')}
                  label={t('credit.cash')}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                />
              </Grid>
            )}

            {financingProgramId === EFinancingProgram.Auto && (
              <Grid item xs={12} md={6}>
                <SelectComponent
                  label={t('credit.bankStatements') as string}
                  disabled={selectedDecision !== EDecision.accept}
                  items={bankStatementValuesList}
                  {...register('bankStatementsRequiredFor')}
                  error={errors?.decision}
                />
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <InputTextField
                error={errors?.maxPmtAmount}
                {...register('maxPmtAmount')}
                label={t('credit.maxPayment')}
                InputProps={{
                  endAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputTextField
                {...register('maxTermDuration')}
                label={t('credit.maxTerme')}
                InputProps={{
                  endAdornment: <InputAdornment position="start">{t('credit.months')}</InputAdornment>,
                }}
                disabled
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputTextField
                error={errors?.maxAmountFinanced}
                {...register('maxAmountFinanced')}
                label={t('credit.authorizedLoanAmount')}
                InputProps={{
                  endAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </Grid>

            {financingProgramId === EFinancingProgram.Auto && (
              <Grid item xs={12} md={6}>
                <SelectComponent
                  label={t('credit.jointVehicule') as string}
                  items={existingCarLoanStatusesList}
                  {...register('existingCarLoanStatus')}
                  error={errors?.existingCarLoanStatus}
                  disabled={!creditApplication.coApplicant}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </ActionsDialog>
  )
}

export default React.memo(CreditDecisionDialog)
