import {
  Autorenew as AutorenewIcon,
  Bolt,
  Handyman,
  PanTool as PanToolIcon,
  PanToolOutlined as PanToolOutlinedIcon,
  PhotoSizeSelectActual as PhotoSizeIcon,
  RotateLeft as RotateLeftIcon,
  RotateRight as RotateRightIcon,
  ViewStream,
  ViewStreamOutlined,
} from '@mui/icons-material'
import { Checkbox, FormControlLabel, IconButton, Slider, Stack, Tooltip } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconButtonWithTooltip } from '../../../components'
import { EDocumentPageStatus, EDocumentStatus } from '../../../data/types'
import { DocumentPageDisplayInfo } from '../../../data/types/DocumentPageDisplayInfo'
import { flipAll, rotateLeftAll, rotateRightAll } from '../actions'

interface Props {
  setDocumentPagesInfos: React.Dispatch<React.SetStateAction<DocumentPageDisplayInfo[]>>
  onPageFiltersChanged: React.Dispatch<React.SetStateAction<string[]>>
  activePageFilters: string[]
  onResizingChange: (val: number) => void
  onToggleBanners: () => void
  showBanners: boolean
  onToggleDraggable: () => void
  isDraggable: boolean
  isActionDisabled: boolean
  userCanEditDocument: boolean
  setQuickTypePanelOpen: (value: React.SetStateAction<boolean>) => void
  activeDocumentStatus: EDocumentStatus
  setHasChange: (value: React.SetStateAction<boolean>) => void
}
const Header = ({
  setDocumentPagesInfos,
  onPageFiltersChanged,
  activePageFilters,
  onResizingChange,
  onToggleBanners,
  showBanners,
  onToggleDraggable,
  isDraggable,
  isActionDisabled,
  userCanEditDocument,
  setQuickTypePanelOpen,
  activeDocumentStatus,
  setHasChange,
}: Props) => {
  const { t } = useTranslation()

  const [showTools, setShowTools] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (activeDocumentStatus === EDocumentStatus.Refused)
      onPageFiltersChanged([...activePageFilters, EDocumentPageStatus.Deleted])
  }, [activeDocumentStatus, onPageFiltersChanged, activePageFilters])

  const onCheck = () => {
    return activePageFilters.includes(EDocumentPageStatus.Deleted)
      ? onPageFiltersChanged([EDocumentPageStatus.New, EDocumentPageStatus.Reviewed])
      : onPageFiltersChanged([...activePageFilters, EDocumentPageStatus.Deleted])
  }

  return (
    <Stack direction="row" justifyContent={showTools ? 'space-between' : 'end'} overflow="auto" spacing={2} margin={1}>
      <span>
        {showTools && (
          <Stack direction="row" spacing={2} alignItems="left" justifyContent="left">
            {!isActionDisabled && (
              <span>
                <IconButtonWithTooltip
                  tooltip={t('document.rotateLeft')}
                  icon={<RotateLeftIcon color={!userCanEditDocument ? 'inherit' : 'primary'} />}
                  onClick={() => {
                    setHasChange(true)
                    setDocumentPagesInfos((pages) => [...rotateLeftAll(pages)])
                  }}
                  disabled={!userCanEditDocument}
                />

                <IconButtonWithTooltip
                  tooltip={t('document.flipPages')}
                  icon={<AutorenewIcon color={!userCanEditDocument ? 'inherit' : 'primary'} />}
                  onClick={() => {
                    setHasChange(true)
                    setDocumentPagesInfos((pages) => [...flipAll(pages)])
                  }}
                  disabled={!userCanEditDocument}
                />

                <IconButtonWithTooltip
                  tooltip={t('document.rotateRight')}
                  icon={<RotateRightIcon color={!userCanEditDocument ? 'inherit' : 'primary'} />}
                  onClick={() => {
                    setHasChange(true)
                    setDocumentPagesInfos((pages) => [...rotateRightAll(pages)])
                  }}
                  disabled={!userCanEditDocument}
                />
              </span>
            )}

            <Stack spacing={1.5} direction="row" alignItems="center">
              <PhotoSizeIcon fontSize="small" color="primary" />
              <Slider
                onChange={(_event, value) => onResizingChange(value as number)}
                sx={{ width: 250 }}
                marks
                defaultValue={5}
                max={6}
                min={1}
                step={1}
              />
              <PhotoSizeIcon color="primary" />
            </Stack>

            {!isActionDisabled && (
              <>
                <Tooltip title={t('document.toggleBanners')}>
                  <IconButton onClick={onToggleBanners}>
                    {showBanners ? <ViewStream color="primary" /> : <ViewStreamOutlined color="primary" />}
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('document.reorderPages')}>
                  <IconButton onClick={onToggleDraggable} disabled={isActionDisabled || !userCanEditDocument}>
                    {isDraggable ? (
                      <PanToolIcon color={isActionDisabled || !userCanEditDocument ? 'inherit' : 'primary'} />
                    ) : (
                      <PanToolOutlinedIcon color={isActionDisabled || !userCanEditDocument ? 'inherit' : 'primary'} />
                    )}
                  </IconButton>
                </Tooltip>
              </>
            )}
            <FormControlLabel
              control={
                <Checkbox onChange={onCheck} checked={activePageFilters.includes(EDocumentPageStatus.Deleted)} />
              }
              label={t('document.showDeletedPages')}
            />
          </Stack>
        )}
      </span>

      <Stack direction="row" spacing={2}>
        <IconButton onClick={() => setShowTools((prev) => !prev)}>
          <Handyman color="primary" />
        </IconButton>
        <Tooltip title={t('document.quickType')}>
          <IconButton onClick={() => setQuickTypePanelOpen((prev) => !prev)}>
            <Bolt color="primary" fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  )
}

export default Header
