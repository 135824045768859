import DeleteIcon from '@mui/icons-material/Delete'
import { FormGroup, Grid, IconButton } from '@mui/material'
import { Control, FieldError, FieldErrorsImpl, Merge, UseFormRegister, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InputTextField, SelectComponent } from '../../../../../../../components'
import { useAppSelector } from '../../../../../../../data/store'
import { appSelectors } from '../../../../../../../data/store/AppStore'
import { ApplicantOtherIncome } from '../../../../../../../data/types'
import { TranslatedEnum } from '../../../../../../../data/types/TranslatedEnum'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formControl: Control<any>
  errors: Merge<FieldError, FieldErrorsImpl<ApplicantOtherIncome>> | undefined
  name: string
  onDeleteClicked: () => void
  editDisabled: boolean
}

const OtherIncomeComponent = ({ register, errors, name, onDeleteClicked, formControl, editDisabled }: Props) => {
  const { t } = useTranslation()
  const otherIncomeTypeEnum = useAppSelector(appSelectors.getOtherIncomeTypesEnum)
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum
  const typeId: number = +useWatch({
    control: formControl,
    name: `${name}.typeId`,
  })

  return (
    <>
      <Grid item xs={12} md={typeId === 6 ? 4 : 6}>
        <SelectComponent
          items={Object.values(otherIncomeTypeEnum).map((item) => ({ label: item[lang], value: item.id }))}
          label={t('editCreditApplication.income.other.typeId') as string}
          {...register(`${name}.typeId`)}
          error={errors?.typeId}
          disabled={onDeleteClicked === undefined || editDisabled}
        />
      </Grid>
      {typeId === 6 && (
        <Grid item xs={12} md={4}>
          <InputTextField
            error={errors?.description}
            label="Description"
            {...register(`${name}.description`)}
            sx={{ flex: 1 }}
            disabled={onDeleteClicked === undefined || editDisabled}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
      )}
      <Grid item xs={12} md={typeId === 6 ? 4 : 6}>
        <FormGroup row>
          <InputTextField
            error={errors?.annualAmount}
            label={t('editCreditApplication.income.other.annualAmount')}
            {...register(`${name}.annualAmount`)}
            sx={{ flex: 1 }}
            disabled={onDeleteClicked === undefined || editDisabled}
          />
          <IconButton
            sx={{ mb: 3, display: 'flex', justifyContent: 'flex-end' }}
            aria-label="delete"
            onClick={onDeleteClicked}
            disabled={editDisabled}
          >
            <DeleteIcon />
          </IconButton>
        </FormGroup>
      </Grid>
    </>
  )
}

export default OtherIncomeComponent
