import {
  CircularProgress,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
} from '@mui/material'
import { ChangeEvent, MouseEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface ColumnDef<TData> {
  field: string
  headerName: string
  numeric?: boolean
  sortable?: boolean
  minWidth?: number
  width?: number | string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  conditionalSx?: (row: TData, value: any) => SxProps<Theme>
  valueGetter?: (data: TData) => string | number
  renderCell?: (row: TData, value: unknown) => JSX.Element
}

type Order = 'asc' | 'desc'

function descendingComparator<T>(a: T, b: T, valueGetter: (data: T) => string | number) {
  const aVal = valueGetter(a)
  const bVal = valueGetter(b)
  if (bVal < aVal) {
    return -1
  }
  if (bVal > aVal) {
    return 1
  }
  return 0
}

function getComparator<Data>(order: Order, valueGetter: (data: Data) => string | number): (a: Data, b: Data) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, valueGetter)
    : (a, b) => -descendingComparator(a, b, valueGetter)
}

interface EnhancedTableHeadProps<Data> {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: keyof Data | null
  columns: ColumnDef<Data>[]
}

const EnhancedTableHead = <TData extends Record<string, string | number>>({
  order,
  orderBy,
  onRequestSort,
  columns,
}: EnhancedTableHeadProps<TData>) => {
  const createSortHandler = (property: keyof TData) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {columns.map((col) => (
          <TableCell
            key={col.field}
            align={col.numeric ? 'right' : 'left'}
            sx={{ minWidth: col.minWidth, width: col.width ? col.width : 'auto' }}
            sortDirection={col.sortable !== false ? (orderBy === col.field ? order : false) : undefined}
          >
            {col.sortable === false ? (
              col.headerName
            ) : (
              <TableSortLabel
                active={orderBy === col.field}
                direction={orderBy === col.field ? order : 'asc'}
                onClick={createSortHandler(col.field)}
                sx={{ '&:focus': { color: 'inherit' } }}
              >
                {col.headerName}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableProps<Data> {
  rows: Data[]
  columns: ColumnDef<Data>[]
  getRowId: (data: Data) => string | number
  selected?: string | number | null
  onSelection?: (id: string | number) => void
  loading?: boolean
  bottomStaticRow?: JSX.Element
}

const EnhancedTable = <TData extends Record<string, string | number>>({
  rows,
  columns,
  getRowId,
  selected,
  onSelection,
  loading,
  bottomStaticRow,
}: EnhancedTableProps<TData>) => {
  const { t } = useTranslation()
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof TData | null>(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const handleRequestSort = (_event: MouseEvent<unknown>, property: keyof TData) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(order === 'asc' ? property : null) // reset on third click
  }

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  const visibleRows = useMemo(() => {
    let vRows = [...rows]
    const orderByCol = columns.find((col) => col.field === orderBy)
    if (orderBy) vRows = vRows.sort(getComparator<TData>(order, orderByCol?.valueGetter || ((data) => data[orderBy])))
    vRows = vRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    return vRows
  }, [columns, order, orderBy, page, rows, rowsPerPage])

  return (
    <>
      <TableContainer>
        <Table>
          <EnhancedTableHead<TData>
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            columns={columns}
          />
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {visibleRows.map((row) => {
                  const id = getRowId(row)
                  return (
                    <TableRow
                      onClick={() => onSelection && onSelection(id)}
                      role={onSelection ? 'radio' : 'row'}
                      key={id}
                      selected={selected === id}
                      hover={Boolean(onSelection)}
                      sx={{ cursor: onSelection ? 'pointer' : 'default' }}
                    >
                      {columns.map((column) => {
                        const value = column.valueGetter ? column.valueGetter(row) : row[column.field]
                        const sx: SxProps<Theme> = {
                          whiteSpace: 'nowrap',
                          minWidth: column.minWidth,
                          width: column.width ? column.width : 'auto',
                        }
                        const conditionalSx = column.conditionalSx ? column.conditionalSx(row, value) : {}
                        return (
                          <TableCell
                            key={column.field}
                            align={column.numeric ? 'right' : 'left'}
                            sx={
                              typeof conditionalSx === 'function'
                                ? (theme) => ({
                                    ...sx,
                                    ...conditionalSx(theme),
                                  })
                                : { ...sx, ...conditionalSx }
                            }
                          >
                            {column.renderCell ? column.renderCell(row, value) : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={columns.length} />
                  </TableRow>
                )}
                {bottomStaticRow}
                {visibleRows.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      {t('common.noData')}
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}

export default EnhancedTable
