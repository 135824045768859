import { BaseWorksheet, baseWorksheetFundingThresholdExtendSchema, baseWorksheetSchema } from './BaseWorksheetSchema'
import { ParameterizedMessage } from './ParameterizedMessage'
import yupExtInt from './common/SchemaTypes'
import * as yup from './common/yup-extended'
import { EPaymentPlan, EPaymentPlanList, EWorksheetDecision } from './constants'

const iFinanceBaseWorksheetSchema = yup.default
  .object({
    amountRequested: yupExtInt.double.positive(),
    includeInsurance: yup.default.boolean().default(false),
    addFastPayments: yup.default.boolean().default(false),
    fundConfirmationNote: yup.default.string().default('').nullable(),
    paymentPlanId: yup.default.mixed<EPaymentPlan>().required().oneOf(EPaymentPlanList),
  })
  .concat(baseWorksheetSchema)

export const IFinanceBaseWorksheetSchema = yup.default.object({ ...iFinanceBaseWorksheetSchema.fields })

export type IFinanceBaseWorksheet = yup.default.InferType<typeof IFinanceBaseWorksheetSchema> &
  BaseWorksheet & {
    insuranceFee: number
    insuranceTax: number
    insuranceRate: number
    insuranceCompanyId: string | null
    totalInterestAmount: number
  }

export type IFinanceBaseWorksheetRevision = IFinanceBaseWorksheet & {
  CurrentRevisionId: string
}

const iFinanceBaseWorksheetDecisionDTOSchema = yup.default.object({
  creditApplicationId: yup.default.string(),
  otherReason: yup.default.string().default('').nullable(),
  decision: yup.default.mixed<EWorksheetDecision>(),
  fundingThresholdExtend: baseWorksheetFundingThresholdExtendSchema
    .notRequired()
    .nullable()
    .default(baseWorksheetFundingThresholdExtendSchema.getDefault()),
  refusalMessages: yup.default.mixed<ParameterizedMessage[]>().default([]),
  versionTag: yup.default.string(),
})

export const IFinanceBaseWorksheetDecisionDTOSchema = yup.default.object({
  ...iFinanceBaseWorksheetDecisionDTOSchema.fields,
})

export type IFinanceBaseWorksheetDecisionDTO = yup.default.InferType<typeof iFinanceBaseWorksheetDecisionDTOSchema>
