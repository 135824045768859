import { endOfDay, startOfDay, subDays } from 'date-fns'
import * as yup from './common/yup-extended'

export const AgentLogEntryFilterSchema = yup.default.object({
  startDate: yup.default
    .date()
    .required()
    .default(startOfDay(subDays(new Date(), 7))),
  endDate: yup.default.date().required().default(endOfDay(new Date())),
})

export type AgentLogEntryFilterSchemaType = yup.default.InferType<typeof AgentLogEntryFilterSchema>
