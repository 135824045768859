import { ProductsComputeRequestDto } from '@src/data/types/ProductsComputeRequestDto'
import { ProductsComputeResultDto } from '@src/data/types/ProductsComputeResultDto'
import {
  ProductsWorksheet,
  ProductsWorksheetDecisionDTO,
  ProductsWorksheetDto,
  ProductsWorksheetRevision,
} from '@src/data/types/ProductsWorksheetSchema'
import allApi from '../../api'
import { makeGetById, makeGetListWithIds, makeSave } from '../effects/standard-side-effects'
import {
  COMPUTE_PRODUCTS_LOAN,
  GET_PRODUCTS_WORKSHEET,
  GET_PRODUCTS_WORKSHEET_REVISIONS,
  ProductsWorksheetSlice,
  SAVE_PRODUCTS_WORKSHEET,
  SAVE_PRODUCTS_WORKSHEET_DECISION,
} from './products-worksheet-store'

const productsWorksheetEffects = {
  getById: (id: string) =>
    makeGetById<ProductsWorksheet>(
      GET_PRODUCTS_WORKSHEET,
      allApi.productsWorksheet.getById,
      ProductsWorksheetSlice.actions.setCurrent,
      {
        id,
      },
    ),
  create: (data: ProductsWorksheetDto) =>
    makeSave<ProductsWorksheetDto, ProductsWorksheet>(
      SAVE_PRODUCTS_WORKSHEET,
      allApi.productsWorksheet.create,
      ProductsWorksheetSlice.actions.setCurrent,
      data,
    ),
  update: (data: ProductsWorksheetDto) =>
    makeSave<ProductsWorksheetDto, ProductsWorksheet>(
      SAVE_PRODUCTS_WORKSHEET,
      allApi.productsWorksheet.update,
      ProductsWorksheetSlice.actions.setCurrent,
      data,
    ),
  computeProductsLoan: (data: ProductsComputeRequestDto) =>
    makeSave<ProductsComputeRequestDto, ProductsComputeResultDto>(
      COMPUTE_PRODUCTS_LOAN,
      allApi.productsWorksheet.computeProductsLoan,
      ProductsWorksheetSlice.actions.setLoanComputed,
      data,
    ),
  getWorksheetRevisions: (creditApplicationId: string) =>
    makeGetListWithIds<ProductsWorksheetRevision>(
      GET_PRODUCTS_WORKSHEET_REVISIONS,
      allApi.productsWorksheet.getWorksheetRevisions,
      ProductsWorksheetSlice.actions.setCurrentRevisions,
      { creditApplicationId },
    ),
  updateDecision: (data: ProductsWorksheetDecisionDTO) =>
    makeSave<ProductsWorksheetDecisionDTO, ProductsWorksheet>(
      SAVE_PRODUCTS_WORKSHEET_DECISION,
      allApi.productsWorksheet.updateDecision,
      ProductsWorksheetSlice.actions.setCurrent,
      data,
    ),
}

export default productsWorksheetEffects
