import React from 'react'
import { useTranslation } from 'react-i18next'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Grid, useTheme } from '@mui/material'
import { useAppSelector } from '@src/data/store'
import { creditSelectors } from '@src/data/store/CreditApplication'
import { NormsMessage } from '../../../data/types'
import CreditWarningMessageList from './creditWarningMessageList'
import WarningMessageList from './warningMessageList'

interface Props {
  applicantCreditWarnings: NormsMessage[] | null
  coapplicantCreditWarnings: NormsMessage[] | null
  commonCreditWarnings: NormsMessage[] | null
  worksheetWarnings: NormsMessage[] | null
  expanded: boolean
}

const WarningMessageAccordion = ({
  applicantCreditWarnings,
  coapplicantCreditWarnings,
  commonCreditWarnings,
  worksheetWarnings,
  expanded,
}: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const borderColor = theme.palette.mode === 'dark' ? 'error.main' : 'error.light'

  const applicantWarning = applicantCreditWarnings !== null && applicantCreditWarnings.length > 0 ? 1 : 0
  const coapplicantWarning = coapplicantCreditWarnings !== null && coapplicantCreditWarnings.length > 0 ? 1 : 0
  const numberOfApplicantsListMessages: number = applicantWarning + coapplicantWarning
  const config = useAppSelector(creditSelectors.getFinancingProgramConfigs)

  return (
    <Accordion defaultExpanded={expanded} sx={{ mb: 2 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ borderColor, borderWidth: 1, borderStyle: 'solid' }}>
        <Typography sx={{ width: '100%', textAlign: 'center' }}>{t('common.warningMessage')}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ borderColor, borderWidth: 1, borderStyle: 'solid' }}>
        <Grid container columnSpacing={2}>
          {applicantCreditWarnings !== null && applicantCreditWarnings.length > 0 && (
            <CreditWarningMessageList
              messageTitle={t('warningMessages.applicantWarning')}
              creditWarningMessages={applicantCreditWarnings}
              size={numberOfApplicantsListMessages === 2 ? 'half' : null}
            />
          )}
          {coapplicantCreditWarnings !== null && coapplicantCreditWarnings.length > 0 && (
            <CreditWarningMessageList
              messageTitle={t('warningMessages.coapplicantWarning')}
              creditWarningMessages={coapplicantCreditWarnings}
              size={numberOfApplicantsListMessages === 2 ? 'half' : null}
            />
          )}
          {commonCreditWarnings != null && commonCreditWarnings.length > 0 && (
            <CreditWarningMessageList
              messageTitle={t('warningMessages.commonWarning')}
              creditWarningMessages={commonCreditWarnings}
              size={null}
            />
          )}
          {worksheetWarnings != null &&
            worksheetWarnings.length > 0 &&
            config.displayWorksheetWarningsAtApplicationLevel && (
              <WarningMessageList
                messageTitle={t('warningMessages.worksheetWarning')}
                normsMessages={worksheetWarnings}
                size={null}
              />
            )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default React.memo(WarningMessageAccordion)
