import { Button, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CreditApplication, EDecision } from '../../../data/types'
import DecisonIcon from './decisonIcon'
import RefinancingIcebergDialog from './refinancingDialog'

interface Props {
  creditApplication: CreditApplication
  userCanApproveCreditApplication: boolean
}

const RefinancingSection = ({ creditApplication, userCanApproveCreditApplication }: Props) => {
  const [openRefinancingIcebergDialog, setOpenRefinancingIcebergDialog] = React.useState<boolean>(false)
  const refinancingApplicableAndEngagementDisabled =
    creditApplication.refinancing?.applyEngagement === false &&
    creditApplication.refinancing?.refinancingApplicable === true
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <>
      <Typography variant="h6" component="h3" gutterBottom>
        {t('credit.refinancing')}
      </Typography>
      {creditApplication.refinancing?.refinancingApplicable === false && (
        <>
          <p>{t('common.status')} : </p>
          <Typography
            variant="subtitle1"
            sx={{ backgroundColor: theme.palette.action.selected, marginBottom: 3 }}
            component="p"
          >
            {t('enum.eRefinancementStatus.NotEligible')}
          </Typography>
        </>
      )}

      {creditApplication.refinancing?.refinancingApplicable === null && (
        <>
          <p>{t('common.status')} : </p>
          <Typography
            variant="subtitle1"
            sx={{ backgroundColor: theme.palette.action.selected, marginBottom: 5 }}
            component="p"
          >
            {t('enum.eRefinancementStatus.FormMustBeEntered')}
          </Typography>
        </>
      )}
      {creditApplication.refinancing?.refinancingApplicable === true && (
        <>
          {creditApplication.refinancing.applyEngagement === true && <DecisonIcon decision={EDecision.accept} />}
          <Typography
            variant="subtitle1"
            sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
            component="p"
          >
            {t('browseCreditApplications.dataGridColumnHeaders.cvtNumber')}: {creditApplication.refinancing.cvtNumber}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
            component="p"
          >
            {t('worksheet.interestRate')}: {creditApplication.refinancing.engagementRate}%
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
            component="p"
          >
            {t('credit.commitmentDiscount')}: {creditApplication.refinancing.engagementAmount}$
          </Typography>
        </>
      )}
      {refinancingApplicableAndEngagementDisabled && (
        <Typography
          variant="subtitle1"
          sx={{ backgroundColor: theme.palette.action.selected, marginTop: 3 }}
          component="p"
        >
          {t('enum.eRefinancementStatus.EngagementDisabled')}
        </Typography>
      )}

      <Button
        variant="contained"
        size="large"
        sx={{
          mt: 2,
        }}
        onClick={() => setOpenRefinancingIcebergDialog(true)}
        disabled={creditApplication.editLocked || !userCanApproveCreditApplication}
      >
        {t('credit.refinancing')}
      </Button>
      <RefinancingIcebergDialog
        open={openRefinancingIcebergDialog}
        onCancel={() => setOpenRefinancingIcebergDialog(false)}
        creditApplication={creditApplication}
        title={`${t('credit.icebergRefinancing')}`}
      />
    </>
  )
}

export default RefinancingSection
