import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { ActionsDialog } from '../../../components'
import ProofOfReleaseDetail from './proofOfReleaseDetail'
import { CreditReport } from '../../../data/types/CreditReportSchema'

interface Props {
  onCancel: () => void
  onConfirm: (data: Record<string, boolean>) => void
  applicantCreditReport: CreditReport | null
  coApplicantCreditReport: CreditReport | null
  open: boolean
  title: string
  releaseRequiredByLoanId: Record<string, boolean>
}

const ProofOfReleaseDialog = ({
  onCancel,
  onConfirm,
  applicantCreditReport,
  coApplicantCreditReport,
  open,
  title,
  releaseRequiredByLoanId,
}: Props) => {
  const trades = applicantCreditReport?.trades.filter(
    (trade) => trade.creditorNumber !== import.meta.env.VITE_CREDITOR_NUMBER,
  )

  const [selectionsInError, setSelectionsInError] = useState<string[]>([])

  const [releaseRequiredByLoanIdModified, setReleaseRequiredByLoanIdModified] =
    useState<Record<string, boolean>>(releaseRequiredByLoanId)

  const handleClickProofOfReleaseRequired = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target
    const updatedRecord = {
      ...releaseRequiredByLoanIdModified,
      [name]: event.target.value === 'yes',
    }
    setReleaseRequiredByLoanIdModified(updatedRecord)
  }
  const handleConfirm = () => {
    const keys = Object.keys(releaseRequiredByLoanIdModified)
    const keyInError = keys.filter((key) => releaseRequiredByLoanIdModified[key] === null)
    if (keyInError.length > 0) {
      setSelectionsInError(keyInError)
    } else {
      setSelectionsInError([])
      onConfirm(releaseRequiredByLoanIdModified)
    }
  }

  const handleOnCancel = () => {
    setReleaseRequiredByLoanIdModified(releaseRequiredByLoanId)
    setSelectionsInError([])
    onCancel()
  }

  return (
    <Grid>
      <ActionsDialog onCancel={handleOnCancel} onConfirm={handleConfirm} open={open} title={title}>
        {trades?.map((trade) => {
          const value = releaseRequiredByLoanIdModified[trade.accountNumber ?? '']
          return (
            <ProofOfReleaseDetail
              trade={trade}
              coApplicantCreditReport={coApplicantCreditReport}
              key={trade.accountNumber}
              handleClickProofOfReleaseRequired={handleClickProofOfReleaseRequired}
              value={value}
              selectionsInError={selectionsInError}
            />
          )
        })}
      </ActionsDialog>
    </Grid>
  )
}

export default ProofOfReleaseDialog
