import { Grid } from '@mui/material'
import { DatePicker } from '@src/components/DatePicker'
import InputTextField from '@src/components/InputTextField'
import SelectComponent from '@src/components/SelectComponent'
import { CreditApplication, EPaymentFrequency, EPaymentPlan } from '@src/data/types'
import { ProductsWorksheet, ProductsWorksheetDto } from '@src/data/types/ProductsWorksheetSchema'
import { useEffect, useMemo, useState } from 'react'
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  useWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useProductsTerms } from '../../editWorksheet-hooks'
import { getProductsMinMaxPaymentDates } from '../productWorksheet-selectors'

interface Props {
  creditApplication: CreditApplication
  worksheet: ProductsWorksheet
  control: Control<ProductsWorksheetDto>
  register: UseFormRegister<ProductsWorksheetDto>
  watch: UseFormWatch<ProductsWorksheetDto>
  setValue: UseFormSetValue<ProductsWorksheetDto>
  getValues: UseFormGetValues<ProductsWorksheetDto>
  errors: FieldErrors<ProductsWorksheetDto>
  merchantPayments: { label: string; value: string }[]
  disabledAmountInput?: boolean
  maxTermDuration: number
}

const WorksheetDetails = ({
  creditApplication,
  worksheet,
  register,
  errors,
  control,
  watch,
  setValue,
  merchantPayments,
  disabledAmountInput,
  maxTermDuration,
}: Props) => {
  const { t } = useTranslation()
  const [paymentFrequency, deliveryOn] = useWatch({ control, name: ['paymentFrequency', 'deliveryOn'] })
  const maxActivationDate = useMemo(() => new Date(creditApplication.expiresOn), [creditApplication.expiresOn])

  const [minFirstPaymentDate, maxFirstPaymentDate] = useMemo(() => {
    return getProductsMinMaxPaymentDates(deliveryOn, paymentFrequency)
  }, [deliveryOn, paymentFrequency])
  const possibleTerms = useProductsTerms(maxTermDuration)

  const [isVariableInterest, setIsVariableInterest] = useState(false)
  const loanTermChoicesList =
    possibleTerms.map((item) => ({
      label: `${item} ${t('worksheet.monthLowerCase')}`,
      value: item,
    })) || []
  const paymentPlanId = watch('paymentPlanId') as EPaymentPlan
  const selectedPlan = useMemo(
    () => merchantPayments?.find((p) => (p.value as EPaymentPlan) === paymentPlanId),
    [merchantPayments, paymentPlanId],
  )

  useEffect(() => {
    const isVariable = Boolean(paymentPlanId && paymentPlanId !== EPaymentPlan.regularDailyInterests)

    if (isVariable && selectedPlan) {
      setValue('term', Number(selectedPlan.value))
    }

    setIsVariableInterest(isVariable)
  }, [paymentPlanId, merchantPayments, selectedPlan, selectedPlan?.value, setValue])

  return (
    <Grid container spacing={2} mt={0.5} mb={2}>
      <Grid item xs={6} md={4}>
        <InputTextField
          label={t('worksheetCommon.amountRequested')}
          fullWidth
          disabled={disabledAmountInput}
          {...register('amountRequested')}
          error={errors.amountRequested}
          defaultValue={worksheet?.amountRequested}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <DatePicker
          name="deliveryOn"
          control={control}
          error={errors?.deliveryOn}
          label={t('worksheetCommon.activationDate')}
          disablePast
          minDate={new Date()}
          maxDate={maxActivationDate}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SelectComponent
          disabled={isVariableInterest}
          items={loanTermChoicesList}
          label={t('worksheet.term') as string}
          {...register('term')}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SelectComponent
          items={Object.values(EPaymentFrequency).map((item) => {
            return { label: `worksheet.${item}`, value: item }
          })}
          label={t('worksheet.paymentFrequency') as string}
          {...register('paymentFrequency')}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <DatePicker
          name="firstPaymentOn"
          control={control}
          error={errors?.firstPaymentOn}
          label={t('worksheet.firstPaymentOn')}
          disablePast
          minDate={minFirstPaymentDate}
          maxDate={maxFirstPaymentDate}
          disabled={!paymentFrequency}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SelectComponent
          items={merchantPayments?.map((item) => ({
            label: item.label.includes(EPaymentPlan.regularDailyInterests)
              ? t(`enum.ePaymentPlan.${item.label as EPaymentPlan}`)
              : item.label,
            value: item.value,
          }))}
          label={t('worksheet.paymentPlan') as string}
          {...register('paymentPlanId')}
          error={errors?.paymentPlanId}
        />
      </Grid>
    </Grid>
  )
}

export default WorksheetDetails
