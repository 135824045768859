import { Edit as EditIcon, History as HistoryIcon } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Divider, IconButton, Link, Paper, Stack, Typography } from '@mui/material'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { reportErrorToConsole } from '@src/services/error-logger'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { b2cWorksheetEffects } from '../../../data/store/B2cWorksheet'
import {
  CreditApplication,
  EDecision,
  EPaymentFrequency,
  EPaymentPlan,
  ETermMax,
  EWorksheetStatus,
  User,
} from '../../../data/types'
import { B2cWorksheet } from '../../../data/types/B2cWorksheetSchema'
import { FormatCurrency, formatDate, formatDateTime } from '../../../services/Formatter'
import { computeTotalInsurance } from '../../../services/WorksheetComputer'
import getIFinanceMaxPaymentDateGivenActivationDate from '../viewCreditApplicationPage-selectors'

interface Props {
  creditApplication: CreditApplication
  worksheet: B2cWorksheet
  user: User | null
  editDisabled: boolean
  onWorksheetHistoryClicked: () => void
}

const B2cWorksheetInformationSection = ({
  creditApplication,
  worksheet,
  user,
  editDisabled,
  onWorksheetHistoryClicked,
}: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const dispatchEffect = useSideEffect()

  const viewWorksheetDisabled = worksheet === null
  const editWorksheetDisabled =
    creditApplication.finalCreditDecision.decision !== EDecision.accept ||
    !user?.rights.canEditWorksheet ||
    editDisabled

  // event handlers
  const createWorksheet = useCallback(() => {
    const route = `/Applications/b2c/${creditApplication.id}/worksheet/edit`

    if (worksheet === null) {
      const paymentAmount =
        (creditApplication.finalCreditDecision.maxAmountFinanced ?? 0) >= (creditApplication.requestedLoanAmount ?? 0)
          ? creditApplication.requestedLoanAmount
          : creditApplication.finalCreditDecision.maxAmountFinanced
      const newWorksheet = {
        amountRequested: paymentAmount,
        deliveryOn: formatDate(new Date()),
        firstPaymentOn: getIFinanceMaxPaymentDateGivenActivationDate(new Date()),
        paymentFrequency: EPaymentFrequency.Monthly,
        term: ETermMax.twelve,
        includeInsurance: false,
        addFastPayments: false,
        versionTag: undefined,
        fundConfirmationNote: null,
        paymentPlanId: EPaymentPlan.regularDailyInterests,
        creditApplicationId: creditApplication.id,
        status: EWorksheetStatus.Draft,
      }
      dispatchEffect(b2cWorksheetEffects.create(newWorksheet))
        .then(() => navigate(route))
        .catch(reportErrorToConsole)
    } else {
      navigate(route)
    }
  }, [worksheet, creditApplication, dispatchEffect, navigate])

  return (
    <Paper elevation={3} sx={{ borderRadius: 2, p: 2, mb: 3 }}>
      <Box sx={{ m: 1 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={2}>
            <ButtonGroup variant="outlined" size="large">
              <Button
                id="btnEditWorksheet"
                component={Link}
                href={`/Applications/b2c/${creditApplication.id}/worksheet`}
                variant="outlined"
                disabled={viewWorksheetDisabled}
              >
                {t('worksheet.financing')}
              </Button>
              <Button onClick={createWorksheet} disabled={editWorksheetDisabled} size="small">
                <EditIcon fontSize="large" />
              </Button>
            </ButtonGroup>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            alignContent="center"
            divider={<Divider orientation="vertical" flexItem sx={{ height: 25, alignSelf: 'center' }} />}
          >
            {worksheet?.updatedOn && (
              <Typography variant="body1" component="div" gutterBottom>
                {t('common.LastModification')} : {worksheet && formatDateTime(worksheet?.updatedOn)}
              </Typography>
            )}
            <IconButton
              onClick={onWorksheetHistoryClicked}
              disabled={worksheet === null}
              sx={{
                color: worksheet === null ? '' : 'primary.main',
                fontSize: 'large',
              }}
            >
              <HistoryIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Divider />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          alignItems="top"
          marginTop={1}
          justifyContent="space-evenly"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <span>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.decision')} :{' '}
              {worksheet && t(`enum.eWorkSheetDecision.${worksheet?.finalDecision?.decision}`)}
            </Typography>
          </span>
          <span>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.paymentFrequency')} : {worksheet && t(`worksheet.${worksheet?.paymentFrequency}`)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.term')} : {worksheet?.term} {worksheet?.term !== undefined && t('worksheet.month')}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.paymentAmount')} : {FormatCurrency(worksheet?.paymentAmountForFrequency)}
            </Typography>
          </span>
          <span>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.price')} : {FormatCurrency(worksheet?.amountRequested)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.insurance')} : {FormatCurrency(computeTotalInsurance(worksheet))}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.totalAmountFinanced')} : {FormatCurrency(worksheet?.totalAmountFinanced)}
            </Typography>
          </span>
        </Stack>
      </Box>
    </Paper>
  )
}

export default B2cWorksheetInformationSection
