import { Cancel as CancelIcon } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EPaymentPlan, EWorksheetStatus } from '@src/data/types'
import { computeTotalInsurance, computeTotalObligation } from '@src/services/WorksheetComputer'
import { reportErrorToConsole } from '@src/services/error-logger'
import { creditSelectors } from '@src/data/store/CreditApplication'
import { PageSpinner } from '@src/components'
import { useAppSelector } from '../../../data/store'
import { IFinanceBaseWorksheetRevision } from '../../../data/types/IFinanceBaseWorksheetSchema'
import { MerchantPaymentPlan } from '../../../data/types/MerchantPaymentPlan'
import { FormatCurrency, formatDate, formatTime } from '../../../services/Formatter'
import FormattedMerchantPaymentPlan from './FormattedMerchantPaymentPlan'

interface RowProps {
  newWorksheetRevision: IFinanceBaseWorksheetRevision
  oldWorksheetRevision: IFinanceBaseWorksheetRevision
  merchantPaymentPlans: MerchantPaymentPlan[] | undefined
}

const IFinanceWorksheetHistoryRow = ({
  newWorksheetRevision,
  oldWorksheetRevision,
  merchantPaymentPlans,
}: RowProps) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const GetColorAfterComparingValues = (
    newValue: string | undefined | number | null,
    oldValue: string | undefined | number | null,
  ) => {
    if (newValue === oldValue)
      return theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black
    return theme.palette.mode === 'dark' ? theme.palette.error.dark : theme.palette.error.light
  }

  const newInsuranceFee = computeTotalInsurance(newWorksheetRevision)
  const oldInsuranceFee = computeTotalInsurance(oldWorksheetRevision)
  const newTotalObligation = computeTotalObligation(newWorksheetRevision)
  const oldTotalObligation = computeTotalObligation(oldWorksheetRevision)

  const merchantPaymentPlanDisplay = () => {
    if (newWorksheetRevision.paymentPlanId === EPaymentPlan.regularDailyInterests) {
      return t(`enum.ePaymentPlan.${newWorksheetRevision.paymentPlanId}`)
    }

    const merchantPaymentPlan = merchantPaymentPlans?.find((x) => x.id === newWorksheetRevision.paymentPlanId)
    if (merchantPaymentPlan) {
      return <FormattedMerchantPaymentPlan merchantPaymentPlan={merchantPaymentPlan} />
    }

    return ''
  }

  return (
    <TableRow key={newWorksheetRevision.id}>
      <TableCell align="center">{newWorksheetRevision.updatedByUserFullname}</TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(
            newWorksheetRevision.amountRequested,
            oldWorksheetRevision.amountRequested,
          ),
        }}
      >
        {FormatCurrency(newWorksheetRevision.amountRequested)}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(newInsuranceFee, oldInsuranceFee),
        }}
      >
        {FormatCurrency(newInsuranceFee)}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(newTotalObligation, oldTotalObligation),
        }}
      >
        {FormatCurrency(newTotalObligation)}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(newWorksheetRevision.paymentPlanId, oldWorksheetRevision.paymentPlanId),
        }}
      >
        {merchantPaymentPlanDisplay()}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(newWorksheetRevision.term, oldWorksheetRevision.term),
        }}
      >
        {`${newWorksheetRevision.term ?? 0}  ${t('worksheet.month')}`}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(
            newWorksheetRevision.paymentAmountForFrequency,
            oldWorksheetRevision.paymentAmountForFrequency,
          ),
        }}
      >
        {FormatCurrency(newWorksheetRevision.paymentAmountForFrequency)}
      </TableCell>
      <TableCell
        align="center"
        style={{
          color: GetColorAfterComparingValues(newWorksheetRevision.status, oldWorksheetRevision.status),
        }}
      >
        {newWorksheetRevision.status === EWorksheetStatus.Active
          ? newWorksheetRevision.status
          : newWorksheetRevision?.finalDecision?.decision}
      </TableCell>
      <TableCell align="center">
        <>
          {formatDate(newWorksheetRevision.updatedOn)}
          <br />
          {formatTime(newWorksheetRevision.updatedOn)}
        </>
      </TableCell>
    </TableRow>
  )
}

interface TableProps {
  worksheetRevisions: IFinanceBaseWorksheetRevision[]
  merchantPaymentPlans: MerchantPaymentPlan[] | undefined
}

const IFinanceWorksheetHistoryTable = ({ worksheetRevisions, merchantPaymentPlans }: TableProps) => {
  const { t } = useTranslation()
  return (
    <Paper elevation={3} sx={{ borderRadius: 2, p: 2, mt: 3, mb: 3 }}>
      <TableContainer sx={{ mt: 2, maxHeight: 800 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">{t('worksheetHistory.user')}</TableCell>
              <TableCell align="center">{t('worksheetCommon.amountRequested')}</TableCell>
              <TableCell align="center">{t('worksheetCommon.insurance')}</TableCell>
              <TableCell align="center">{t('worksheetCommon.totalObligation')}</TableCell>
              <TableCell align="center">{t('worksheet.paymentPlan')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.term')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.payment')}</TableCell>
              <TableCell align="center">{t('worksheet.decision')}</TableCell>
              <TableCell align="center">{t('worksheetHistory.DateTime')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {worksheetRevisions.length !== 0 &&
              worksheetRevisions.map((worksheetRevision, index, worksheetRevisionsArray) => (
                <IFinanceWorksheetHistoryRow
                  newWorksheetRevision={worksheetRevision}
                  oldWorksheetRevision={
                    worksheetRevisionsArray[index + 1 === worksheetRevisionsArray.length ? index : index + 1]
                  }
                  merchantPaymentPlans={merchantPaymentPlans}
                  key={worksheetRevision.id}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

interface Props {
  onClose: () => void
  open: boolean
  creditApplicationId: string | undefined
  load: () => Promise<unknown>
  worksheetRevisions: IFinanceBaseWorksheetRevision[]
}

const IFinanceWorksheetHistoryDialog = ({ onClose, open, creditApplicationId, load, worksheetRevisions }: Props) => {
  const { t } = useTranslation()

  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (open && creditApplicationId) {
      setLoading(true)
      load()
        .catch(reportErrorToConsole)
        .finally(() => {
          setLoading(false)
        })
    }
  }, [creditApplicationId, open, load])

  const merchant = useAppSelector(creditSelectors.getMerchant)
  return (
    <Dialog
      onClose={onClose}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
      keepMounted
      transitionDuration={0}
      disablePortal
      BackdropProps={{ transitionDuration: 0 }}
    >
      <DialogTitle style={{ borderBottom: '1px solid' }} id="alert-dialog-title">
        {t('worksheetHistory.worksheetHistory')}
      </DialogTitle>
      <DialogContent>
        <PageSpinner isLoading={isLoading} />
        {worksheetRevisions && (
          <IFinanceWorksheetHistoryTable
            worksheetRevisions={worksheetRevisions}
            merchantPaymentPlans={merchant?.paymentPlans}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="warning" startIcon={<CancelIcon />} onClick={onClose}>
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(IFinanceWorksheetHistoryDialog)
