import { Box, Button, Chip, Link, Stack, Typography } from '@mui/material'
import { DataGrid, GridColDef, GridFooter, GridFooterContainer, GridValueFormatterParams } from '@mui/x-data-grid'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { userSelectors } from '@src/data/store/UserStore'
import { reportErrorToConsole } from '@src/services/error-logger'
import { startOfDay, subYears } from 'date-fns'
import { addDays } from 'date-fns/esm'
import { omit } from 'lodash-es'
import { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import FullScreenContainer from '../../components/FullScreenContainer'
import { TRootState, useAppSelector } from '../../data/store'
import { appSelectors } from '../../data/store/AppStore'
import { creditDashboardEntryEffects, creditDashboardEntrySelectors } from '../../data/store/CreditDashboardEntry'
import {
  ECreditApplicationStatus,
  EFinancingCompany,
  EFinancingProgram,
  EPaymentPlan,
  EProgressionStatus,
} from '../../data/types'
import { CreditDashboardEntry, CreditDashboardFilters } from '../../data/types/CreditDashboardEntry'
import { TranslatedEnum } from '../../data/types/TranslatedEnum'
import { formatDate, formatUtcIsoDateTime } from '../../services/Formatter'
import { getNumberFromQueryString, getStringArrayFromQueryString, getStringFromQueryString } from '../../services/url'
import DataGridHeader from './DataGridHeader'
import InlineActions from './InlineActions'

const falsy = () => false

const BrowseCreditApplicationsPage = (): JSX.Element => {
  const { t } = useTranslation()
  document.title = t('breadcrumbs.home')
  const AddDays = (date: Date, days: number) => {
    const d = new Date(date)
    d.setDate(d.getDate() + days)
    return d
  }

  const containsNumbers = (str: string) => {
    return /\d/.test(str)
  }

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()

  const selectedCompany = useAppSelector(userSelectors.selectedCompany)

  const filterValues = useMemo<CreditDashboardFilters>(() => {
    return {
      limit: getNumberFromQueryString(searchParams, 'limit', 50),
      offset: getNumberFromQueryString(searchParams, 'offset', 0),
      financingProgramIds: getStringArrayFromQueryString(searchParams, 'financingProgramIds'),
      territoryIds: getStringArrayFromQueryString(searchParams, 'territoryIds'),
      loanPurposeIds: getStringArrayFromQueryString(searchParams, 'loanPurposeIds'),
      creditApplicationStatus: getStringFromQueryString<ECreditApplicationStatus>(
        searchParams,
        'creditApplicationStatus',
      ),
      contains: getStringFromQueryString(searchParams, 'contains', ''),
      merchantId: getStringFromQueryString(searchParams, 'merchantId', ''),
      dateRange: getStringFromQueryString(searchParams, 'dateRange', ''),
      plan: getStringArrayFromQueryString(searchParams, 'plan'),
      progressionStatus: getStringArrayFromQueryString(searchParams, 'progressionStatus'),
      financingCompanyId: selectedCompany!,
      team: getStringFromQueryString(searchParams, 'team'),
      tag: getStringFromQueryString(searchParams, 'tag'),
    }
  }, [searchParams, selectedCompany])

  const dispatchEffect = useSideEffect()

  const creditApplications = useAppSelector(creditDashboardEntrySelectors.getCreditApplicationList)
  const isLoading = useAppSelector(creditDashboardEntrySelectors.isLoadingCreditApplicationList)
  const planEnum = useAppSelector((state: TRootState) => appSelectors.getPlanEnumForProgram(state))
  const applicantTypeEnum = useAppSelector(appSelectors.getApplicantTypeEnum)
  const documentTypes = useAppSelector(appSelectors.getDocumentTypes)
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum
  const tags = useAppSelector(appSelectors.getMerchantTags)

  useEffect(() => {
    const dates = filterValues.dateRange ? filterValues.dateRange.split('@') : ['', '']
    const endDate = dates[1] || formatUtcIsoDateTime(addDays(new Date(), 1))
    const defaultStartDate = filterValues.dateRange ? null : formatUtcIsoDateTime(subYears(new Date(endDate), 2))
    const containsValueExists = filterValues.contains
    let queryParams = {}
    if (containsValueExists) {
      const isReference = containsValueExists && containsNumbers(filterValues.contains)
      queryParams = {
        referenceNumber: isReference ? filterValues.contains : '',
        clientNameContains: !isReference ? filterValues.contains : '',
        startDate: dates[0] || defaultStartDate,
        endDate,
        financingCompanyId: selectedCompany,
      }
    } else {
      queryParams = {
        ...omit(filterValues, ['dateRange', 'contains']),
        startDate: dates[0] || formatUtcIsoDateTime(startOfDay(AddDays(new Date(), -90))),
        endDate,
        merchantId: filterValues.merchantId,
        creditApplications: filterValues.creditApplicationStatus
          ? filterValues.creditApplicationStatus
          : ECreditApplicationStatus.Active,
      }
    }
    dispatchEffect(creditDashboardEntryEffects.getCreditApplicationListByFilters(queryParams)).catch(
      reportErrorToConsole,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues])

  const handleFilter = (newValue: CreditDashboardFilters) => {
    const updateSearch = new URLSearchParams(location.search)
    updateSearch.set('limit', encodeURIComponent(newValue.limit))
    updateSearch.set('offset', encodeURIComponent(newValue.offset))
    updateSearch.set('financingProgramIds', encodeURIComponent(newValue.financingProgramIds.toString()))
    updateSearch.set('loanPurposeIds', encodeURIComponent(newValue.loanPurposeIds.toString()))
    if (selectedCompany === EFinancingCompany.IFinance) {
      updateSearch.set('team', encodeURIComponent(newValue.team.toString()))
    }
    updateSearch.set('contains', encodeURIComponent(newValue.contains.toString()))
    updateSearch.set('merchantId', encodeURIComponent(newValue.merchantId.toString()))
    if (newValue.tag) updateSearch.set('tag', encodeURIComponent(newValue.tag))
    updateSearch.set('creditApplicationStatus', encodeURIComponent(newValue.creditApplicationStatus.toString()))
    updateSearch.set('dateRange', encodeURIComponent(newValue.dateRange))
    updateSearch.set('territoryIds', encodeURIComponent(newValue.territoryIds.toString()))
    updateSearch.set('plan', encodeURIComponent(newValue.plan.toString()))
    updateSearch.set('progressionStatus', encodeURIComponent(newValue.progressionStatus.toString()))
    updateSearch.set('financingCompanyId', encodeURIComponent(newValue.financingCompanyId.toString()))
    const newUrl = `${location.pathname}?${updateSearch.toString()}`
    navigate(newUrl, { replace: true })
  }

  const getPlanTranslation = useCallback(
    (plan?: string | null) => {
      if (!plan) return ''
      const planEnumValue = planEnum[plan]
      if (!planEnumValue?.[lang]) {
        reportErrorToConsole(`Plan ${plan} not found in planEnum or translation for ${lang} not available`)
        return ''
      }
      return planEnumValue[lang]
    },
    [lang, planEnum],
  )

  useEffect(() => {
    const updateSearch = new URLSearchParams(location.search)
    if (selectedCompany === EFinancingCompany.Iceberg) {
      updateSearch.delete('team')
    }

    const newUrl = `${location.pathname}?${updateSearch.toString()}`
    navigate(newUrl, { replace: true })
  }, [location.pathname, location.search, navigate, selectedCompany])

  const columns = useMemo<GridColDef<CreditDashboardEntry>[]>(
    () => [
      {
        field: 'financingProgramId',
        headerName: t('browseCreditApplications.dataGridColumnHeaders.type'),
        minWidth: 80,
        sortable: false,
        hide: true,
        renderCell: (params) => {
          switch (params.row.financingProgramId) {
            case EFinancingProgram.Personal:
            case EFinancingProgram.Products:
            case EFinancingProgram.IfXpress:
            case EFinancingProgram.Auto:
              return t(`enum.eFinancingProgram.${params.row.financingProgramId}`)
            default:
              return ''
          }
        },
      },
      {
        field: 'referenceNumber',
        headerName: t('browseCreditApplications.dataGridColumnHeaders.applicationNumber'),
        minWidth: 130,
        sortable: false,
        renderCell: (params) => {
          if (params.row.merchantName.startsWith('createdFor'))
            return <Typography>{params.row.creditApplicationReferenceNumber}</Typography>
          return (
            <Link href={`/Applications/${params.row.financingProgramId}/${params.row.id}/view`}>
              {params.row.creditApplicationReferenceNumber}
            </Link>
          )
        },
      },
      {
        field: 'merchantName',
        headerName: t('browseCreditApplications.dataGridColumnHeaders.merchant'),
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          if (params.row.merchantName.startsWith('createdFor'))
            return (
              <Typography>
                <Trans
                  i18nKey="browseCreditApplications.createdFor"
                  values={{ financingCompany: `${params.row.merchantName.replace('createdFor', '')}` }}
                />
              </Typography>
            )
          return <Typography>{params.row.merchantName}</Typography>
        },
      },
      {
        field: 'clientName',
        headerName: t('browseCreditApplications.dataGridColumnHeaders.client'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'progressionStatus',
        headerName: t('common.status'),
        minwidth: 80,
        sortable: false,
        renderCell: (params) => {
          if (!params.row.progressionStatus) return null
          let statusColor = '#d13126'
          const statuscode = t(`enum.eProgressionStatus.${params.row.progressionStatus}`)
          const abbr = statuscode.substring(statuscode.length - 3, statuscode.length - 1)
          if (params.row.progressionStatus === EProgressionStatus.Approved) {
            statusColor = '#25c455'
          }
          if (
            params.row.progressionStatus === EProgressionStatus.ConditionalApproval ||
            params.row.progressionStatus === EProgressionStatus.CreditPending
          ) {
            statusColor = '#fcba03'
          }
          if (
            params.row.progressionStatus === EProgressionStatus.DocumentIncomplete ||
            params.row.progressionStatus === EProgressionStatus.DocumentReceived ||
            params.row.progressionStatus === EProgressionStatus.PaymentPending
          ) {
            statusColor = '#951dc4'
          }
          if (params.row.progressionStatus === EProgressionStatus.Booked) {
            statusColor = '#0ea8eb'
          }
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  backgroundColor: statusColor,
                  height: '12px',
                  width: '12px',
                  borderRadius: '12px',
                  marginRight: 5,
                }}
              />
              <div title={statuscode} style={{ marginLeft: 3 }}>
                {abbr}
              </div>
            </div>
          )
        },
      },
      {
        field: 'interestRate',
        headerName: t('browseCreditApplications.dataGridColumnHeaders.interestRate'),
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        renderCell: (params) => {
          if (!params.row.interestRate) return null
          if (params.row.paymentPlanId !== EPaymentPlan.regularDailyInterests) {
            return <Typography>Promo</Typography>
          }
          return <Typography>{params.row.interestRate}</Typography>
        },
      },
      {
        field: 'plan',
        headerName: t('credit.plan'),
        minWidth: 100,
        sortable: false,
        renderCell: (params) => {
          return <Typography>{getPlanTranslation(params.row?.plan)}</Typography>
        },
      },
      {
        field: 'totalAmountFinanced',
        headerName: t('browseCreditApplications.dataGridColumnHeaders.amount'),
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        renderCell: (params) => {
          if (!params.row.totalAmountFinanced) return null
          return <Typography>{params.row.totalAmountFinanced}</Typography>
        },
      },
      {
        field: 'tags',
        headerAlign: 'left',
        headerName: t('browseCreditApplications.dataGridColumnHeaders.tags'),
        align: 'left',
        sortable: false,
        renderCell: (params) => {
          if (!params.row.tags) return null
          return (
            <Stack direction="row" gap={0.5}>
              {params.row.tags.map((tag) => (
                <Chip size="small" key={tag} label={tags.find((item) => item.id === tag)?.text} />
              ))}
            </Stack>
          )
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 180,
        sortable: false,
        renderCell: (params) => {
          return (
            <InlineActions
              id={params.row?.id}
              worksheetDecision={params.row?.worksheetDecision}
              hasDocumentsPendingReview={params.row?.hasDocumentsPendingReview}
              incomeConfirmed={params.row?.incomeConfirmed}
              missingRequiredDocuments={params.row?.missingRequiredDocuments}
              reservedByUserId={params.row?.reservedByUserId}
              reservedByUserName={params.row?.reservedByUserName}
              reservedUntil={params.row?.reservedUntil}
              missingDocumentsNames={documentTypes}
              applicantTypes={applicantTypeEnum}
              lang={lang}
            />
          )
        },
      },
      {
        field: 'createdOn',
        headerName: t('browseCreditApplications.dataGridHeader.createdOn'),
        sortable: false,
        minWidth: 130,
        renderCell: (params) => {
          if (params.row.createdOn.toString() === '0001-01-01T00:00:00') return <Typography />
          return <Typography>{formatDate(params.row.createdOn)}</Typography>
        },
        valueFormatter: (params: GridValueFormatterParams<Date>) => formatDate(params.value),
      },
    ],
    [t, getPlanTranslation, tags, documentTypes, applicantTypeEnum, lang],
  )

  const handleOnPageChange = (state: number) => {
    const updateSearch = new URLSearchParams(location.search)
    if (state === 0) {
      updateSearch.set('offset', encodeURIComponent(filterValues.offset - filterValues.limit))
    } else {
      updateSearch.set('offset', encodeURIComponent(filterValues.offset + filterValues.limit))
    }
    const newUrl = `${location.pathname}?${updateSearch.toString()}`
    navigate(newUrl, { replace: true })
  }

  const customFooter = () => {
    return (
      <GridFooterContainer>
        <GridFooter sx={{ '& .MuiTablePagination-actions': { display: 'none' } }} />
        <div style={{ display: 'flex' }}>
          <Button onClick={() => handleOnPageChange(0)} disabled={filterValues.offset === 0}>
            {' '}
            {t('common.previous')}
          </Button>
          <Button onClick={() => handleOnPageChange(1)} disabled={creditApplications.length < filterValues.limit}>
            {' '}
            {t('common.next')}
          </Button>
        </div>
      </GridFooterContainer>
    )
  }

  return (
    <FullScreenContainer>
      <DataGridHeader
        filterValues={filterValues}
        resetFilter={() => navigate(window.location.pathname, { replace: true })}
        applyFilter={handleFilter}
        addDays={AddDays}
      />
      <Box sx={{ textAlign: 'center' }}>
        <div style={{ height: '45rem' }}>
          <DataGrid
            disableColumnFilter
            isRowSelectable={falsy}
            rows={creditApplications}
            columns={columns}
            pageSize={filterValues.limit}
            onPageSizeChange={(size: number) => handleFilter({ ...filterValues, limit: size })}
            loading={isLoading}
            rowCount={creditApplications.length}
            components={{ Footer: customFooter }}
            componentsProps={{
              pagination: {
                labelRowsPerPage: t('common.customDisplayedRowsTextFooter.RowsPerPage'),
                labelDisplayedRows: () =>
                  t('common.customDisplayedRowsTextFooter.displayRange', {
                    defaultValue: '',
                    from: filterValues.offset + 1,
                    to: filterValues.offset + filterValues.limit,
                  }),
              },
            }}
          />
        </div>
      </Box>
    </FullScreenContainer>
  )
}

export default BrowseCreditApplicationsPage
