import {
  AccessTimeFilled as AccessTimeFilledIcon,
  CheckCircle as CheckCircleIcon,
  RemoveCircle as RemoveCircleIcon,
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import IconButtonWithTooltip from '../../components/IconButtonWithTooltip'
import { TRootState, useAppSelector } from '../../data/store'
import { appSelectors } from '../../data/store/AppStore'
import { CreditApplicationTask } from '../../data/types'
import { EFinancingProgram, ETasksStatus } from '../../data/types/constants'

interface Props {
  task: CreditApplicationTask
  onClickStatusButton: (status: ETasksStatus, task: CreditApplicationTask) => void
  userCanEditTask: boolean
  editDisabled: boolean
}

const StatusTasks = ({ task, onClickStatusButton, userCanEditTask, editDisabled }: Props): JSX.Element | null => {
  const { t } = useTranslation()
  const { financingProgramId } = useParams<{ financingProgramId: EFinancingProgram }>()
  const taskTypes = useAppSelector((state: TRootState) => appSelectors.getTaskTypes(state, financingProgramId))

  return (
    <>
      <IconButtonWithTooltip
        tooltip={t('tasks.toDo')}
        icon={<AccessTimeFilledIcon color={task.status === ETasksStatus.ToDo ? 'primary' : 'disabled'} />}
        onClick={() => onClickStatusButton(ETasksStatus.ToDo, task)}
        disabled={!(userCanEditTask && !editDisabled)}
      />

      <IconButtonWithTooltip
        tooltip={t('tasks.completed')}
        icon={<CheckCircleIcon color={task.status === ETasksStatus.Completed ? 'success' : 'disabled'} />}
        onClick={() => onClickStatusButton(ETasksStatus.Completed, task)}
        disabled={taskTypes[task.typeId].statusIsManagedByTheSystem === true || !(userCanEditTask && !editDisabled)}
      />

      <IconButtonWithTooltip
        tooltip={t('tasks.cancelled')}
        icon={<RemoveCircleIcon color={task.status === ETasksStatus.Cancelled ? 'error' : 'disabled'} />}
        onClick={() => onClickStatusButton(ETasksStatus.Cancelled, task)}
        disabled={!(userCanEditTask && !editDisabled)}
      />
    </>
  )
}

export default StatusTasks
