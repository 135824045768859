import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { ProductsComputeResultDto } from '@src/data/types/ProductsComputeResultDto'
import { ProductsWorksheetDto } from '@src/data/types/ProductsWorksheetSchema'
import { FormatCurrency, formatDate } from '@src/services/Formatter'
import { UseFormGetValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
  getValues: UseFormGetValues<ProductsWorksheetDto>
  computedInfo: ProductsComputeResultDto
  maxPmtAmount: number
  isFetching?: boolean
}

const ProductsLoanFundingSummary = ({ getValues, computedInfo, maxPmtAmount, isFetching = false }: Props) => {
  const { t } = useTranslation()
  const totalObligation = getValues('amountRequested') + computedInfo.totalInterestAmount
  const paymentFrequency = getValues('paymentFrequency')
  return (
    <Box
      sx={{
        border: 1,
        padding: 2,
        height: 'fit-content',
        maxWidth: { md: '25rem' },
        minWidth: '25rem',
      }}
    >
      <Stack direction="column" spacing={2}>
        {isFetching && (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        )}
        {computedInfo && (
          <>
            <Typography variant="subtitle1" fontWeight="bold">
              {t('worksheetCommon.fundingSummary')} :
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography>{t('worksheetCommon.loanAmount')} :</Typography>
              <Typography>{FormatCurrency(getValues('amountRequested'))}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography>{`${t('worksheetCommon.interest')} (${computedInfo.interestRate ?? 0}%)`}: </Typography>
              <Typography>{FormatCurrency(computedInfo.totalInterestAmount)}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography>{t('worksheetCommon.totalObligation')} :</Typography>
              <Typography>{FormatCurrency(totalObligation)}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography>{t('worksheetCommon.annualPercentageRate')} :</Typography>
              <Typography>{computedInfo.effectiveRate ?? 0} %</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" paddingTop={3}>
              <Typography variant="subtitle1" fontWeight="bold">
                {t('worksheetCommon.payment')}
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Typography>{t('worksheetCommon.maxPmtAmount')} :</Typography>
              <Typography>{FormatCurrency(maxPmtAmount)}</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Typography>{t('worksheetCommon.paymentFrequency')} :</Typography>
              <Typography>{paymentFrequency ? t(`worksheet.${paymentFrequency}`) : t('worksheet.monthly')}</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Typography>{t('worksheetCommon.frequencyAmount')} :</Typography>
              <Typography>{FormatCurrency(computedInfo.paymentAmountForFrequency)}</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Typography>{t('worksheetCommon.firstPaymentDate')} :</Typography>
              <Typography>{formatDate(getValues('firstPaymentOn'))}</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" paddingTop={3}>
              <Typography fontWeight="bold">{t('worksheetCommon.amountToBePaid')} :</Typography>
              <Typography>{FormatCurrency(getValues('amountRequested'))}</Typography>
            </Stack>
          </>
        )}
      </Stack>
    </Box>
  )
}

export default ProductsLoanFundingSummary
