import { t } from 'i18next'
/**
 * @deprecated wrong design pattern
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const translate = (key: any, params: Record<string, string | number> = {}): string => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unnecessary-type-assertion
  return t(key, { replace: params }) as string
}

export default translate
