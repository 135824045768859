import { Button, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { documentEffects } from '@src/data/store/Document'
import { creditEffects } from '../../../data/store/CreditApplication'
import { CreditApplication } from '../../../data/types'
import ProofOfReleaseDialog from './proofOfReleaseDialog'
import ProofOfReleaseSummary from './proofOfReleaseSummary'
import { CreditReport } from '../../../data/types/CreditReportSchema'

interface Props {
  creditApplication: CreditApplication
  applicantCreditReport: CreditReport | null
  coApplicantCreditReport: CreditReport | null
}

const ProofOfReleaseSection = ({ creditApplication, applicantCreditReport, coApplicantCreditReport }: Props) => {
  const [openProofOfReleaseDialog, setProofOfReleaseDialog] = React.useState<boolean>(false)
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatchEffect = useSideEffect()

  function proofOfReleaseRequired(data: Record<string, boolean>): string | undefined {
    const keys = Object.keys(data)
    if (keys.some((key) => data[key] === null)) {
      return 'proofMustBeSelected'
    }
    if (keys.some((key) => data[key] === true)) {
      return 'proofRequired'
    }
    return 'proofNotRequired'
  }

  const proofIsRequired = creditApplication.proofsOfRelease
    ? proofOfReleaseRequired(creditApplication.proofsOfRelease.releaseRequiredByLoanId)
    : undefined

  const keys = creditApplication.proofsOfRelease
    ? Object.keys(creditApplication.proofsOfRelease?.releaseRequiredByLoanId)
    : new Array<string>()
  const requiredProofOfRelease = keys.filter(
    (key) => creditApplication.proofsOfRelease.releaseRequiredByLoanId[key] === true,
  )

  const onCancelProofOfRelease = () => {
    setProofOfReleaseDialog(false)
  }

  const onConfirmProofOfRelease = (data: Record<string, boolean>) => {
    const proofOfRelease = {
      ...creditApplication.proofsOfRelease,
      financingProgramId: creditApplication.financingProgramId,
      creditApplicationId: creditApplication.id,
      releaseRequiredByLoanId: data,
      versionTag: creditApplication.versionTag!,
    }
    setProofOfReleaseDialog(false)
    return dispatchEffect(creditEffects.updateProofOfRelease(proofOfRelease)).then(() => {
      return dispatchEffect(documentEffects.getRequiredDocumentList(creditApplication.id))
    })
  }

  return (
    <>
      <Typography variant="h6" component="h3" gutterBottom>
        {t('proofsOfRelease.tittle')}
      </Typography>

      {proofIsRequired === 'proofMustBeSelected' && (
        <Typography
          variant="subtitle1"
          sx={{ backgroundColor: theme.palette.action.selected, marginBottom: 3 }}
          component="p"
        >
          {t('proofsOfRelease.proofOfReleaseMustBeSelected')}
        </Typography>
      )}

      {proofIsRequired === 'proofNotRequired' && (
        <Typography
          variant="subtitle1"
          sx={{ backgroundColor: theme.palette.action.selected, marginBottom: 3 }}
          component="p"
        >
          {t('proofsOfRelease.noProofOfReleaseRequired')}
        </Typography>
      )}

      {proofIsRequired === 'proofRequired' &&
        requiredProofOfRelease.map((accountNumber) => {
          return (
            <ProofOfReleaseSummary
              applicantCreditReport={applicantCreditReport}
              accountNumber={accountNumber}
              key={accountNumber}
            />
          )
        })}

      {Object.keys(creditApplication.proofsOfRelease.releaseRequiredByLoanId).length > 0 && (
        <Button
          variant="contained"
          size="large"
          sx={{
            mt: 2,
          }}
          onClick={() => setProofOfReleaseDialog(true)}
        >
          {t('proofsOfRelease.tittle')}
        </Button>
      )}

      <ProofOfReleaseDialog
        open={openProofOfReleaseDialog}
        applicantCreditReport={applicantCreditReport}
        coApplicantCreditReport={coApplicantCreditReport}
        onCancel={onCancelProofOfRelease}
        onConfirm={onConfirmProofOfRelease}
        title={`${t('proofsOfRelease.tittle')}`}
        releaseRequiredByLoanId={creditApplication.proofsOfRelease.releaseRequiredByLoanId}
      />
    </>
  )
}

export default ProofOfReleaseSection
