import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { CreditReportExtTrade, CreditReportTrade, EFolioCode, EPaymentRateCode } from '@src/data/types'
import { FormatCurrencyNoDecimals, formatDate } from '@src/services/Formatter'
import Row from './tableRow'

interface FundingProps {
  trades: CreditReportTrade[]
  extTrades: CreditReportExtTrade[]
}

const Funding = ({ trades, extTrades }: FundingProps) => {
  const { t } = useTranslation()

  const getFolioCodeDescription = (folioCode: EFolioCode) => t(`creditReport.folioCode.${folioCode}`)

  const getPaymentRateCodeDescription = (paymentRateCode: EPaymentRateCode) =>
    t(`creditReport.pmtRateCode.${paymentRateCode}`)

  const style = {
    maxWidth: '160px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderStyle: 'border-box',
  }

  const styleNoBorder = {
    maxWidth: '160px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderStyle: 'border-box',
    borderBottom: 'none',
  }

  const styleTableFirstElement = { borderBottom: 'none', width: '10%', paddingTop: '1px', paddingBottom: '10px' }

  const styleTableSecondElement = { borderBottom: 'none', width: '25%', paddingTop: '1px', paddingBottom: '10px' }

  return (
    <Row id="funding" name={(t('creditReport.paymentReports') as string).concat(' / ', t('creditReport.funding'))}>
      <TableContainer>
        <Table aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={style}>
                {t('creditReport.creditor')}
              </TableCell>
              <TableCell align="left" style={style}>
                {t('creditReport.registrationDate')}
              </TableCell>
              <TableCell align="left" style={style}>
                {t('creditReport.accountNumber')}
              </TableCell>
              <TableCell align="left" style={style}>
                {t('creditReport.association')}
              </TableCell>
              <TableCell align="left" style={style}>
                {t('creditReport.highCreditAmount')}
              </TableCell>
              <TableCell align="left" style={style}>
                {t('creditReport.paymentTermAmount')}
              </TableCell>
              <TableCell align="left" style={style}>
                {t('creditReport.balanceAmount')}
              </TableCell>
              <TableCell align="left" style={style}>
                {t('creditReport.pastDueAmount')}
              </TableCell>
              <TableCell align="left" style={style}>
                {t('creditReport.monthsReviewed')}
              </TableCell>
              <TableCell align="left" style={style}>
                {t('creditReport.openingDate')}
              </TableCell>
              <TableCell align="left" style={style}>
                {t('creditReport.lastActivityOrPayment')}
              </TableCell>
              <TableCell align="left" style={style}>
                {t('creditReport.portfolioType')}
              </TableCell>
              <TableCell align="left" colSpan={3}>
                {t('creditReport.state')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {extTrades.map((item) => (
              <React.Fragment key={item.accountNumber}>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" align="left" style={styleNoBorder}>
                    {item.creditorName} {item.creditorPhone && `(${item.creditorPhone})`}
                  </TableCell>
                  <TableCell align="left" style={styleNoBorder}>
                    {formatDate(item.reportedDate)}
                  </TableCell>
                  <TableCell align="left" style={styleNoBorder}>
                    {item.accountNumber}
                  </TableCell>
                  <TableCell align="left" style={styleNoBorder}>
                    {item.associationCode
                      ? t(`creditReport.associationCode.${item.associationCode}`)
                      : t('creditReport.associationCode.U')}
                  </TableCell>
                  <TableCell align="left" style={styleNoBorder}>
                    {FormatCurrencyNoDecimals(item.highCreditAmount)}
                  </TableCell>
                  <TableCell align="left" style={styleNoBorder}>
                    {FormatCurrencyNoDecimals(item.paymentTermAmount)}
                  </TableCell>
                  <TableCell align="left" style={styleNoBorder}>
                    {FormatCurrencyNoDecimals(item.balanceAmount)}
                  </TableCell>
                  <TableCell align="left" style={styleNoBorder}>
                    {FormatCurrencyNoDecimals(item.pastDueAmount)}
                  </TableCell>
                  <TableCell align="left" style={styleNoBorder}>
                    {item.timesReported}
                  </TableCell>
                  <TableCell align="left" style={styleNoBorder}>
                    {formatDate(item.openedDate)}
                  </TableCell>
                  <TableCell align="left" style={styleNoBorder}>
                    {formatDate(item.lastActivityDate)}
                  </TableCell>
                  <TableCell align="left" style={styleNoBorder}>
                    {getFolioCodeDescription(item.folioCode)}
                    <br />
                    <strong>{item.isStudentTrade && t('creditReport.isStudentTrade')}</strong>
                  </TableCell>
                  <TableCell align="left" style={styleNoBorder}>
                    {getPaymentRateCodeDescription(item.pmtRateCode)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={14}>
                    <TableContainer style={{ width: '30%' }}>
                      <Table
                        style={{
                          whiteSpace: 'nowrap',
                          border: 'solid',
                          borderWidth: '1px',
                          borderColor: 'rgba(224, 224, 224, 1)',
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={5} align="left" style={{ borderBottom: 'none' }}>
                              <strong>
                                {item.paymentFrequencyCode &&
                                  `${t(`creditReport.paymentFrequencyCode.${item.paymentFrequencyCode}`)}`}
                              </strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left" style={styleTableFirstElement}>
                              Rating
                            </TableCell>
                            <TableCell align="left" style={styleTableSecondElement}>
                              {item.folioCode}
                              {item.pmtRateCode}
                              <span style={{ marginRight: '20px' }} />
                              {item.rating}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" style={styleTableFirstElement}>
                              Pattern
                            </TableCell>
                            <TableCell align="left" style={styleTableSecondElement}>
                              {item.patterns}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
            <TableRow>
              <TableCell align="left" colSpan={14}>
                <Typography variant="subtitle2" gutterBottom component="div">
                  {t('creditReport.funding')}
                </Typography>
              </TableCell>
            </TableRow>
            {trades.map((item) => (
              <TableRow key={item.accountNumber} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" align="left">
                  {item.creditorName} {item.creditorPhone && `(${item.creditorPhone})`}
                  <br />
                  <strong>
                    {item.paymentFrequencyCode &&
                      `${t(`creditReport.paymentFrequencyCode.${item.paymentFrequencyCode}`)}`}
                  </strong>
                </TableCell>
                <TableCell align="left" style={style}>
                  {formatDate(item.reportedDate)}
                </TableCell>
                <TableCell align="left" style={style}>
                  {item.accountNumber}
                </TableCell>
                <TableCell align="left" style={style}>
                  {item.associationCode
                    ? t(`creditReport.associationCode.${item.associationCode}`)
                    : t('creditReport.associationCode.U')}
                </TableCell>
                <TableCell align="left" style={style}>
                  {FormatCurrencyNoDecimals(item.highCreditAmount)}
                </TableCell>
                <TableCell align="left" style={style}>
                  {FormatCurrencyNoDecimals(item.paymentTermAmount)}
                </TableCell>
                <TableCell align="left" style={style}>
                  {FormatCurrencyNoDecimals(item.balanceAmount)}
                </TableCell>
                <TableCell align="left" style={style}>
                  {FormatCurrencyNoDecimals(item.pastDueAmount)}
                </TableCell>
                <TableCell align="left" style={style}>
                  {item.timesReported}
                </TableCell>
                <TableCell align="left" style={style}>
                  {formatDate(item.openedDate)}
                </TableCell>
                <TableCell align="left" style={style}>
                  {formatDate(item.lastActivityDate)}
                </TableCell>
                <TableCell align="left" style={style}>
                  {getFolioCodeDescription(item.folioCode)}
                  <br />
                  <strong>{item.isStudentTrade && t('creditReport.isStudentTrade')}</strong>
                </TableCell>
                <TableCell align="left">{getPaymentRateCodeDescription(item.pmtRateCode)}</TableCell>
                <TableCell component="th" scope="row" align="center" colSpan={2}>
                  Rating <br />
                  {item.folioCode}
                  {item.pmtRateCode}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Row>
  )
}

export default Funding
