import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { EFinancingProgram } from '@src/data/types'
import { ActionsDialog } from '../../../components'
import { TRootState, useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import { DocumentRequired } from '../../../data/types/DocumentSchema'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'

interface Props {
  open: boolean
  data: DocumentRequired | null | undefined
  onConfirm: () => void
  onCancel: () => void
}

const DeleteDocumentRequestDialog = ({ open, data, onConfirm, onCancel }: Props) => {
  const { t } = useTranslation()
  const { financingProgramId } = useParams<{ financingProgramId: EFinancingProgram }>()
  const documentTypes = useAppSelector((state: TRootState) => appSelectors.getDocumentTypes(state, financingProgramId))
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum

  return (
    <ActionsDialog
      title={
        <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={3}>
          <Typography>{t('document.deleteDocumentRequest')}</Typography>
        </Stack>
      }
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <Typography>{t('document.deleteDocumentRequestConfirmation')}</Typography>
      {data?.typeId !== undefined && (
        <>
          {documentTypes[data.typeId][lang]}
          {data.labelSuffix && ` - ${data.labelSuffix}`}
          {data.applicantType && ` - ${t(`common.${data.applicantType}`)}`}
        </>
      )}
    </ActionsDialog>
  )
}

export default React.memo(DeleteDocumentRequestDialog)
