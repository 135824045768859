import React from 'react'
import { useTranslation } from 'react-i18next'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Grid, useTheme } from '@mui/material'
import { PreviousApplication } from '../../../data/types/PreviousApplicationSchema'
import PreviousApplicationList from './PreviousApplicationList'

interface Props {
  applicantPreviousApplications: PreviousApplication[]
  coApplicantPreviousApplications: PreviousApplication[]
  hasCoapplicant: boolean
}

const OtherApplicationList = ({
  applicantPreviousApplications,
  coApplicantPreviousApplications,
  hasCoapplicant,
}: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const borderColor = theme.palette.mode === 'dark' ? 'warning.main' : 'warning.light'
  return (
    <MuiAccordion sx={{ mb: 2 }}>
      <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ borderColor, borderWidth: 1, borderStyle: 'solid' }}
      >
        <Typography sx={{ width: '100%', textAlign: 'center' }}>{t('common.otherApplication.label')}</Typography>
      </MuiAccordionSummary>
      <AccordionDetails sx={{ borderColor, borderWidth: 1, borderStyle: 'solid' }}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={12} lg={hasCoapplicant ? 6 : 12}>
            <PreviousApplicationList
              previousApplication={applicantPreviousApplications}
              label={`${t('common.applicant')}`}
            />
          </Grid>
          {hasCoapplicant && (
            <Grid item xs={12} md={12} lg={6}>
              <PreviousApplicationList
                previousApplication={coApplicantPreviousApplications}
                label={`${t('common.coApplicant')}`}
              />
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </MuiAccordion>
  )
}

export default React.memo(OtherApplicationList)
