import {
  ApplicableTax,
  BaseWorksheet,
  ComputedTax,
  baseWorksheetFundingThresholdExtendSchema,
  baseWorksheetSchema,
} from './BaseWorksheetSchema'
import { ParameterizedMessage } from './ParameterizedMessage'
import yupExtInt from './common/SchemaTypes'
import * as yup from './common/yup-extended'
import {
  ECarCondition,
  ECarConditionList,
  EExtendedServiceDuration,
  EExtendedServiceDurationList,
  EPlanType,
  EWorksheetDecision,
} from './constants'

const additionsSchema = {
  code: yup.default.string().default(''),
  description: yup.default.string().default(''),
}

export const AdditionsSchema = yup.default.object(additionsSchema)
export type Additions = yup.default.InferType<typeof AdditionsSchema>
const PriceRangeSchema = yup.default.object().shape({
  xclean: yup.default.number().default(0),
  clean: yup.default.number().required().default(0),
  avg: yup.default.number().required().default(0),
  rough: yup.default.number().required().default(0),
})

const PricingSchema = yup.default.object().shape({
  baseRetailPrices: PriceRangeSchema,
  adjustedRetailPrices: PriceRangeSchema,
  baseWholePrices: PriceRangeSchema,
  adjustedWholePrices: PriceRangeSchema,
  regionalRetailAdjustments: PriceRangeSchema,
  regionalWholeAdjustments: PriceRangeSchema,
  kilometerThresholds: PriceRangeSchema,
})
const vehicleSchema = {
  thirdPartyId: yup.default.string(),
  isTradeIn: yup.default.boolean().default(false),
  pricingCategory: yup.default.string().nullable(),

  // Common
  year: yupExtInt.integer
    .positive()
    .default(0)
    .when('vin', {
      is: (value: string) => value && value.length === 17,
      then: (schema) => schema.required(),
    }),
  make: yup.default
    .string()
    .default('')
    .when('vin', {
      is: (value: string) => value && value.length === 17,
      then: yup.default.string().required(),
    }),
  model: yup.default
    .string()
    .default('')
    .when('vin', {
      is: (value: string) => value && value.length === 17,
      then: yup.default.string().required(),
    }),
  // New vehicle
  msrp: yupExtInt.double.when('isTradeIn', {
    is: false,
    then: yupExtInt.integer.when('condition', {
      is: (value: ECarCondition) => value && value === ECarCondition.New,
      then: yupExtInt.double.positive().required(),
    }),
  }),

  // Vehicle
  additions: yup.default
    .array(AdditionsSchema)
    .default([])
    .when('isTradeIn', {
      is: false,
      then: yup.default.array(AdditionsSchema),
    }),
  includes: yup.default
    .array()
    .default([])
    .when('isTradeIn', {
      is: false,
      then: yup.default.array(yup.default.string().required().default('')),
    }),
  series: yup.default.string().default('').when('isTradeIn', {
    is: false,
    then: yup.default.string().required(),
  }),
  condition: yup.default
    .mixed<ECarCondition>()
    .default('' as ECarCondition)
    .nullable()
    .oneOf(ECarConditionList)
    .when('isTradeIn', {
      is: false,
      then: (schema) => schema.required(),
    }),
  vin: yup.default
    .string()
    .default('')
    .uppercase()
    .when('isTradeIn', {
      is: false,
      then: yup.default
        .string()
        .required()
        .matches(/^[A-HJ-NPR-Za-hj-npr-z0-9]{17}$/, 'common.errors.invalidVin'), // Same as server
    }),
  mileage: yupExtInt.integer
    .positive()
    .default(0)
    .when('isTradeIn', {
      is: false,
      then: yupExtInt.integer.positive().required().nullable(false),
    }),
  bodyStyle: yup.default.string().default('').when('isTradeIn', {
    is: false,
    then: yup.default.string().required(),
  }),
  pricing: PricingSchema,
  // Trade In Vehicle
  allowance: yupExtInt.double.default(0).when(['isTradeIn'], {
    is: true,
    then: yupExtInt.double.positive().required(),
  }),
  lienAmount: yupExtInt.double.positive(),
  balanceOwedTo: yup.default.string().when(['isTradeIn', 'vin'], {
    is: (isTradeIn: boolean, lienAmount: number | null) => {
      return lienAmount && isTradeIn
    },
    then: yup.default.string().required(),
  }),
  netTradeInAllowance: yupExtInt.double,
  thirdPartySearchOverride: yup.default.boolean().default(false),
  thirdPartyEvaluationValue: yupExtInt.double.nullable().default(null),
  brokerEstimatedValue: yupExtInt.double.nullable().default(null),
}

export const VehicleSchema = yup.default.object(vehicleSchema)
export type Vehicle = yup.default.InferType<typeof VehicleSchema>

export const vehicleInternalEvaluation = yup.default.object({
  id: yup.default.string().nullable(),
  vehicleValue: yupExtInt.double.positive(),
  discretionaryAllocation: yupExtInt.double.positive().max(500).default(0),
})

export type VehicleInternalEvaluation = yup.default.InferType<typeof vehicleInternalEvaluation>

export const vehicleEvaluationDTO = yup.default.object({
  creditApplicationId: yup.default.string().nullable(),
  vehicleValue: yupExtInt.double.positive(),
  discretionaryAllocation: yupExtInt.double.min(0).max(500).default(0),
  carfaxValue: yupExtInt.double.nullable().positive().default(0),
  versionTag: yup.default.string(),
})

export type VehicleEvaluationDTO = yup.default.InferType<typeof vehicleEvaluationDTO>

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const autoWorksheetDecisionDTOSchema = yup.default.object({
  creditApplicationId: yup.default.string().nullable(),
  otherReason: yup.default.string().default('').nullable(),
  decision: yup.default.mixed<EWorksheetDecision>(),
  fundingThresholdExtend: baseWorksheetFundingThresholdExtendSchema
    .notRequired()
    .nullable()
    .default(baseWorksheetFundingThresholdExtendSchema.getDefault()),
  refusalMessages: yup.default.mixed<ParameterizedMessage[]>().default([]),
  versionTag: yup.default.string(),
})

export type AutoWorksheetDecisionDTO = yup.default.InferType<typeof autoWorksheetDecisionDTOSchema>

export const AutoWorksheetSchema = yup.default
  .object({
    vehicle: VehicleSchema,
    tradeInVehicle: VehicleSchema.nullable().default(null),
    carfaxValue: yupExtInt.double.nullable().positive().default(0),

    price: yupExtInt.double.positive().required(),
    rebate: yupExtInt.double.positive(),
    cashDown: yupExtInt.double.positive(),
    installationDelivery: yupExtInt.double.positive(),
    licenseFee: yupExtInt.double.positive(),
    dealerAdminFee: yupExtInt.double.positive(),
    otherTaxable: yupExtInt.double.positive(),
    otherTaxableDescription: yup.default
      .string()
      .nullable()
      .when('otherTaxable', {
        is: (otherTaxable: number | null) => otherTaxable !== null,
        then: yup.default.string().required(),
      }),
    otherNonTaxable: yupExtInt.double.positive(),
    otherNonTaxableDescription: yup.default
      .string()
      .nullable()
      .when('otherNonTaxable', {
        is: (otherNonTaxable: number | null) => otherNonTaxable !== null,
        then: yup.default.string().required(),
      }),
    icebergPPSA: yupExtInt.integer.positive(),
    vehicleAccAndFees: yupExtInt.double,
    lifeInsurance: yupExtInt.double.positive(),
    accidentAndHealthInsurance: yupExtInt.double.positive(),
    isTaxExempt: yup.default.boolean().default(false),
  })
  .shape(
    {
      extendedService: yupExtInt.double.when('extendedServiceDuration', {
        is: (extendedServiceDuration: number | null) => extendedServiceDuration !== null,
        then: yupExtInt.double.required().positive(),
      }),
      extendedServiceDuration: yupExtInt
        .numberEnum<EExtendedServiceDuration>(EExtendedServiceDurationList)
        .when('extendedService', {
          is: (extendedService: number | null) => extendedService !== null,
          then: (schema) => schema.required(),
        }),
      gapInsurance: yupExtInt.double.when('gapInsuranceDuration', {
        is: (gapInsuranceDuration: number | null) => gapInsuranceDuration !== null,
        then: yupExtInt.double.required().positive(),
      }),
      gapInsuranceDuration: yupExtInt.integer.when('gapInsurance', {
        is: (gapInsurance: number | null) => gapInsurance !== null,
        then: yupExtInt.integer.required().moreThan(-1, 'common.errors.positive'),
      }),
    },
    [
      ['extendedService', 'extendedServiceDuration'],
      ['gapInsurance', 'gapInsuranceDuration'],
    ],
  )
  .concat(baseWorksheetSchema)

export type AutoWorksheet = yup.default.InferType<typeof AutoWorksheetSchema> &
  BaseWorksheet & {
    applicableTaxesOnVehicleAndFees: ApplicableTax[]
    applicableTaxOnInsurances: ApplicableTax
    computedVehicleAndFeesTaxes: ComputedTax[]
    tradeInVehicleTaxedAmount: number
    extendedServiceTaxedAmount: number
    brokerRequestedFinancingPlan: EPlanType
    totalAmountFinancedWithoutInsurancesAndExtendedWarranty: number
    vehicleEvaluation: VehicleInternalEvaluation
    thirdPartyVehicleValue: number
    finalVehicleValue: number
    margin: number
    authorizedSurplus: number
    authorizedAmount: number
    taxesOnInsurance: number
    aftermarketVsValue: number
  }

export type AutoWorksheetRevision = AutoWorksheet & {
  CurrentRevisionId: string
}
