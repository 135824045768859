import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import { ApplicantJob, ApplicantOtherIncome, EOtherIncomeType } from '../../../data/types'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'
import { formatAddress, FormatCurrencyNoDecimals } from '../../../services/Formatter'

interface Props {
  incomes: ApplicantJob[]
  otherIncomes: ApplicantOtherIncome[]
  externalReportedIncome?: number
}

const IncomesTable = ({ incomes, otherIncomes, externalReportedIncome }: Props) => {
  const { t } = useTranslation()
  const jobTypeEnum = useAppSelector(appSelectors.getJobTypeEnum)
  const otherIncomeTypeEnum = useAppSelector(appSelectors.getOtherIncomeTypesEnum)
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum

  let totalIncome = 0
  let totalOtherIncome = 0
  incomes.forEach((item) => {
    totalIncome += item.annualSalary
  })

  otherIncomes.forEach((item) => {
    totalOtherIncome += item.annualAmount
  })

  return (
    <TableContainer component={Paper}>
      <Table aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={3}>
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                <Typography textAlign="center" variant="h6" component="h6">
                  {t('credit.totalIncomes')}
                </Typography>
                <Typography textAlign="center" variant="h6" component="h6">
                  {FormatCurrencyNoDecimals(totalIncome + totalOtherIncome)}
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography textAlign="left" variant="h6" component="h6">
                  {t('editCreditApplication.income.job.label')}
                </Typography>
                <Typography textAlign="right" variant="h6" component="h6">
                  {FormatCurrencyNoDecimals(totalIncome)}
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {incomes.map((item) => (
            <TableRow key={item.employerName}>
              <TableCell>
                <Typography>{`${item.employerName} (${jobTypeEnum[item.jobType.toString()][lang]}) : ${
                  item.jobTitle
                }`}</Typography>
                {formatAddress(item.address)}
              </TableCell>
              <TableCell align="right">{FormatCurrencyNoDecimals(item.annualSalary)}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell align="center" colSpan={3}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h6" component="h6">
                  {t('editCreditApplication.income.other.label')}
                </Typography>
                <Typography variant="h6" component="h6">
                  {FormatCurrencyNoDecimals(totalOtherIncome)}
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
          {otherIncomes.map((item) => (
            <TableRow key={item.annualAmount + item.typeId}>
              <TableCell>
                {otherIncomeTypeEnum[item.typeId.toString()][lang]}
                {item.typeId === EOtherIncomeType.other && ` : ${item.description}`}
              </TableCell>
              <TableCell align="right">{FormatCurrencyNoDecimals(item.annualAmount)}</TableCell>
            </TableRow>
          ))}
          {externalReportedIncome && externalReportedIncome !== 0 && (
            <TableRow>
              <TableCell colSpan={3}>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                  <Typography textAlign="center" variant="h6" component="h6">
                    {t('credit.externalReportedIncome')}
                  </Typography>
                  <Typography textAlign="center" variant="h6" component="h6">
                    {FormatCurrencyNoDecimals(externalReportedIncome)}
                  </Typography>
                </Stack>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default IncomesTable
