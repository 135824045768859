import {
  Checkbox,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { debounce, every } from 'lodash-es'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ActionsDialog, InputTextField, MultipleSelectCheckmarks, SelectComponent } from '../../../components'
import { TRootState, useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import { EApplicantType, ECreditApplicationStepList, EFinancingProgram, SelectValueListItem } from '../../../data/types'
import { DocumentRequired } from '../../../data/types/DocumentSchema'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'
import { creditSelectors } from '../../../data/store/CreditApplication'

interface Props {
  open: boolean
  creditApplicationId: string
  onConfirm: (data: DocumentRequired[]) => void
  onCancel: () => void
}

const DocumentsRequestDialog = ({ open, creditApplicationId, onConfirm, onCancel }: Props) => {
  const { t } = useTranslation()
  const { financingProgramId } = useParams<{ financingProgramId: EFinancingProgram }>()
  const documentTypes = useAppSelector((state: TRootState) =>
    appSelectors.getDocumentTypesAddableManually(state, financingProgramId!),
  )
  const hasCoapplicant = useAppSelector(creditSelectors.hasCoapplicant)
  const applicantTypeEnum = useAppSelector(appSelectors.getApplicantTypeEnum)
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum
  const [selectedDocumentsId, setselecteDocumentsId] = React.useState<number[]>([])
  const [textFilter, setTextFilter] = React.useState<string>('')
  const [filteredDocs, setFilteredDocs] = React.useState<number[]>([])
  const handleOnConfirm = React.useCallback(() => {
    const docs: DocumentRequired[] = []

    selectedDocumentsId.forEach((typeId) => {
      const requiredBeforeStepElement = document.getElementById(`${typeId}.requiredBeforeStep`) as HTMLInputElement
      const applicantsElement = document.getElementById(`${typeId}.applicants`) as HTMLInputElement
      const noteElement = document.getElementById(`${typeId}.note`) as HTMLInputElement

      if (applicantsElement) {
        const applicant = applicantsElement.value.split(',')
        applicant.forEach((app) => {
          const doc = {
            creditApplicationId,
            financingProgramId,
            typeId,
            applicantType: app === '' ? EApplicantType.Applicant : app,
            requiredBeforeStep: requiredBeforeStepElement.value,
            note: noteElement.value,
          } as DocumentRequired
          docs.push(doc)
        })
      } else {
        const doc = {
          creditApplicationId,
          financingProgramId,
          typeId,
          applicantType: null,
          requiredBeforeStep: requiredBeforeStepElement.value,
          note: noteElement.value,
        } as DocumentRequired
        docs.push(doc)
      }
    })

    onConfirm(docs)
    setselecteDocumentsId([])
  }, [onConfirm, selectedDocumentsId, creditApplicationId, financingProgramId])

  const handleOnChange = React.useCallback(
    (checked: boolean, docId: number) => {
      const docs = selectedDocumentsId.filter((x) => x !== docId)
      if (checked) setselecteDocumentsId([...docs, docId])
      else setselecteDocumentsId(docs)
    },
    [selectedDocumentsId],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterDocs = useCallback(
    debounce((filters) => {
      const match = Object.values(documentTypes).filter((type) => {
        return every(
          filters,
          (f: string) => type[lang].toString().toLocaleLowerCase().includes(f.toLocaleLowerCase()),
        )
      })
      setFilteredDocs(match.map((x) => Number(x.id)))
    }, 200),
    [documentTypes],
  )

  React.useEffect(() => {
    if (open) {
      if (textFilter.trim() !== '') {
        filterDocs(textFilter.split(' '))
      } else setFilteredDocs(Object.keys(documentTypes).map((id) => Number(id)))
    }
  }, [textFilter, open, documentTypes, filterDocs])

  return (
    <ActionsDialog
      title={
        <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={3}>
          <Typography>{t('document.request')}</Typography>
          <TextField
            name={t('common.filter')}
            onChange={(e) => setTextFilter(e.target.value)}
            value={textFilter}
            label={t('common.filter')}
          />
        </Stack>
      }
      open={open}
      onCancel={() => {
        onCancel()
        setselecteDocumentsId([])
      }}
      onConfirm={handleOnConfirm}
    >
      <TableContainer component={Paper} sx={{ minWidth: '100vh', maxHeight: '70vh', mt: 2 }}>
        <Table>
          <TableBody>
            {filteredDocs
              .map((id) => {
                return {
                  id,
                  label: documentTypes[id][lang],
                  mustSpecifyApplicant: documentTypes[id].mustSpecifyApplicant,
                }
              })
              .sort((a, b) => a.label.toString().localeCompare(b.label.toString()))
              .map((item) => (
                <TableRow key={item.id}>
                  <TableCell align="right">
                    <Checkbox
                      checked={selectedDocumentsId.find((x) => x === item.id) !== undefined}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleOnChange(event.target.checked, item.id)
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">{item.label}</TableCell>
                  <TableCell align="left">
                    {item.mustSpecifyApplicant === true && (
                      <MultipleSelectCheckmarks
                        {...{ id: `${item.id}.applicants` }}
                        title={`${t('common.applicant')}(s)`}
                        content={Object.values(applicantTypeEnum).map((val) => {
                          const data: SelectValueListItem = {
                            label: val[lang],
                            value: val.id,
                          }
                          return data
                        })}
                        disabled={!hasCoapplicant}
                      />
                    )}
                  </TableCell>
                  <TableCell sx={{ minWidth: '15%' }} align="left">
                    <SelectComponent
                      {...{ id: `${item.id}.requiredBeforeStep` }}
                      items={ECreditApplicationStepList.map((value) => ({
                        label: `enum.eCreditApplicationSteps.${value}`,
                        value,
                      }))}
                      label={t('common.requiredFor') as string}
                    />
                  </TableCell>
                  <TableCell align="left" sx={{ width: '35%' }}>
                    <InputTextField
                      placeholder={t('common.clientVisible')}
                      id={`${item.id}.note`}
                      label={`${t('common.comment')} - ${t('common.clientVisible')}`}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ActionsDialog>
  )
}

export default React.memo(DocumentsRequestDialog)
