import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { AutoRslaWorksheet } from '@src/data/types/AutoRslaWorksheet'
import translate from '@src/services/translate'
import type { TRootState } from '..'
import { apiStateSelectors } from '../ApiStateStore'

import { NormsMessage } from '../../types'
import { appSelectors } from '../AppStore'
import { ParameterizedMessage } from '../../types/ParameterizedMessage'

export const SAVE_AUTO_RSLA_WORKSHEET = 'autoRslaWorksheet/save'
export const GET_AUTO_RSLA_WORKSHEET = 'autoRslaWorksheet/getById'

export const AutoRslaWorksheetSlice = createSlice({
  name: 'autoRslaWorksheet',
  initialState: {
    current: null as AutoRslaWorksheet | null,
  },
  reducers: {
    setCurrent: (state, action: PayloadAction<AutoRslaWorksheet | null>) => {
      state.current = action.payload
    },
  },
})

export default AutoRslaWorksheetSlice.reducer

export const autoRslaWorksheetActions = AutoRslaWorksheetSlice.actions

const autoRslaWorksheetSelectorsPartial = {
  isSavingPersonalLoanWorksheet: (state: TRootState) => apiStateSelectors.isLoading(state, SAVE_AUTO_RSLA_WORKSHEET),
  getCurrent: (state: TRootState) => state.autoRslaWorksheet.current,
}

function getParameterizedNormMessage(message: ParameterizedMessage): string {
  const translatedMessage = translate([`worksheet.norms.${message.id}`])

  let messageSuffix = ' | '
  message.parameters.forEach((p, i) => {
    if (i >= 1) messageSuffix = messageSuffix.concat(', ')

    const suffix = translate(`worksheet.paramNames.${p.name}`, { val: p.value })
    messageSuffix = messageSuffix.concat(suffix)
  })

  return translatedMessage.concat(messageSuffix)
}

const getCurrentWorksheetNormWarningMessages = createSelector(
  [autoRslaWorksheetSelectorsPartial.getCurrent, appSelectors.getCurrentLang],
  (currentWorksheet) => {
    const worksheetNormMessages: NormsMessage[] = []
    currentWorksheet?.normsDecision?.warningMessages.forEach((wr) => {
      const builtMessage = getParameterizedNormMessage(wr)
      const worksheetNormMessage: NormsMessage = {
        id: wr.id,
        message: builtMessage,
        applicantType: null,
      }
      worksheetNormMessages.push(worksheetNormMessage)
    })

    return worksheetNormMessages
  },
)

const getCurrentWorksheetNormRefusalMessages = createSelector(
  [autoRslaWorksheetSelectorsPartial.getCurrent, appSelectors.getCurrentLang],
  (currentWorksheet) => {
    const worksheetNormMessages: NormsMessage[] = []
    currentWorksheet?.normsDecision?.refusalMessages.forEach((wr) => {
      const builtMessage = getParameterizedNormMessage(wr)
      const worksheetNormMessage: NormsMessage = {
        id: wr.id,
        message: builtMessage,
        applicantType: null,
      }
      worksheetNormMessages.push(worksheetNormMessage)
    })

    return worksheetNormMessages
  },
)

const getCurrentWorksheetNormRefusalAndWarningMessages = createSelector(
  [autoRslaWorksheetSelectorsPartial.getCurrent, appSelectors.getCurrentLang],
  (currentWorksheet) => {
    const warningsAndRefusals = currentWorksheet?.normsDecision?.warningMessages?.concat(
      currentWorksheet?.normsDecision?.refusalMessages,
    )

    const worksheetNormMessages: NormsMessage[] = []
    warningsAndRefusals?.forEach((wr) => {
      const builtMessage = getParameterizedNormMessage(wr)
      const worksheetNormMessage: NormsMessage = {
        id: wr.id,
        message: builtMessage,
        applicantType: null,
      }
      worksheetNormMessages.push(worksheetNormMessage)
    })
    return worksheetNormMessages
  },
)

export const autoRslaWorksheetSelectors = {
  ...autoRslaWorksheetSelectorsPartial,
  getCurrentWorksheetNormRefusalAndWarningMessages,
  getCurrentWorksheetNormWarningMessages,
  getCurrentWorksheetNormRefusalMessages,
}
