import { appSelectors } from '@src/data/store/AppStore'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Breadcrumb } from '../../components'
import { TRootState, useAppDispatch, useAppSelector } from '../../data/store'
import { creditActions, creditEffects } from '../../data/store/CreditApplication'
import { buildDraftCreditApplicationSchema, CreditApplication, EFinancingProgram } from '../../data/types'
import { reportErrorToServer } from '../../services/error-logger'
import CreditApplicationForm from './components/CreditApplicationForm/CreditApplicationForm'

const CreateCreditApplicationPage = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const dispatchEffect = useSideEffect()
  const navigate = useNavigate()
  const { financingProgramId } = useParams<{ financingProgramId: EFinancingProgram }>()
  const financingConfig = useAppSelector((state: TRootState) =>
    appSelectors.getFinancingConfig(state, financingProgramId),
  )

  const [creditApplication] = useState<CreditApplication>({
    ...(buildDraftCreditApplicationSchema(financingConfig).getDefault() as CreditApplication),
    financingProgramId: financingProgramId!,
  })
  document.title = t('breadcrumbs.newApplication')
  const onSave = (data: Partial<CreditApplication>) => {
    dispatchEffect(creditEffects.create(data))
      .then((creditApp) => {
        navigate(`/Applications/${creditApp.financingProgramId}/${creditApp.id}/view`, { replace: true })
      })
      .catch(reportErrorToServer)
  }

  const breadCrumbs = [
    { path: '/', label: t('breadcrumbs.home') },
    { path: '/Applications/browse', label: t('breadcrumbs.creditApplication') },
    { path: '#', label: t('breadcrumbs.newApplication') },
  ]

  useEffect(() => {
    dispatch(creditActions.setMerchant(null))
  }, [dispatch])

  return (
    <div>
      <Breadcrumb trees={breadCrumbs} />
      <CreditApplicationForm creditApplication={creditApplication} onSave={onSave} />
    </div>
  )
}

export default CreateCreditApplicationPage
