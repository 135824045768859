import { Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AutoWorksheet } from '../../../data/types'
import { EDecision } from '../../../data/types/constants'
import { FormatCurrency } from '../../../services/Formatter'
import DecisonIcon from '../../CreditDecisionPage/components/decisonIcon'

interface Props {
  worksheet: AutoWorksheet
}

const NormIcebergWorksheet = ({ worksheet }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <>
      <Typography variant="h6" component="h3" gutterBottom>
        {t('credit.icebergStandards')}
      </Typography>
      <DecisonIcon decision={EDecision.manualRevision} />
      <Stack direction="row" spacing={4} justifyContent="center" alignItems="center" marginBottom={2}>
        <Typography variant="subtitle1" component="p">
          {t('worksheet.sideSection.margin')} :
        </Typography>
        <Typography variant="subtitle1" component="p">
          {FormatCurrency(worksheet.margin)}
        </Typography>
      </Stack>
      <Typography
        variant="subtitle1"
        sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
        component="p"
      >
        Norm 1
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
        component="p"
      >
        Norm 2
      </Typography>
    </>
  )
}

export default NormIcebergWorksheet
