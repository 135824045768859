import { Stack, debounce } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { Applicant, EFinancingProgram } from '../../../data/types'
import { ActionsDialog, InputTextField } from '../../../components'
import LoanCustomers from './loanCustomers'
import { SetCustomerReferenceDto } from '../../../data/types/CustomerReferenceSchema'
import { LoanCustomer, SearchCustomerDto } from '../../../data/types/LoanCustomerSchema'
import { creditEffects } from '../../../data/store/CreditApplication'
import { reportErrorToConsole } from '../../../services/error-logger'

interface Props {
  applicant: Applicant
  onCancel: () => void
  open: boolean
  title: string
  creditApplicationId: string
  financingProgramId: EFinancingProgram
  isApplicant: boolean
}

const CustomerAccountDialog = ({
  applicant,
  onCancel,
  open,
  title,
  creditApplicationId,
  financingProgramId,
  isApplicant,
}: Props) => {
  const dispatchEffect = useSideEffect()
  const { t } = useTranslation()

  const [selectedCustomer, setSelectedCustomer] = useState<string>(
    applicant?.existingCustomerReference ? applicant?.existingCustomerReference.customerId : '',
  )
  const [customers, setCustomers] = useState<LoanCustomer[]>([])
  const [firstName, setFirstName] = useState<string>(applicant.firstName)
  const [lastName, setLastName] = useState<string>(applicant.lastName)

  const handleSelectedCustomer = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSelectedCustomer(value)
  }, [])

  const handleSaveCustomer = useCallback(
    (data: SetCustomerReferenceDto) => {
      if (isApplicant) {
        return dispatchEffect(creditEffects.setApplicantCustomerReference(data)).catch(reportErrorToConsole)
      }
      return dispatchEffect(creditEffects.setCoapplicantCustomerReference(data)).catch(reportErrorToConsole)
    },
    [isApplicant, dispatchEffect],
  )

  const onConfirmData = useCallback(() => {
    const selectedLoanCustomer = customers.find((x) => x.id === selectedCustomer)
    const selectedData: SetCustomerReferenceDto = {
      creditApplicationId,
      customerId: selectedLoanCustomer?.id ?? '',
      financingProgramId,
    }
    return handleSaveCustomer(selectedData).then(() => onCancel())
  }, [customers, selectedCustomer, onCancel, creditApplicationId, financingProgramId, handleSaveCustomer])

  useEffect(() => {
    setFirstName(applicant.firstName)
    setLastName(applicant.lastName)
  }, [applicant])

  useEffect(() => {
    if (open) {
      const searchCustomer = {
        firstName,
        lastName,
        birthDate: applicant.birthDate,
        sin: applicant.sin,
      } as SearchCustomerDto
      dispatchEffect(creditEffects.findCustomers(searchCustomer))
        .then((data) => {
          setCustomers(data)
        })
        .catch(reportErrorToConsole)
    }
  }, [applicant, firstName, lastName, open, dispatchEffect])

  const onFirstNameEdited = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    setFirstName(event.target.value)
  }, [])

  const onLastNameEdited = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    setLastName(event.target.value)
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFirstNameEdited = useCallback(debounce(onFirstNameEdited, 500), [onFirstNameEdited])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceLastNameEdited = useCallback(debounce(onLastNameEdited, 500), [onLastNameEdited])

  return (
    <ActionsDialog onCancel={onCancel} onConfirm={onConfirmData} open={open} title={title}>
      <Stack spacing={2} direction="row" marginTop={2} justifyContent="center">
        <InputTextField
          sx={{ width: '20rem' }}
          label={t('editCreditApplication.information.firstName')}
          defaultValue={firstName}
          onChange={debounceFirstNameEdited}
        />
        <InputTextField
          sx={{ width: '20rem' }}
          label={t('editCreditApplication.information.lastName')}
          defaultValue={lastName}
          onChange={debounceLastNameEdited}
        />
      </Stack>
      <LoanCustomers
        customers={customers}
        handleSelectedCustomer={handleSelectedCustomer}
        selectedCustomer={selectedCustomer}
      />
    </ActionsDialog>
  )
}

export default React.memo(CustomerAccountDialog)
