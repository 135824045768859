import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import translate from '@src/services/translate'
import { B2cWorksheet, B2cWorksheetRevision } from '../../types/B2cWorksheetSchema'
import { FundingComputedDto } from '../../types/FundingComputedDto'
import type { TRootState } from '..'
import { EPaymentPlan, EWorksheetDecision, NormsMessage } from '../../types'
import { ParameterizedMessage } from '../../types/ParameterizedMessage'
import { apiStateSelectors } from '../ApiStateStore'
import { appSelectors } from '../AppStore'

export const B2cWorksheetSlice = createSlice({
  name: 'b2cWorksheet',
  initialState: {
    current: null as B2cWorksheet | null,
    computedInfo: {} as FundingComputedDto,
    currentRevisions: [] as B2cWorksheetRevision[],
  },
  reducers: {
    setCurrent: (state, action: PayloadAction<B2cWorksheet | null>) => {
      state.current = action.payload
    },
    setFundingComputed: (state, action: PayloadAction<FundingComputedDto | null>) => {
      if (action.payload)
        state.computedInfo = {
          ...state.computedInfo,
          ...action.payload,
        }
    },
    setCurrentRevisions: (state, action: PayloadAction<B2cWorksheetRevision[]>) => {
      state.currentRevisions = action.payload.sort((a, b) => {
        if (!a.updatedOn) return -1
        if (!b.updatedOn) return 1
        return a.updatedOn < b.updatedOn ? 1 : -1
      })
    },
  },
})

export default B2cWorksheetSlice.reducer

export const b2cWorksheetActions = B2cWorksheetSlice.actions

export const SAVE_B2C_WORKSHEET = 'b2cWorksheet/save'
export const COMPUTE_B2C_FUNDING = 'b2cWorksheet/computeB2cFunding'
export const SAVE_B2C_WORKSHEET_DECISION = 'b2cWorksheet/saveDecision'
export const GET_B2C_WORKSHEET_BY_ID = 'b2cWorksheet/getB2cWorksheetById'
export const GET_B2C_WORKSHEET_REVISIONS = 'b2cWorksheet/revisions'

const b2cWorksheetSelectorsPartial = {
  isSavingB2cWorksheet: (state: TRootState) => apiStateSelectors.isLoading(state, SAVE_B2C_WORKSHEET),
  isComputeB2cFunding: (state: TRootState) => apiStateSelectors.isLoading(state, COMPUTE_B2C_FUNDING),
  getCurrent: (state: TRootState) => state.b2cWorksheet.current,
  getCurrentRevisions: (state: TRootState) => state.b2cWorksheet.currentRevisions,
  getComputedInfo: (state: TRootState) => state.b2cWorksheet.computedInfo,
  getTotalfees: (state: TRootState) => state.b2cWorksheet.computedInfo?.lenderFee || 0,
  getTotalInsurance: (state: TRootState) =>
    (state.b2cWorksheet.computedInfo?.insuranceFee || 0) + (state.b2cWorksheet.computedInfo?.insuranceTax || 0),
  getTotalObligation: (state: TRootState) =>
    (state.b2cWorksheet.current?.amountRequested || 0) +
    (state.b2cWorksheet.computedInfo?.totalInterestAmount || 0) +
    (state.b2cWorksheet.computedInfo?.lenderFee || 0) +
    (state.b2cWorksheet.computedInfo?.insuranceFee || 0) +
    (state.b2cWorksheet.computedInfo?.insuranceTax || 0),
  worksheetIsApproved: (state: TRootState) =>
    state.b2cWorksheet.current?.finalDecision.decision === EWorksheetDecision.Approved,
  hasConfirmedPromotion: (state: TRootState) =>
    state.b2cWorksheet.current !== null &&
    state.b2cWorksheet.current?.paymentPlanId !== EPaymentPlan.regularDailyInterests,
  isCreated: (state: TRootState) => state.b2cWorksheet.current !== null,
}

function getParameterizedNormMessage(message: ParameterizedMessage): string {
  const translatedMessage = translate([`worksheet.norms.${message.id}`])

  let messageSuffix = ' | '
  message.parameters.forEach((p, i) => {
    if (i >= 1) messageSuffix = messageSuffix.concat(', ')

    const suffix = translate(`worksheet.paramNames.${p.name}`, { val: p.value })
    messageSuffix = messageSuffix.concat(suffix)
  })

  return translatedMessage.concat(messageSuffix)
}

const getCurrentWorksheetNormWarningMessages = createSelector(
  [b2cWorksheetSelectorsPartial.getCurrent, appSelectors.getCurrentLang],
  (currentWorksheet) => {
    const worksheetNormMessages: NormsMessage[] = []
    currentWorksheet?.normsDecision?.warningMessages.forEach((wr) => {
      const builtMessage = getParameterizedNormMessage(wr)
      const worksheetNormMessage: NormsMessage = {
        id: wr.id,
        message: builtMessage,
        applicantType: null,
      }
      worksheetNormMessages.push(worksheetNormMessage)
    })

    return worksheetNormMessages
  },
)

const getCurrentWorksheetNormRefusalMessages = createSelector(
  [b2cWorksheetSelectorsPartial.getCurrent, appSelectors.getCurrentLang],
  (currentWorksheet) => {
    const worksheetNormMessages: NormsMessage[] = []
    currentWorksheet?.normsDecision?.refusalMessages.forEach((wr) => {
      const builtMessage = getParameterizedNormMessage(wr)
      const worksheetNormMessage: NormsMessage = {
        id: wr.id,
        message: builtMessage,
        applicantType: null,
      }
      worksheetNormMessages.push(worksheetNormMessage)
    })

    return worksheetNormMessages
  },
)
const getCurrentWorksheetNormRefusalAndWarningMessages = createSelector(
  [b2cWorksheetSelectorsPartial.getCurrent, appSelectors.getCurrentLang],
  (currentWorksheet) => {
    const warningsAndRefusals = currentWorksheet?.normsDecision?.warningMessages?.concat(
      currentWorksheet?.normsDecision?.refusalMessages,
    )

    const worksheetNormMessages: NormsMessage[] = []
    warningsAndRefusals?.forEach((wr) => {
      const builtMessage = getParameterizedNormMessage(wr)
      const worksheetNormMessage: NormsMessage = {
        id: wr.id,
        message: builtMessage,
        applicantType: null,
      }
      worksheetNormMessages.push(worksheetNormMessage)
    })
    return worksheetNormMessages
  },
)

export const b2cWorksheetSelectors = {
  ...b2cWorksheetSelectorsPartial,
  getCurrentWorksheetNormRefusalAndWarningMessages,
  getCurrentWorksheetNormWarningMessages,
  getCurrentWorksheetNormRefusalMessages,
}
