import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { BankAccount, BankAccountSchema } from '../../../data/types'
import ActionsDialog from '../../../components/ActionsDialog'
import { reportErrorToConsole } from '../../../services/error-logger'
import { appSelectors } from '../../../data/store/AppStore'
import { useAppSelector } from '../../../data/store'
import { InputTextField, PageError } from '../../../components'

interface Props {
  bankAccount: BankAccount | undefined
  open: boolean
  onConfirm: (data: BankAccount) => void
  onCancel: () => void
}

const EditBankAccountDialog = ({ bankAccount, onConfirm, onCancel, open }: Props) => {
  const { t } = useTranslation()

  const error = useAppSelector(appSelectors.getBusinessError)

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<BankAccount>({
    mode: 'onBlur',
    defaultValues: bankAccount,
    resolver: yupResolver(BankAccountSchema),
  })

  React.useEffect(() => {
    reset(bankAccount)
  }, [bankAccount, reset])

  return (
    <ActionsDialog
      title={t('bankAccount.title')}
      onCancel={onCancel}
      onConfirm={handleSubmit(onConfirm, reportErrorToConsole)}
      open={open}
    >
      <PageError errors={error} />
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <InputTextField
            maxLength={50}
            error={errors?.bankName}
            label={t('bankAccount.bankName')}
            {...register('bankName')}
          />
        </Grid>
        <Grid item xs={12}>
          <InputTextField
            maxLength={3}
            error={errors?.institutionNumber}
            label={t('bankAccount.institutionNumber')}
            {...register('institutionNumber')}
          />
        </Grid>
        <Grid item xs={12}>
          <InputTextField
            maxLength={5}
            error={errors?.transitNumber}
            label={t('bankAccount.transitNumber')}
            {...register('transitNumber')}
          />
        </Grid>
        <Grid item xs={12}>
          <InputTextField
            maxLength={12}
            error={errors?.accountNumber}
            label={t('bankAccount.accountNumber')}
            {...register('accountNumber')}
          />
        </Grid>
      </Grid>
    </ActionsDialog>
  )
}
export default React.memo(EditBankAccountDialog)
