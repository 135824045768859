import { Typography } from '@mui/material'
import { CreditReport, EFinancingProgram } from '@src/data/types'

interface DimensionPlusProps {
  creditReport: CreditReport
  financingProgramId: EFinancingProgram
}

const DimensionPlusPersonalLoanSection = (creditReport: CreditReport) => {
  return (
    <>
      <Typography variant="body1" gutterBottom component="div">
        A2MH017 : {creditReport.a2mh017}
      </Typography>
      <Typography variant="body1" gutterBottom component="div">
        A2VH004 : {creditReport.a2vh004}
      </Typography>
    </>
  )
}

const DimensionPlusAutoSection = (creditReport: CreditReport) => {
  return (
    <>
      <Typography variant="body1" gutterBottom component="div">
        ABDY4152: {creditReport.abdy4152}
      </Typography>
      <Typography variant="body1" gutterBottom component="div">
        ABDY4142: {creditReport.a2dy4142}
      </Typography>
    </>
  )
}

const DimensionPlusSection = ({ creditReport, financingProgramId }: DimensionPlusProps) => {
  return (
    <>
      <Typography variant="subtitle2" gutterBottom component="div" sx={{ mt: 2 }}>
        Dimension Plus
      </Typography>
      {financingProgramId === EFinancingProgram.Personal && DimensionPlusPersonalLoanSection(creditReport)}
      {financingProgramId === EFinancingProgram.Auto && DimensionPlusAutoSection(creditReport)}
    </>
  )
}

export default DimensionPlusSection
