import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { findIndex } from 'lodash-es'
import type { TRootState } from '..'
import { formatDateTime } from '../../../services/Formatter'
import {
  Document,
  DocumentDecision,
  DocumentPage,
  DocumentRequired,
  ECreditApplicationStep,
  EDocumentStatus,
  EDocumentType,
} from '../../types'
import { DocumentPageDisplayInfo } from '../../types/DocumentPageDisplayInfo'
import { DocumentTransformationResult } from '../../types/DocumentTransformationResult'
import { apiStateSelectors } from '../ApiStateStore'
import { appSelectors } from '../AppStore'

export const documentSlice = createSlice({
  name: 'document',
  initialState: {
    documentList: [] as Document[],
    documentPageList: [] as DocumentPage[],
    documentRequestedList: [] as DocumentRequired[],
  },
  reducers: {
    setCurrentDocument: (state, action: PayloadAction<Document>) => {
      const index = findIndex(state.documentList, { id: action.payload.id })
      if (index === -1) {
        state.documentList.unshift(action.payload)
      } else {
        state.documentList.splice(index, 1, action.payload)
      }
    },

    setCurrentDocumentRequired: (state, action: PayloadAction<DocumentRequired | null>) => {
      if (action.payload === null) return
      const index = findIndex(state.documentRequestedList, { id: action.payload.id })
      if (index === -1) {
        state.documentRequestedList.push(action.payload)
      } else {
        state.documentRequestedList.splice(index, 1, action.payload)
      }
    },

    setDocumentList: (state, action: PayloadAction<Document[]>) => {
      state.documentList = action.payload
    },

    setDocumentPageList: (state, action: PayloadAction<DocumentPage[]>) => {
      state.documentPageList = action.payload
    },

    setDocumentTransformationResult: (state, action: PayloadAction<DocumentTransformationResult | null>) => {
      if (action.payload === null) return
      state.documentList = action.payload.documents
      state.documentPageList = action.payload.pages
    },

    addMultipleDocuments: (state, action: PayloadAction<Document[]>) => {
      state.documentList = action.payload.concat(
        state.documentList.filter((x) => action.payload.every((y) => y.id !== x.id)),
      )
    },

    setRequiredDocumentList: (state, action: PayloadAction<DocumentRequired[]>) => {
      state.documentRequestedList = action.payload
    },

    addMultipleRequiredDocuments: (state, action: PayloadAction<DocumentRequired[]>) => {
      state.documentRequestedList = action.payload.concat(
        state.documentRequestedList.filter((x) => action.payload.every((y) => y.id !== x.id)),
      )
    },

    removeRequiredDocument: (state, action: PayloadAction<DocumentRequired>) => {
      const index = findIndex(state.documentRequestedList, { id: action.payload.id })
      state.documentRequestedList.splice(index, 1)
    },

    removePrivateDocument: (state, action: PayloadAction<Document>) => {
      const index = findIndex(state.documentList, { id: action.payload.id })
      state.documentList.splice(index, 1)
    },
  },
})

export default documentSlice.reducer

export const documentActions = documentSlice.actions

export const POST_CREATE_DOCUMENT_REQUEST = 'document/create'

export const GET_ALL_DOCUMENT = 'document/get'
export const GET_ALL_REQUIRED_DOCUMENT = 'required_document/get'
export const GET_ALL_PAGES = 'document/pages'
export const UPDATE_DOCUMENT_REQUEST = 'document/update'
export const SEND_DOCUMENT = 'document/upload'
export const DELETE_DOCUMENT_REQUEST = 'document/delete'
export const GET_THUMBNAILS = 'document/thumbnails'
export const DOWNLOAD_DOCUMENT = 'document/download'
export const APPLY_TRANSFORMATION = 'document/applyTransform'
export const MAKE_DECISIONS = 'document/decisions'
export const QUICK_MERGE = 'document/quickMerge'

const documentSelectorsPartial = {
  isLoadingDocuments: (state: TRootState) => apiStateSelectors.isLoading(state, GET_ALL_DOCUMENT),
  isLoadingDocumentPages: (state: TRootState) => apiStateSelectors.isLoading(state, GET_ALL_PAGES),
  isLoadingDocumentsRequest: (state: TRootState) => apiStateSelectors.isLoading(state, GET_ALL_REQUIRED_DOCUMENT),
  isCreatingDocumentRequest: (state: TRootState) => apiStateSelectors.isLoading(state, POST_CREATE_DOCUMENT_REQUEST),
  isLoadingThumbnails: (state: TRootState) => apiStateSelectors.isLoading(state, GET_THUMBNAILS),
  isDownloadingDocument: (state: TRootState) => apiStateSelectors.isLoading(state, DOWNLOAD_DOCUMENT),
  isUpLoadDocument: (state: TRootState) => apiStateSelectors.isLoading(state, SEND_DOCUMENT),
  isApplyingTransformation: (state: TRootState) => apiStateSelectors.isLoading(state, APPLY_TRANSFORMATION),
  isMakingDecisions: (state: TRootState) => apiStateSelectors.isLoading(state, MAKE_DECISIONS),
  isQuickMerging: (state: TRootState) => apiStateSelectors.isLoading(state, QUICK_MERGE),

  getDocumentList: (state: TRootState) => state.document.documentList,
  getDocumentPageList: (state: TRootState) => state.document.documentPageList,
  getDocumentRequestedList: (state: TRootState) => state.document.documentRequestedList,
}

function allDocumentRequiredForStep(step: ECreditApplicationStep, docs: Document[], docsReq: DocumentRequired[]) {
  return docsReq.every(
    (x) =>
      x.requiredBeforeStep !== step ||
      docs.some(
        (y) =>
          y.applicantType === x.applicantType &&
          y.typeId === x.typeId &&
          y.status === EDocumentStatus.Approved &&
          y.subKey === x.subKey,
      ),
  )
}
function getPrioritizedDocumentStatus(documents: Document[]): EDocumentStatus | undefined {
  if (documents.length === 0) return undefined
  if (documents.some((d) => d.status === EDocumentStatus.AwaitingScan)) return EDocumentStatus.AwaitingScan
  if (documents.some((d) => d.status === EDocumentStatus.Approved)) return EDocumentStatus.Approved
  const incompleteDoc = documents.find((d) => d.status === EDocumentStatus.Incomplete)
  if (incompleteDoc) {
    const awaitingApprovalDocs = documents.filter((d) => d.status === EDocumentStatus.AwaitingApproval)
    if (
      awaitingApprovalDocs.some((d) => d.updatedOn && incompleteDoc.updatedOn && d.updatedOn > incompleteDoc.updatedOn)
    )
      return EDocumentStatus.AwaitingApproval
    return EDocumentStatus.Incomplete
  }
  if (documents.some((d) => d.status === EDocumentStatus.AwaitingApproval)) return EDocumentStatus.AwaitingApproval
  if (documents.some((d) => d.status === EDocumentStatus.Refused)) return EDocumentStatus.Refused
  if (documents.some((d) => d.status === EDocumentStatus.Deleted)) return EDocumentStatus.Deleted
  return EDocumentStatus.AwaitingApproval
}

const statusOrder = {
  [EDocumentStatus.AwaitingScan]: 1,
  [EDocumentStatus.AwaitingDocument]: 2,
  [EDocumentStatus.AwaitingApproval]: 3,
  [EDocumentStatus.Incomplete]: 4,
  [EDocumentStatus.Refused]: 5,
  [EDocumentStatus.Approved]: 6,
  [EDocumentStatus.Deleted]: 7,
}

export const documentSelectors = {
  ...documentSelectorsPartial,
  areAllDocumentsRequiredForCredit: createSelector(
    [documentSelectorsPartial.getDocumentList, documentSelectorsPartial.getDocumentRequestedList],
    (docs, docsReq) => {
      return allDocumentRequiredForStep(ECreditApplicationStep.Credit, docs, docsReq)
    },
  ),
  areAllDocumentsRequiredForCVT: createSelector(
    [documentSelectorsPartial.getDocumentList, documentSelectorsPartial.getDocumentRequestedList],
    (docs, docsReq) => {
      return (
        allDocumentRequiredForStep(ECreditApplicationStep.CVT, docs, docsReq) &&
        allDocumentRequiredForStep(ECreditApplicationStep.Financing, docs, docsReq) &&
        allDocumentRequiredForStep(ECreditApplicationStep.Credit, docs, docsReq)
      )
    },
  ),
  areAllDocumentsRequiredForPayOut: createSelector(
    [documentSelectorsPartial.getDocumentList, documentSelectorsPartial.getDocumentRequestedList],
    (docs, docsReq) => {
      return (
        allDocumentRequiredForStep(ECreditApplicationStep.Financing, docs, docsReq) &&
        allDocumentRequiredForStep(ECreditApplicationStep.Credit, docs, docsReq) &&
        allDocumentRequiredForStep(ECreditApplicationStep.CVT, docs, docsReq) &&
        allDocumentRequiredForStep(ECreditApplicationStep.PayOut, docs, docsReq)
      )
    },
  ),
  hasDocumentsPendingApproval: createSelector([documentSelectorsPartial.getDocumentList], (docs) => {
    return docs.some((x) => x.status === EDocumentStatus.AwaitingScan || x.status === EDocumentStatus.AwaitingApproval)
  }),

  areAllDocumentsRequiredApproved: createSelector(
    [documentSelectorsPartial.getDocumentList, documentSelectorsPartial.getDocumentRequestedList],
    (docs, docsReq) => {
      return docsReq.every((x) =>
        docs.some(
          (y) =>
            y.applicantType === x.applicantType &&
            y.typeId === x.typeId &&
            y.status === EDocumentStatus.Approved &&
            y.subKey === x.subKey,
        ),
      )
    },
  ),

  getDocumentPageDisplayInfos: createSelector(
    [documentSelectorsPartial.getDocumentList, documentSelectorsPartial.getDocumentPageList],
    (documents, documentPages) => {
      const ret: DocumentPageDisplayInfo[] = []
      if (documents.length > 0) {
        documents.forEach((d) => {
          const concernedPages = documentPages.filter((p) => p.documentId === d.id)
          concernedPages.forEach((p) => {
            const documentPageDisplayInfo: DocumentPageDisplayInfo = {
              id: p.id,
              documentId: p.documentId!,
              src: p.thumbnailUrl,
              rotation: p.rotation,
              pageNumber: p.pageNumber,
              originalFilename: d.fileName!,
              documentType: d.typeId,
              documentStatus: d.status!,
              pageStatus: p.status!,
              applicantType: d.applicantType!,
              subKey: d.subKey,
            }
            ret.push(documentPageDisplayInfo)
          })
        })
      }

      return ret
    },
  ),
  getDocumentDecisions: createSelector(
    [documentSelectorsPartial.getDocumentList, (_state: TRootState, documentIds: string[]) => documentIds],
    (documents, documentIds) => {
      const decisions: DocumentDecision[] = []
      documents
        .filter((d) => documentIds.includes(d.id!))
        .forEach((d) => {
          if (d.typeId !== EDocumentType.VehicleValueEvaluation) {
            const documentDecision: DocumentDecision = {
              documentId: d.id!,
              documentType: d.typeId,
              status: d.status!,
              refusalReason: d.refusalReason ?? null,
              updatedBy: d.updatedByUserFullname ?? '',
              lastUpdate: d.updatedOn ? formatDateTime(d.updatedOn) : '',
              applicantType: d.applicantType ?? null,
              versionTag: d.versionTag!,
              subKey: d.subKey,
            }
            decisions.push(documentDecision)
          }
        })

      let ordered = decisions.filter((d) => d.status === EDocumentStatus.AwaitingApproval)
      ordered = ordered.concat(decisions.filter((d) => d.status !== EDocumentStatus.AwaitingApproval))

      const decisionIds = decisions.map((d) => d.documentId)
      if (documentIds?.length === 1 && decisionIds.includes(documentIds[0])) {
        const concernedDecision = decisions.find((d) => d.documentId === documentIds[0])
        const decisionsWithoutWantedOne = decisions.filter((d) => d.documentId !== documentIds[0])

        decisionsWithoutWantedOne.unshift(concernedDecision!)
        ordered = decisionsWithoutWantedOne
      }
      return ordered
    },
  ),
  getMultipleRequiredDocuments: createSelector(
    [documentSelectorsPartial.getDocumentRequestedList, appSelectors.getDocumentTypes],
    (requestedDocuments, documentTypes) => {
      const possibleMultipleTypes = Object.values(documentTypes)
        .filter((dt) => dt.canHaveMultiple)
        .map((dt) => dt.id)

      const requiredDocsThatAreInMultipleTypes = requestedDocuments.filter((rd) =>
        possibleMultipleTypes.includes(rd.typeId),
      )
      return requiredDocsThatAreInMultipleTypes
    },
  ),
  isAbleToSendFundDepositConfirmation: createSelector([documentSelectorsPartial.getDocumentList], (docs) => {
    const signedCVTApproved = docs.some(
      (x) => x.typeId === EDocumentType.SignedCVT && x.status === EDocumentStatus.Approved,
    )

    if (signedCVTApproved) {
      const auditCVTToValidate = docs.find((x) => x.typeId === EDocumentType.AuditCVT)
      if (
        !auditCVTToValidate ||
        auditCVTToValidate.status === EDocumentStatus.Approved ||
        auditCVTToValidate.status === EDocumentStatus.Incomplete ||
        auditCVTToValidate.status === EDocumentStatus.Refused
      )
        return true
    }
    return false
  }),

  getRequiredDocumentStatusFromMatchingDocuments: createSelector(
    [documentSelectorsPartial.getDocumentList, documentSelectorsPartial.getDocumentRequestedList],
    (docs, docsReq) => {
      const requiredDocumentStatuses: Record<string, EDocumentStatus | undefined> = {}
      docsReq.forEach((r) => {
        const concernedDocs = docs.filter(
          (d) => d.typeId === r.typeId && d.applicantType === r.applicantType && d.subKey === r.subKey,
        )
        requiredDocumentStatuses[String(r.id)] = getPrioritizedDocumentStatus(concernedDocs)
      })

      return requiredDocumentStatuses
    },
  ),
  getSortedDocumentsByStatus: createSelector([documentSelectorsPartial.getDocumentList], (docs) => {
    return [...docs].sort((a, b) => {
      const statusA = a.status ? statusOrder[a.status] || 0 : 0
      const statusB = b.status ? statusOrder[b.status] || 0 : 0

      return statusA - statusB
    })
  }),
  getSortedRequiredDocumentsByStatus: createSelector(
    [documentSelectorsPartial.getDocumentList, documentSelectorsPartial.getDocumentRequestedList],
    (docs, requiredDocs) => {
      const requiredDocumentStatuses: Record<string, EDocumentStatus> = {}

      requiredDocs.forEach((reqDoc) => {
        const docsForThisRequest = docs.filter(
          (doc) =>
            doc.typeId === reqDoc.typeId && doc.applicantType === reqDoc.applicantType && doc.subKey === reqDoc.subKey,
        )
        requiredDocumentStatuses[String(reqDoc.id)] =
          getPrioritizedDocumentStatus(docsForThisRequest) ?? EDocumentStatus.AwaitingDocument
      })

      return [...requiredDocs].sort((a, b) => {
        const aStatus = requiredDocumentStatuses[String(a.id)]
        const bStatus = requiredDocumentStatuses[String(b.id)]
        const statusA = statusOrder[aStatus]
        const statusB = statusOrder[bStatus]

        return statusA - statusB
      })
    },
  ),

  hasReceivedCvtDocument: createSelector([documentSelectorsPartial.getDocumentList], (docs) => {
    return docs.some(
      (x) =>
        x.typeId === EDocumentType.SignedCVT &&
        (x.status === EDocumentStatus.AwaitingApproval || x.status === EDocumentStatus.Approved),
    )
  }),
}
