import { FeatureSwitch } from '.'

class FeatureFlags {
  public readonly useIncludeInsuranceAllProvinces: boolean

  public readonly allowWeekendOrHolidayActivationDate: boolean

  public readonly useCreateLoanWorkflow: boolean

  constructor(featureSwitchDtos: FeatureSwitch[]) {
    const activatedFeatureSwitches = new Set(featureSwitchDtos.filter((x) => x.isActivated).map((x) => x.id))
    this.useIncludeInsuranceAllProvinces = activatedFeatureSwitches.has('UseIncludeInsuranceAllProvinces')
    this.allowWeekendOrHolidayActivationDate = activatedFeatureSwitches.has('UseAllowWeekendOrHolidayActivationDate')
    this.useCreateLoanWorkflow = activatedFeatureSwitches.has('UseCreateLoanWorkflow')
  }
}

export default FeatureFlags
