import axios from 'axios'

// No need to overcomplicate default api client configurations via redux and instances
// Axios is already a singleton and can be used as is
axios.defaults.baseURL = import.meta.env.VITE_BACKOFFICE_API_URL
axios.defaults.headers['X-requestor'] = `backofficeapp:${import.meta.env.VITE_VERSION}`

export const setApiClientAuthorization = (token: string) => {
  axios.defaults.headers.Authorization = `Bearer ${token}`
}

export const getApiClient = () => {
  return axios
}
export default axios
