import {
  Box,
  FormControlLabel,
  FormGroup,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import { pull, debounce } from 'lodash-es'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PendingActions as PendingActionsIcon,
  DocumentScanner as DocumentScannerIcon,
  RemoveCircle as RemoveCircleIcon,
  CheckCircle as CheckCircleIcon,
  FindInPage as FindInPageIcon,
  NotificationsActive as NotificationActiveIcon,
  HighlightOff,
} from '@mui/icons-material'
import { InputTextField } from '../../../components'
import {
  CreditApplication,
  DocumentRequired,
  EApplicantType,
  EDocumentStatus,
  EDocumentType,
} from '../../../data/types'
import { DocumentType } from '../../../data/types/DocumentType'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'
import { useAppSelector } from '../../../data/store'
import { documentSelectors } from '../../../data/store/Document'

interface Props {
  creditApp?: CreditApplication | null
  documentTypes: Record<string, DocumentType>
  lang: keyof TranslatedEnum
  editSelectedPagesDocType: (typeId: number, subKey: string, applicantTypeId: EApplicantType | undefined) => void
  creditAppRequiredDocs: DocumentRequired[]
  creditAppHasCoApplicant: boolean
  applicantType: EApplicantType
  setApplicantType: (data: EApplicantType) => void
  editDisabled: boolean
  multipleRequiredDocs: DocumentRequired[]
}

const DocumentTypeSelectionSection = ({
  creditApp,
  documentTypes,
  lang,
  editSelectedPagesDocType,
  creditAppRequiredDocs,
  creditAppHasCoApplicant,
  applicantType,
  setApplicantType,
  editDisabled,
  multipleRequiredDocs,
}: Props) => {
  const { t } = useTranslation()
  const [query, setQuery] = useState('')
  const [filteredDocumentTypes, setFilteredDocumentTypes] = React.useState<EDocumentType[]>([])
  const documentStatuses = useAppSelector(documentSelectors.getRequiredDocumentStatusFromMatchingDocuments)

  const updateQuery = (e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)
  const debounceOnChange = debounce(updateQuery, 200)

  React.useEffect(() => {
    const typeIds = Object.values(documentTypes).map((type) => type.id)
    const sourceRequiredDocumentTypeIds = pull(typeIds, EDocumentType.Other)

    const filteredRequiredDocumentTypeIds = sourceRequiredDocumentTypeIds
      .filter((docTypeId: number) =>
        (documentTypes[docTypeId][lang] as string).toLowerCase().includes(query.toLowerCase()),
      )
      .sort((a, b) => (documentTypes[a][lang] > documentTypes[b][lang] ? 1 : -1))

    setFilteredDocumentTypes(filteredRequiredDocumentTypeIds)
  }, [query, applicantType, documentTypes, lang])

  const getRequestedDocumentsOrOtherDocuments = () => {
    return filteredDocumentTypes.filter(
      (value) =>
        !creditAppRequiredDocs.some((x) => x.typeId === value) &&
        creditApp?.financingProgramId &&
        (documentTypes[value].restrictedToFinancingProgramIds.includes(creditApp.financingProgramId) ||
          documentTypes[value].restrictedToFinancingProgramIds.length === 0),
    )
  }

  const getRequiredDocName = (item: DocumentRequired) => {
    const concerned = multipleRequiredDocs.find(
      (mr) => mr.typeId === item.typeId && mr.subKey === item.subKey && mr.applicantType === item.applicantType,
    )
    return concerned ? `${documentTypes[item.typeId][lang]}-${concerned.labelSuffix}` : documentTypes[item.typeId][lang]
  }

  const getConcernedDocumentStatus = (request: DocumentRequired) => {
    return documentStatuses[String(request.id)]
  }

  const getRequestedDocuments = () => {
    return creditAppRequiredDocs.filter(
      (value) =>
        filteredDocumentTypes.includes(value.typeId) &&
        (value.applicantType === applicantType || value.applicantType === null),
    )
  }

  const RequestedDocuments = getRequestedDocuments()
  const OtherDocuments = getRequestedDocumentsOrOtherDocuments()

  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: '1rem',
        marginTop: -3,
      }}
    >
      <Box sx={{ pt: 2, pl: 2, pr: 2 }}>
        <InputTextField onChange={debounceOnChange} label={t('common.filter')} />
      </Box>

      <FormGroup row sx={{ pr: 2, pl: 2, pb: 2 }}>
        <RadioGroup
          row
          value={applicantType}
          onChange={(e) => {
            setApplicantType(e.target.value as EApplicantType)
          }}
        >
          <FormControlLabel
            value={EApplicantType.Applicant}
            control={<Radio checked={applicantType === EApplicantType.Applicant} />}
            label={t(`common.${EApplicantType.Applicant}`)}
            disabled={editDisabled}
          />
          <FormControlLabel
            value={EApplicantType.CoApplicant}
            control={<Radio checked={applicantType === EApplicantType.CoApplicant} />}
            label={t(`common.${EApplicantType.CoApplicant}`)}
            disabled={!creditAppHasCoApplicant || editDisabled}
          />
        </RadioGroup>
      </FormGroup>
      <List
        sx={{
          maxHeight: '37rem',
          position: 'relative',
          overflow: 'auto',
          width: '100%',
          scrollbarWidth: 'thin',
          '::-webkit-scrollbar': { width: 5, background: 'lightgray' },
          '::-webkit-scrollbar-thumb': {
            background: 'darkgray',
          },
          '& ul': { padding: 0 },
        }}
        subheader={<li />}
      >
        <li key="section-required">
          <ul>
            {RequestedDocuments.length !== 0 && (
              <>
                <ListSubheader>
                  <Typography variant="h6" gutterBottom component="div">
                    {t('document.requestedDocument')}
                  </Typography>
                </ListSubheader>
                {RequestedDocuments.map((item) => (
                  <ListItemButton
                    key={`section-required-${item.typeId}-${item.subKey}-${item.applicantType}`}
                    onClick={() =>
                      item.applicantType !== null
                        ? editSelectedPagesDocType(item.typeId, item.subKey!, item.applicantType)
                        : editSelectedPagesDocType(item.typeId, item.subKey!, undefined)
                    }
                  >
                    <ListItemIcon>
                      {!getConcernedDocumentStatus(item) && (
                        <Tooltip title={t('document.awaitingDocument')} arrow placement="top">
                          <PendingActionsIcon color="warning" />
                        </Tooltip>
                      )}
                      {getConcernedDocumentStatus(item) === EDocumentStatus.AwaitingScan && (
                        <Tooltip title={t('document.awaitingScan')} arrow placement="top">
                          <DocumentScannerIcon />
                        </Tooltip>
                      )}
                      {getConcernedDocumentStatus(item) === EDocumentStatus.AwaitingApproval && (
                        <Tooltip title={t('document.awaitingApproval')} arrow placement="top">
                          <FindInPageIcon color="primary" />
                        </Tooltip>
                      )}
                      {getConcernedDocumentStatus(item) === EDocumentStatus.Approved && (
                        <Tooltip title={t('document.approved')} arrow placement="top">
                          <CheckCircleIcon color="success" />
                        </Tooltip>
                      )}
                      {getConcernedDocumentStatus(item) === EDocumentStatus.Refused && (
                        <Tooltip title={t('document.declined')} arrow placement="top">
                          <RemoveCircleIcon color="error" />
                        </Tooltip>
                      )}
                      {getConcernedDocumentStatus(item) === EDocumentStatus.Incomplete && (
                        <Tooltip title={t('enum.eDocumentDecisionStatus.incomplete')} arrow placement="top">
                          <NotificationActiveIcon color="warning" />
                        </Tooltip>
                      )}
                      {getConcernedDocumentStatus(item) === EDocumentStatus.Deleted && (
                        <Tooltip title={t('enum.eDocumentDecisionStatus.deleted')} arrow placement="top">
                          <HighlightOff color="error" />
                        </Tooltip>
                      )}
                    </ListItemIcon>
                    <ListItemText primary={getRequiredDocName(item)} />
                  </ListItemButton>
                ))}
              </>
            )}

            <ListSubheader>
              <Typography variant="h6" gutterBottom component="div">
                {t('document.otherDocuments')}
              </Typography>
            </ListSubheader>

            {OtherDocuments.map((item) => (
              <ListItemButton
                key={`section-required-${item}`}
                onClick={() => editSelectedPagesDocType(item, '', applicantType)}
              >
                <ListItemText primary={documentTypes[item][lang]} />
              </ListItemButton>
            ))}
          </ul>
        </li>
      </List>
    </Paper>
  )
}

export default DocumentTypeSelectionSection
