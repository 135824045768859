import { Box, FormControlLabel, FormHelperText, Paper, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CreditReport, CreditReportTrade } from '../../../data/types/CreditReportSchema'

interface Props {
  trade: CreditReportTrade
  coApplicantCreditReport: CreditReport | null
  handleClickProofOfReleaseRequired: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: boolean | null
  selectionsInError: string[]
}

const ProofOfReleaseDetail = ({
  trade,
  coApplicantCreditReport,
  handleClickProofOfReleaseRequired,
  value,
  selectionsInError,
}: Props) => {
  const { t } = useTranslation()
  function IndividualOrJoin(): string {
    if (coApplicantCreditReport) {
      const tradeExists = coApplicantCreditReport?.trades.some((elm) => elm.accountNumber === trade.accountNumber)
      if (tradeExists) {
        return t('proofsOfRelease.jointWithCoApplicant')
      }
      return t('proofsOfRelease.jointWithoutCoApplicant')
    }

    return t('proofsOfRelease.individual')
  }
  const inError = selectionsInError.some((elm) => elm === trade.accountNumber)
  const name = trade.accountNumber ?? ''
  return (
    <Box>
      <Paper elevation={3} sx={{ borderRadius: 2, p: 3, mb: 6, md: 8 }}>
        <Stack marginTop={1} marginLeft={1} marginRight={20} direction="column" alignItems="flex-start">
          <Typography>
            {trade.accountNumber}-{trade.creditorName}
          </Typography>
          <Typography>{IndividualOrJoin()}</Typography>
          <Typography>
            {t('proofsOfRelease.leftToPay', '', {
              balanceAmount: trade.balanceAmount,
              highCreditAmount: trade.highCreditAmount,
            })}
          </Typography>
          <Typography>
            {trade.industryCode} - {t(`proofsOfRelease.industryCode.${trade.industryCode}`)}
          </Typography>
        </Stack>
        <Stack alignItems="flex-end" justifyContent="flex-end" marginTop={2} marginRight={1}>
          <RadioGroup name={name} onChange={handleClickProofOfReleaseRequired} row>
            <FormControlLabel value="yes" control={<Radio />} label={t('common.yes')} checked={value === true} />
            <FormControlLabel value="no" control={<Radio />} label={t('common.no')} checked={value === false} />
          </RadioGroup>
          {inError && <FormHelperText error>{t('common.errors.required')}</FormHelperText>}
        </Stack>
      </Paper>
    </Box>
  )
}

export default ProofOfReleaseDetail
