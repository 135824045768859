import React from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Menu, MenuItem } from '@mui/material'
import { Add, Calculate } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { EFinancingCompany, EFinancingProgram } from '@src/data/types'
import { getProgramsByCompanyId } from '@src/data/types/FinancingCompanies'
import IconButtonWithTooltip from '../../components/IconButtonWithTooltip'
import { appSelectors } from '../../data/store/AppStore'
import { TranslatedEnum } from '../../data/types/TranslatedEnum'
import { useAppSelector } from '../../data/store'
import { buildSelectValueListFromEnumWithTranslation } from '../../components/buildSelectValueList'
import { userSelectors } from '../../data/store/UserStore'

const MainActions = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const selectedCompany = useAppSelector(userSelectors.selectedCompany)
  const programsByCompanyId = getProgramsByCompanyId([selectedCompany] as EFinancingCompany[])
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum
  const financingProgramEnum = useAppSelector(appSelectors.getFinancingProgramEnum)
  const user = useAppSelector(userSelectors.getUser)

  const availableFinancingPrograms = buildSelectValueListFromEnumWithTranslation(
    financingProgramEnum,
    lang,
    programsByCompanyId,
    undefined,
    ['ifxpress', 'vlvr'],
  )
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleNewApplication = (financingProgramId: EFinancingProgram) => {
    navigate(`/Applications/${financingProgramId}/add`)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack direction="row">
      <IconButtonWithTooltip
        tooltip={t('browseCreditApplications.mainActions.newApplication')}
        icon={<Add color="primary" />}
        onClick={handleClick}
        disabled={!user?.rights.canEditCreditApp}
      />
      <IconButtonWithTooltip
        tooltip={t('browseCreditApplications.mainActions.calculate')}
        icon={<Calculate color="primary" />}
        onClick={() => undefined}
      />
      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {Object.values(availableFinancingPrograms).map((item) => (
          <MenuItem key={item.value} onClick={() => handleNewApplication(item.value as EFinancingProgram)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  )
}

export default MainActions
