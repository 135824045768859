import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AvailableCredit } from '@src/data/types'
import type { TRootState } from '..'

export const availableCreditSlice = createSlice({
  name: 'availableCredit',
  initialState: {
    availableCredit: null as AvailableCredit | null,
  },
  reducers: {
    setAvailableCredit: (state, action: PayloadAction<AvailableCredit | null>) => {
      state.availableCredit = action.payload
    },
  },
})

export default availableCreditSlice.reducer

export const availableCreditActions = availableCreditSlice.actions

export const GET_AVAILABLE_CREDIT = 'availableCredit/getAvailableCredit'

const availableCreditSelectorsPartial = {
  get: (state: TRootState) => state.availableCredit.availableCredit,
  getAvailableCredit: (state: TRootState) => state.availableCredit.availableCredit?.availableAmount ?? 0,
  getCreditInProgress: (state: TRootState) => state.availableCredit.availableCredit?.creditInProgress ?? 0,
}

export const availableCreditSelectors = {
  ...availableCreditSelectorsPartial,
}
