import React, { ReactElement } from 'react'
import { AvailableCredit, ECreditApplicationRelations } from '@src/data/types'
import { FormatCurrency } from '@src/services/Formatter'
import { useTranslation } from 'react-i18next'
import { Link, Tooltip, useTheme } from '@mui/material'

interface Props {
  availableCreditData: AvailableCredit | null
  children: ReactElement
}

const AvailableCreditTooltip = ({ availableCreditData, children }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isDarkMode = theme.palette.mode === 'dark'

  const tooltipContent = () => {
    if (!availableCreditData) return children || null

    const totalRefinancingAmount = availableCreditData.refinancingCreditApplications.reduce(
      (sum, application) => sum + application.worksheetAmountFinanced,
      0,
    )

    return (
      <div>
        <p>
          <strong>{t('worksheet.creditInProgressToolTip.summary')}</strong>
        </p>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ textAlign: 'left', padding: '4px' }}>
                {t('worksheet.creditInProgressToolTip.totalOnOtherWorksheet')}
              </th>
              <th style={{ textAlign: 'right', padding: '4px' }}>
                {FormatCurrency(availableCreditData.activeNotDisbursedWorksheetAmount)}
              </th>
            </tr>
          </thead>
        </table>
        {availableCreditData.refinancingCreditApplications.length > 0 && (
          <>
            <p>{t('worksheet.creditInProgressToolTip.summaryRefinancing')}</p>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left', padding: '4px' }}>
                    {t('worksheet.creditInProgressToolTip.referenceNumber')}
                  </th>
                  <th style={{ textAlign: 'right', padding: '4px' }}>
                    {t('worksheet.creditInProgressToolTip.amount')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {availableCreditData.refinancingCreditApplications.map((application) => (
                  <tr key={application.creditApplicationId}>
                    <td style={{ textAlign: 'left', padding: '4px' }}>
                      <Link
                        rel="noopener noreferrer"
                        target="_blank"
                        href={`/Applications/${application.financingProgramId}/${application.creditApplicationId}/view`}
                        sx={{ color: isDarkMode ? theme.palette.primary.main : theme.palette.primary.contrastText }}
                      >
                        {application.creditApplicationReferenceNumber}
                        {application.relationToCurrentCreditApplication === ECreditApplicationRelations.Parent
                          ? ' (P)'
                          : ''}
                      </Link>
                    </td>
                    <td style={{ textAlign: 'right', padding: '4px' }}>
                      {FormatCurrency(application.worksheetAmountFinanced)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <tbody>
            <tr>
              <td style={{ textAlign: 'left', padding: '4px' }}>
                {t('worksheet.creditInProgressToolTip.refinancingTotal')}
              </td>
              <td style={{ textAlign: 'right', padding: '4px' }}>{FormatCurrency(totalRefinancingAmount)}</td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left', padding: '4px' }}>
                {t('worksheet.creditInProgressToolTip.creditInProgressTotal')}
              </td>
              <td style={{ textAlign: 'right', padding: '4px' }}>
                {FormatCurrency(availableCreditData.creditInProgress)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
  return (
    <Tooltip title={tooltipContent()} arrow placement="bottom-end">
      <span style={{ display: 'inline-block', width: '100%' }}>{children}</span>
    </Tooltip>
  )
}
export default React.memo(AvailableCreditTooltip)
