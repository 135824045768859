import ArticleIcon from '@mui/icons-material/Article'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import SummarizeIcon from '@mui/icons-material/Summarize'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import { IconButton, Tooltip, Typography } from '@mui/material'
import { green, yellow } from '@mui/material/colors'
import { useTranslation } from 'react-i18next'
import IconButtonWithTooltip from '../../components/IconButtonWithTooltip'
import { DocumentRequired, EWorksheetDecision } from '../../data/types'
import { DocumentType } from '../../data/types/DocumentType'
import { TranslatedEnum } from '../../data/types/TranslatedEnum'
import { formatDateTime } from '../../services/Formatter'

interface Props {
  id: string
  worksheetDecision: EWorksheetDecision
  hasDocumentsPendingReview: boolean
  incomeConfirmed: boolean
  missingRequiredDocuments: DocumentRequired[]
  reservedByUserId: string
  reservedByUserName: string
  reservedUntil: Date
  missingDocumentsNames: Record<string, DocumentType>
  applicantTypes: Record<string, TranslatedEnum>
  lang: keyof TranslatedEnum
}
const InlineActions = ({
  id,
  worksheetDecision,
  hasDocumentsPendingReview,
  incomeConfirmed,
  missingRequiredDocuments,
  reservedByUserId,
  reservedByUserName,
  reservedUntil,
  missingDocumentsNames,
  applicantTypes,
  lang,
}: Props): JSX.Element | null => {
  const { t } = useTranslation()

  const workSheetDecisionColor = () => {
    if (worksheetDecision === EWorksheetDecision.ManualRevision || worksheetDecision === EWorksheetDecision.Pending) {
      return yellow[600]
    }
    if (worksheetDecision === EWorksheetDecision.Approved) {
      return green[500]
    }
    return undefined
  }

  const visibility = (elementIsVisible: boolean) => {
    return elementIsVisible ? 'visible' : 'hidden'
  }

  const isNotExpired = () => {
    return reservedUntil && formatDateTime(new Date()) <= formatDateTime(reservedUntil)
  }

  const documentListItems = missingRequiredDocuments.map((item) => (
    <li key={item.id}>
      {missingDocumentsNames[item.typeId][lang]}
      {item.labelSuffix ? `-${item.labelSuffix}` : ''}{' '}
      {item.applicantType ? `(${applicantTypes[item.applicantType][lang]})` : ''}
    </li>
  ))

  const documentsTooltip = () => {
    return (
      <>
        <Typography>{`${t('inlineActions.missingDocuments')} :`}</Typography>
        <ul>{documentListItems}</ul>
      </>
    )
  }

  return (
    <div key={id}>
      <Tooltip
        title={documentsTooltip()}
        arrow
        placement="top"
        disableHoverListener={visibility(missingRequiredDocuments.length > 0) === 'hidden'}
      >
        <span>
          <IconButton sx={{ visibility: visibility(missingRequiredDocuments.length > 0) }}>
            <ArticleIcon />
          </IconButton>
        </span>
      </Tooltip>
      <IconButtonWithTooltip
        tooltip={t('inlineActions.documentPendingReview')}
        icon={<FindInPageIcon />}
        visibility={visibility(hasDocumentsPendingReview)}
        color={yellow[600]}
      />
      <IconButtonWithTooltip
        tooltip={t('inlineActions.icomeConfirmed')}
        icon={<AttachMoneyOutlinedIcon />}
        visibility={visibility(incomeConfirmed)}
        color={green[500]}
      />
      <IconButtonWithTooltip
        tooltip={t('inlineActions.workSheetDecision')}
        icon={<SummarizeIcon />}
        visibility={visibility(worksheetDecision !== null)}
        color={workSheetDecisionColor()}
      />
      <IconButtonWithTooltip
        tooltip={t('inlineActions.requestAssigned', '', {
          name: reservedByUserName,
          id: reservedByUserId,
        })}
        icon={<SupportAgentIcon />}
        visibility={visibility(reservedByUserId !== null && isNotExpired())}
      />
    </div>
  )
}

export default InlineActions
