import { EventType, InteractionType, PublicClientApplication, RedirectRequest } from '@azure/msal-browser'
import { msalConfig, protectedResources } from '../authConfig'
import api from '../data/api'
import { JwtToken } from '../data/api/user/user-api'
import { store } from '../data/store'
import { userActions } from '../data/store/UserStore'
import { getApiClient } from './api-client'

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

function saveUserToStorage(token: string): void {
  localStorage.setItem('jwtToken', token)
}

const apiClient = getApiClient()

export default function initializeMsal(): PublicClientApplication {
  // Listen for sign-in event and set active account
  msalInstance.addEventCallback(async (event) => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS ||
      (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.interactionType === InteractionType.Redirect)
    ) {
      const { account } = event.payload as RedirectRequest
      if (account) {
        msalInstance.setActiveAccount(account)

        const responseToken = await msalInstance.acquireTokenSilent({
          account,
          scopes: protectedResources.getTokenAzure.scopes.read,
        })

        const token: JwtToken = { jwt: responseToken.accessToken }
        const responseIceberg = await api.user.loginWithAzureAd(apiClient, token)

        saveUserToStorage(responseIceberg.jwt)
        store.dispatch(userActions.setMustAuthenticate(false))
      }
    }
  })

  return msalInstance
}
