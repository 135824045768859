import { Outlet } from 'react-router-dom'
import { useAppSelector } from '../../data/store'
import { userSelectors } from '../../data/store/UserStore'
import { UserRight } from '../../data/types'
import NotAuthorizedPage from './NotAuthorizedPage'

interface Props {
  requiredPermission: UserRight
}

const RouteGuard = ({ requiredPermission }: Props) => {
  const user = useAppSelector(userSelectors.getUser)
  return user?.rights[requiredPermission] === true ? <Outlet /> : <NotAuthorizedPage />
}

export default RouteGuard
