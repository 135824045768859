import { EastSharp } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { MerchantPaymentPlan } from '../../../data/types/MerchantPaymentPlan'

interface Props {
  merchantPaymentPlan: MerchantPaymentPlan
}

const FormattedMerchantPaymentPlan = ({ merchantPaymentPlan }: Props) => {
  return (
    <Stack direction="row">
      {`${merchantPaymentPlan?.reducedInterestRate}\u00a0/\u00a0${merchantPaymentPlan?.reducedRateDurationInMonths} `}
      <EastSharp sx={{ marginX: '1rem' }} />
      {`${merchantPaymentPlan?.interestRate}%\u00a0/\u00a0${
        merchantPaymentPlan.loanTerm - merchantPaymentPlan.reducedRateDurationInMonths
      }`}
    </Stack>
  )
}

export default FormattedMerchantPaymentPlan
