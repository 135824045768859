import { createTheme, CssBaseline, ThemeProvider as MUIThemeProvider, Theme, ThemeOptions } from '@mui/material'
import { blue } from '@mui/material/colors'
import { frFR as coreFr } from '@mui/material/locale'
import { frFR as dataGridFR } from '@mui/x-data-grid/locales'
import type {} from '@mui/x-data-grid/themeAugmentation'
import { frFR as datePickerFr } from '@mui/x-date-pickers/locales'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import { useAppSelector } from '@src/data/store'
import { userSelectors } from '@src/data/store/UserStore'
import { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

const LinkComponent = forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />
  },
)
LinkComponent.displayName = 'LinkComponent'

const GLOBAL_OVERRIDES: ThemeOptions = {
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkComponent,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent,
      },
    },
    MuiListItemButton: {
      defaultProps: {
        LinkComponent,
      },
    },
    MuiIconButton: {
      defaultProps: {
        LinkComponent,
      },
    },
    MuiButton: {
      defaultProps: {
        LinkComponent,
      },
    },
    MuiInputBase: {
      defaultProps: {
        // Needed to prevent adding a global style for every input field
        disableInjectingGlobalStyles: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '0.6rem',
        },
      },
    },
  },
}

// Color picking documentation https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
// Custom color: https://mui.com/material-ui/customization/palette/#custom-colors
// Generating token color: https://mui.com/material-ui/customization/color/#playground
export const createCustomTheme = (isDark: boolean, language?: string): Theme => {
  return createTheme(
    {
      ...GLOBAL_OVERRIDES,
      palette: {
        primary: { main: isDark ? blue[200] : blue[700] },
        background: {
          default: isDark ? '#1e1e1e' : '#ffffff',
        },
        mode: isDark ? 'dark' : 'light',
      },
    },
    ...(language === 'fr' ? [coreFr, dataGridFR, datePickerFr] : []),
  )
}

interface Props {
  children: JSX.Element
}

const ThemeProvider = ({ children }: Props) => {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation()
  const isDarkMode = useAppSelector(userSelectors.isDarkModeEnabled)
  const customTheme = useMemo(() => createCustomTheme(isDarkMode, resolvedLanguage), [isDarkMode, resolvedLanguage])

  return (
    <MUIThemeProvider theme={customTheme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  )
}

export default ThemeProvider
