import { Container } from '@mui/material'

const NotFoundPage = (): JSX.Element => {
  return (
    <Container maxWidth="xl" disableGutters>
      There is nothing here!
    </Container>
  )
}

export default NotFoundPage
