import { yupResolver } from '@hookform/resolvers/yup'
import { ActionsDialog, PageError } from '@src/components'
import ApplicantExpensesSection from '@src/containers/EditCreditApplicationPage/components/CreditApplicationForm/components/Applicant/components/applicantExpenseComponent'
import { useAppSelector } from '@src/data/store'
import { appSelectors } from '@src/data/store/AppStore'
import { Applicant, buildDraftCreditApplicationSchema, EFinancingProgram } from '@src/data/types'
import { reportErrorToConsole } from '@src/services/error-logger'
import { memo, useEffect } from 'react'
import { Resolver, useForm } from 'react-hook-form'

interface Props {
  applicant: Applicant | undefined
  open: boolean
  title: string
  onConfirm: (data: Applicant) => void
  onCancel: () => void
  financingProgramId: EFinancingProgram
}

const EditExpenseDialog = ({ applicant, onConfirm, onCancel, open, title, financingProgramId }: Props) => {
  const error = useAppSelector(appSelectors.getBusinessError)
  const {
    register,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Applicant>({
    mode: 'onBlur',
    defaultValues: applicant,
    // Forcing type to be backward compatible with the old schema (should be removed in the future)
    resolver: yupResolver(buildDraftCreditApplicationSchema()) as unknown as Resolver<Applicant, unknown, Applicant>,
  })

  useEffect(() => {
    reset(applicant)
  }, [applicant, reset])

  return (
    <ActionsDialog
      title={title}
      onCancel={onCancel}
      onConfirm={handleSubmit(onConfirm, reportErrorToConsole)}
      open={open}
    >
      <PageError errors={error} />
      <ApplicantExpensesSection
        name="expenses"
        register={register}
        watch={watch}
        setValue={setValue}
        errors={errors.expenses}
        showDetailedForm
        financingProgramId={financingProgramId}
        editDisabled
      />
    </ActionsDialog>
  )
}
export default memo(EditExpenseDialog)
