import { Edit as EditIcon } from '@mui/icons-material'
import CarRepairIcon from '@mui/icons-material/CarRepair'
import { Box, Button, ButtonGroup, Divider, Link, Paper, Stack, Typography } from '@mui/material'
import autoRslaWorksheetEffects from '@src/data/store/AutoRslaWorksheet/auto-rsla-worksheet-effects'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import {
  Constants,
  CreditApplication,
  EOriginSystemId,
  EPaymentFrequency,
  EPaymentPlan,
  ETermMax,
  EWorksheetStatus,
  User,
  VehicleSchema,
} from '@src/data/types'
import { AutoRslaWorksheet } from '@src/data/types/AutoRslaWorksheet'
import { reportErrorToConsole } from '@src/services/error-logger'
import { FormatCurrency, formatDate } from '@src/services/Formatter'
import { addMonths } from 'date-fns'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface Props {
  creditApplication: CreditApplication
  worksheet: AutoRslaWorksheet
  user: User | null
  editDisabled: boolean
}

const AutoRslaWorksheetInformationSection = ({ worksheet, creditApplication, user, editDisabled }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatchEffect = useSideEffect()

  const editWorksheetDisabled =
    creditApplication?.originSystemId !== EOriginSystemId.Backoffice || !user?.rights.canEditWorksheet || editDisabled
  // event handlers
  const createWorksheet = useCallback(() => {
    const route = `/Applications/auto-rsla/${creditApplication.id}/worksheet/edit`

    if (worksheet === null) {
      const newWorksheet = {
        id: creditApplication.id,
        status: EWorksheetStatus.Draft,
        vehicle: VehicleSchema.getDefault(),
        amountRequested: creditApplication.requestedLoanAmount,
        deliveryOn: formatDate(new Date()),
        firstPaymentOn: addMonths(new Date(), 1),
        paymentFrequency: EPaymentFrequency.Monthly,
        paymentAmountForFrequency: 0,
        term: ETermMax.thirtySix,
        includeInsurance: false,
        addFastPayments: false,
        versionTag: undefined,
        fundConfirmationNote: null,
        lienAmountFee: Constants.financingProgramConfigs[creditApplication.financingProgramId].lienAmountFee,
        brokerAdminFee: 0,
        roadsideAssistanceAmount: 0,
        merchantReferralFee: 0,
        paymentPlanId: EPaymentPlan.regularDailyInterests,
        creditApplicationId: creditApplication.id,
        insuranceFee: 0,
      }
      dispatchEffect(autoRslaWorksheetEffects.create(newWorksheet))
        .then(() => navigate(route))
        .catch(reportErrorToConsole)
    } else {
      navigate(route)
    }
  }, [worksheet, creditApplication, dispatchEffect, navigate])
  return (
    <Paper elevation={3} sx={{ borderRadius: 2, p: 2, mb: 3 }}>
      <Box sx={{ m: 1 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={2}>
            <ButtonGroup variant="outlined" size="large">
              <Button id="btnEditWorksheet" component={Link} variant="outlined" disabled>
                {t('worksheet.financing')}
              </Button>
              <Button onClick={createWorksheet} disabled={editWorksheetDisabled} size="small">
                <EditIcon fontSize="large" />
              </Button>
            </ButtonGroup>
            <CarRepairIcon fontSize="large" color="primary" />
          </Stack>
        </Stack>
        <Divider />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          alignItems="top"
          marginTop={1}
          justifyContent="space-evenly"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <span>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.vin')} : {worksheet?.vehicle?.vin}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.vehicle')} : {worksheet?.vehicle?.make} {worksheet?.vehicle?.model}{' '}
              {worksheet?.vehicle?.series} {worksheet?.vehicle?.year}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.mileage')} : {worksheet?.vehicle?.mileage}{' '}
              {worksheet?.vehicle?.mileage !== undefined && 'KM'}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              CBB :
              {worksheet?.vehicle?.thirdPartyEvaluationValue
                ? FormatCurrency(worksheet?.vehicle?.thirdPartyEvaluationValue)
                : 'N/A'}
            </Typography>
            <Typography variant="subtitle1">
              {t('worksheet.merchantEstimatedValue')} :{' '}
              {worksheet?.vehicle?.brokerEstimatedValue
                ? FormatCurrency(worksheet?.vehicle?.brokerEstimatedValue)
                : 'N/A'}
            </Typography>
          </span>
          <span>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.paymentFrequency')} : {worksheet && t(`worksheet.${worksheet?.paymentFrequency}`)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.term')} : {worksheet?.term} {worksheet?.term !== undefined && t('worksheet.month')}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.paymentAmount')} : {FormatCurrency(worksheet?.paymentAmountForFrequency)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.plan')} :{' '}
              {worksheet?.paymentPlanId ? t(`enum.ePaymentPlan.${worksheet?.paymentPlanId}`) : ''}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.deliveryOn')} : {formatDate(worksheet?.deliveryOn)}
            </Typography>
          </span>
          <span>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheetCommon.amountRequested')} : {FormatCurrency(worksheet?.amountRequested)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.brokerAdminFee')} : {FormatCurrency(worksheet?.brokerAdminFee)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.ppsa')} : {FormatCurrency(worksheet?.lienAmountFee)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.roadside')} : {FormatCurrency(worksheet?.roadsideAssistanceAmount)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.merchantReferralFee')} : {FormatCurrency(worksheet?.merchantReferralFee)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.insurance')} : {FormatCurrency(worksheet?.insuranceFee)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.totalAmountFinanced')} : {FormatCurrency(worksheet?.totalAmountFinanced)}
            </Typography>
          </span>
        </Stack>
      </Box>
    </Paper>
  )
}

export default AutoRslaWorksheetInformationSection
