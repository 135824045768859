import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Box, Button, Divider, Grid, InputAdornment, Stack, Typography } from '@mui/material'
import { Breadcrumb, InputTextField, PageError, PageSpinner } from '@src/components'
import { getEmptyProductsComputeResponse } from '@src/containers/ViewWorksheet/viewWorksheet-selectors'
import { useAppDispatch, useAppSelector } from '@src/data/store'
import { appSelectors } from '@src/data/store/AppStore'
import { creditSelectors } from '@src/data/store/CreditApplication'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { fullCreditApplicationEffects } from '@src/data/store/FullCreditApplication'
import { productsWorksheetActions, productsWorkSheetSelectors } from '@src/data/store/ProductsWorksheet'
import productsWorksheetEffects from '@src/data/store/ProductsWorksheet/products-worksheet-effects'
import { Constants, EFinancingProgram, EWorksheetStatus } from '@src/data/types'
import { ProductsComputeRequestDto } from '@src/data/types/ProductsComputeRequestDto'
import { ProductsProvider } from '@src/data/types/ProductsProvider'
import { ProductsWorksheetDto, productsWorksheetSchema } from '@src/data/types/ProductsWorksheetSchema'
import { reportErrorToConsole } from '@src/services/error-logger'
import { formatDate } from '@src/services/Formatter'
import { debounce } from 'lodash-es'
import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { usePaymentPlanList } from '../editWorksheet-hooks'
import ProductsInsurances from './Components/ProductsInsurances'
import ProductsLoanFundingSummary from './Components/ProductsLoanFundingSummary'
import WorksheetDetails from './Components/WorksheetDetails'
import { getProductsMinMaxPaymentDates, isValidFirstPayment } from './productWorksheet-selectors'

const EditProductsWorksheetPage = () => {
  const merchant = useAppSelector(creditSelectors.getMerchant)!
  const worksheet = useAppSelector(productsWorkSheetSelectors.getCurrent)!
  const creditApplication = useAppSelector(creditSelectors.getCurrent)!
  const defaultValues = worksheet ?? productsWorksheetSchema.getDefault()

  const {
    control,
    register,
    setValue,
    reset,
    watch,
    getValues,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<ProductsWorksheetDto>({
    mode: 'onBlur',
    defaultValues: defaultValues as ProductsWorksheetDto,
    resolver: yupResolver(productsWorksheetSchema),
  })

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const dispatchEffect = useSideEffect()
  const { t } = useTranslation()
  const productsProviders: ProductsProvider[] = useAppSelector(appSelectors.getProductProviders)
  const [amountRequested, paymentFrequency, term, firstPaymentOn, deliveryOn] = watch([
    'amountRequested',
    'paymentFrequency',
    'term',
    'firstPaymentOn',
    'deliveryOn',
  ])
  const isComputingProductsLoan = useAppSelector(productsWorkSheetSelectors.isComputingProductsLoan)
  const computedInfo = useAppSelector(productsWorkSheetSelectors.getComputedInfo)
  const isLoadingCreditApplication = useAppSelector(creditSelectors.isLoadingCreditApplication)
  const error = useAppSelector(appSelectors.getBusinessError)

  const extendedWarrantyProviders = useMemo(() => {
    const ret = productsProviders
      .filter((x) => merchant?.warrantyProviderIds?.includes(x.id))
      .map((provider) => ({
        label: provider.name,
        value: provider.id,
      }))
    ret.push({ label: '', value: '' })
    return ret
  }, [merchant?.warrantyProviderIds, productsProviders])

  const insuranceProviders = useMemo(() => {
    const ret = productsProviders
      .filter((x) => merchant?.insuranceProviderIds?.includes(x.id))
      .map((provider) => ({
        label: provider.name,
        value: provider.id,
      }))
    ret.push({ label: '', value: '' })
    return ret
  }, [merchant?.insuranceProviderIds, productsProviders])

  const computeFunding = useCallback(
    (computeDto: ProductsComputeRequestDto) => {
      return dispatchEffect(productsWorksheetEffects.computeProductsLoan(computeDto))
    },
    [dispatchEffect],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceComputeFunding = useCallback(debounce(computeFunding, 500), [computeFunding])

  useEffect(() => {
    if (
      [amountRequested, paymentFrequency, term, deliveryOn, firstPaymentOn].every((x) => !!x) &&
      (amountRequested ?? 0) > 0 &&
      isValidFirstPayment(deliveryOn, firstPaymentOn)
    ) {
      const calculationData = {
        stateIso: creditApplication?.applicant.currentAddress.stateIso,
        amountRequested,
        frequency: paymentFrequency,
        term,
        firstPaymentDate: firstPaymentOn,
        deliveryOn: new Date(deliveryOn),
      } as unknown as ProductsComputeRequestDto
      if (amountRequested > 0) debounceComputeFunding(calculationData)?.catch(reportErrorToConsole)
      else {
        const emptyComputedDto = getEmptyProductsComputeResponse()
        dispatch(productsWorksheetActions.setLoanComputed(emptyComputedDto))
      }
    }
  }, [
    creditApplication,
    amountRequested,
    paymentFrequency,
    term,
    debounceComputeFunding,
    dispatch,
    firstPaymentOn,
    deliveryOn,
  ])

  const vehicleFinancingTerm = watch('vehicleFinancingTerm')

  useEffect(() => {
    void trigger(['creditInsurance', 'extendedWarranty', 'replacementOrGapInsurance'])
  }, [trigger, vehicleFinancingTerm])

  // reset form on worksheet changed
  useEffect(() => {
    if (worksheet && merchant) {
      reset(worksheet as ProductsWorksheetDto)
      const date = new Date()
      date.setDate(date.getDate() + 2)
      setValue('firstPaymentOn', worksheet.firstPaymentOn ?? date)
    }
  }, [merchant, reset, setValue, worksheet])

  const paymentPlanList = usePaymentPlanList(creditApplication?.createdOn, merchant?.paymentPlans)
  useEffect(() => {
    const firstDate =
      worksheet?.firstPaymentOn ?? formatDate(getProductsMinMaxPaymentDates(deliveryOn, paymentFrequency)[0])
    setValue('firstPaymentOn', firstDate, { shouldValidate: true })
  }, [deliveryOn, paymentFrequency, setValue, worksheet])

  const [creditInsuranceTerm, replacementOrGapInsuranceTerm, extendedWarrantyTerm] = watch([
    'creditInsurance.term',
    'replacementOrGapInsurance.term',
    'extendedWarranty.term',
  ])

  const maxTermDuration = useMemo(() => {
    let value = creditApplication?.finalCreditDecision.maxTermDuration ?? 0
    const terms = [creditInsuranceTerm, replacementOrGapInsuranceTerm, extendedWarrantyTerm]

    terms.forEach((e) => {
      if (e && (e < value || value === 0)) value = e
    })
    return value
  }, [creditApplication, creditInsuranceTerm, extendedWarrantyTerm, replacementOrGapInsuranceTerm])

  const breadCrumbs = useMemo(
    () => [
      { path: '/', label: t('breadcrumbs.home') },
      { path: '/Applications/browse', label: t('breadcrumbs.creditApplication') },
      {
        path: `/Applications/${EFinancingProgram.Products}/${creditApplication?.id}/view`,
        label: t('breadcrumbs.application').concat(` #${creditApplication?.referenceNumber}`),
      },
      { path: '#', label: t('worksheet.financing') },
    ],
    [creditApplication, t],
  )

  const onSubmit = async (data: ProductsWorksheetDto) => {
    data.status = EWorksheetStatus.Active
    await dispatchEffect(productsWorksheetEffects.update(data)).then(() =>
      dispatchEffect(fullCreditApplicationEffects.getById(creditApplication.id, EFinancingProgram.Products)).then(() =>
        navigate(`/Applications/${EFinancingProgram.Products}/${creditApplication.id}/view`, { replace: true }),
      ),
    )
  }

  return (
    <div>
      <PageSpinner withBackdrop isLoading={isLoadingCreditApplication} />
      <Stack>
        <PageError errors={error} />
      </Stack>
      {creditApplication && (
        <form onSubmit={handleSubmit(onSubmit, reportErrorToConsole)}>
          <Stack direction={{ md: 'column', xs: 'column-reverse' }} spacing={5} justifyContent="space-between">
            <Stack
              direction={{ sm: 'row', xs: 'column' }}
              spacing={2}
              justifyContent="space-between"
              alignItems={{ sm: 'self-end', xs: 'self-start' }}
            >
              <Stack direction="column" justifyContent="space-between" spacing={2}>
                <Breadcrumb trees={breadCrumbs} />
              </Stack>
              <Stack direction="row" spacing={2} display="flex">
                <Button fullWidth={false} variant="contained" onClick={() => navigate(-1)}>
                  {t('common.previous')}
                </Button>
                <Button fullWidth={false} variant="contained" type="submit">
                  {t('common.save')}
                </Button>
              </Stack>
            </Stack>
            <Stack direction={{ md: 'row', xs: 'column-reverse' }} spacing={3} justifyContent="space-between">
              <Box sx={{ flex: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body1" component={Alert} severity="info">
                      {t('worksheet.optional', { amount: Constants.MinimumProductsLoanAmount })}
                      <Divider />
                      {t('worksheet.maxTerm', {
                        maxTermDuration: creditApplication?.finalCreditDecision.maxTermDuration,
                      })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12} sm={6} md={4}>
                      <InputTextField
                        label={t('worksheet.vehicleFinancingTerm')}
                        {...register('vehicleFinancingTerm')}
                        error={errors.vehicleFinancingTerm}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{t('worksheet.monthLowerCase')}</InputAdornment>,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <ProductsInsurances
                      errors={errors}
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      getValues={getValues}
                      trigger={trigger}
                      stateIso={creditApplication.applicant.currentAddress.stateIso!}
                      insurancesProviders={insuranceProviders}
                      warrantyProviders={extendedWarrantyProviders}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">{t('worksheet.finance')}</Typography>
                    <WorksheetDetails
                      disabledAmountInput
                      control={control}
                      watch={watch}
                      register={register}
                      errors={errors}
                      creditApplication={creditApplication}
                      merchantPayments={paymentPlanList}
                      maxTermDuration={maxTermDuration}
                      worksheet={worksheet}
                      setValue={setValue}
                      getValues={getValues}
                    />
                  </Grid>
                </Grid>
              </Box>
              <ProductsLoanFundingSummary
                computedInfo={computedInfo}
                isFetching={isComputingProductsLoan}
                getValues={getValues}
                maxPmtAmount={creditApplication?.finalCreditDecision.maxPmtAmount}
              />
            </Stack>
          </Stack>
        </form>
      )}
    </div>
  )
}

export default EditProductsWorksheetPage
