import yupExtInt from './common/SchemaTypes'
import * as yup from './common/yup-extended'
import {
  EFinancingRefusalApproveMessage,
  EFinancingRefusalApproveMessageList,
  EPaymentFrequency,
  EPaymentFrequencyList,
  EWorksheetDecision,
  EWorksheetStatus,
  EWorksheetStatusList,
} from './constants'
import { MerchantPayment } from './MerchantPayment'
import { ParameterizedMessage } from './ParameterizedMessage'

export interface ApplicableTax {
  taxId: string
  percentage: number
}

export const computedTaxSchema = yup.default.object({
  taxId: yup.default.string(),
  value: yupExtInt.double.nullable(),
})

type ETax = 'gst' | 'pst' | 'hst' | 'qst'

export interface ComputedTax {
  taxId: ETax
  value: number
}

export const baseWorksheetFundingThresholdExtendSchema = yup.default.object({
  maxTermDuration: yupExtInt.double.default(0).nullable(),
  maxPmtAmount: yupExtInt.double.default(0).nullable(),
  maxTotalAmountFinanced: yupExtInt.double.default(0).nullable(),
})

export type BaseWorksheetFundingThresholdExtend = yup.default.InferType<
  typeof baseWorksheetFundingThresholdExtendSchema
>

export const worksheetDecisionSchema = yup.default.object({
  id: yup.default.string().nullable(),
  otherReason: yup.default.string().default('').nullable(),
  refusalMessages: yup.default.mixed<ParameterizedMessage[]>().default([]),
  warningMessages: yup.default.mixed<ParameterizedMessage[]>().default([]),
  refusalReasonCodes: yup.default.array(yup.default.string().required()),
  cantApproveReasonCodes: yup.default
    .array(yup.default.mixed<EFinancingRefusalApproveMessage>().required().oneOf(EFinancingRefusalApproveMessageList))
    .default([]),
  maxTermDuration: yupExtInt.double.default(0),
  maxPmtAmount: yupExtInt.double.default(0),
  maxTotalAmountFinanced: yupExtInt.double.default(0),
})

export type WorksheetDecision = yup.default.InferType<typeof worksheetDecisionSchema> & {
  decidorFullName: string | null
  decidorId: string | null
  decision: EWorksheetDecision
}
export const baseWorksheetSchema = yup.default.object({
  status: yup.default.mixed<EWorksheetStatus>().oneOf(EWorksheetStatusList).required().default(EWorksheetStatus.Draft),
  lenderFee: yupExtInt.integer.positive(),
  term: yupExtInt.integer.min(6).required(),
  paymentFrequency: yup.default.mixed<EPaymentFrequency>().required().oneOf(EPaymentFrequencyList),
  deliveryOn: yup.default.string().required('common.errors.required'),
  merchantPayments: yup.default.mixed<MerchantPayment[]>().default([]),
  firstPaymentOn: yup.default
    .date()
    .default(new Date())
    .required('common.errors.required')
    .typeError('common.errors.required'),
})

export type BaseWorksheet = yup.default.InferType<typeof baseWorksheetSchema> & {
  id: string
  lastPaymentOn: string
  creditApplicationId: string

  finalDecision: WorksheetDecision
  normsDecision: WorksheetDecision
  fundingThresholdExtend: BaseWorksheetFundingThresholdExtend | null

  totalAmountFinanced: number
  effectiveRate: number
  financingPlan: string
  paymentAmountForFrequency: number
  lenderFeeRate: number

  createdOn: Date
  createdByUserId: string
  createdByUserFullname: string
  updatedByUserId: string
  updatedByUserFullname: string
  updatedOn: Date
  versionTag: string
}
