import React, { useState } from 'react'
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from '@mui/material'
import {
  Add as AddIcon,
  Send as SendIcon,
  NotificationsActive as NotificationActiveIcon,
  FindInPage as ReviewDocumentsIcon,
  Merge,
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash-es'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { reportErrorToConsole } from '@src/services/error-logger'
import DocumentsRequestDialog from './documentsRequestDialog'
import { useAppSelector } from '../../../data/store'
import { documentEffects, documentSelectors } from '../../../data/store/Document'
import { Document, DocumentRequired } from '../../../data/types/DocumentSchema'
import SendDocumentsDialog from './sendDocumentsDialog'
import DeleteDocumentRequestDialog from './DeleteDocumentRequestDialog'
import { EDocumentStatus, EFinancingProgram, User } from '../../../data/types'
import EditDocumentDialog from './EditDocumentDialog'
import DocumentTableRow, { DocumentDialog } from './documentTableRow'
import { messageEffects, messageSelectors } from '../../../data/store/Message'

interface Props {
  creditApplicationId: string
  financingProgramId: EFinancingProgram
  editDisabled: boolean
  user: User | null
}

const DocumentList = ({ creditApplicationId, financingProgramId, editDisabled, user }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatchEffect = useSideEffect()
  const [openDialog, setOpenDialog] = React.useState<DocumentDialog>(DocumentDialog.None)
  const [editRequiredDocument, setEditRequiredDocument] = React.useState<DocumentRequired>()
  const [requiredDocumentToDelete, setRequiredDocumentToDelete] = React.useState<DocumentRequired | null>()
  const [selectedDocumentIds, setSelectedDocumentIds] = React.useState<string[]>([])
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [fileList, setFileList] = React.useState<File[]>([])

  const closeDialog = React.useCallback(() => setOpenDialog(DocumentDialog.None), [setOpenDialog])

  const documents = useAppSelector(documentSelectors.getSortedDocumentsByStatus)
  const isCreatingRequiredDocument = useAppSelector(documentSelectors.isCreatingDocumentRequest)
  const documentsRequested = useAppSelector(documentSelectors.getSortedRequiredDocumentsByStatus)
  const areAllDocumentsRequiredApproved = useAppSelector(documentSelectors.areAllDocumentsRequiredApproved)
  const isLoadingDocumentsRequest = useAppSelector(documentSelectors.isLoadingDocumentsRequest)
  const isLoadingDocuments = useAppSelector(documentSelectors.isLoadingDocuments)
  const isQuickMerging = useAppSelector(documentSelectors.isQuickMerging)
  const isSendingRequiredDocumentsMessage = useAppSelector(messageSelectors.isSendingRequiredDocumentsMessage)
  const isLoading = isLoadingDocumentsRequest || isLoadingDocuments

  const handleUpdateDocumentRequest = React.useCallback(
    async (data: DocumentRequired) => {
      await dispatchEffect(documentEffects.updateRequireDocument(data))
      closeDialog()
    },
    [closeDialog, dispatchEffect],
  )

  const handleDocumentChecked = (documentId: string) => (e: { target: { checked: boolean } }) => {
    setSelectedDocumentIds((prevState) => {
      if (e.target.checked) {
        return [...prevState, documentId]
      }
      return prevState.filter((i) => i !== documentId)
    })
  }

  const handleDocumentRequest = React.useCallback(
    async (data: DocumentRequired[]) => {
      const newRequests: DocumentRequired[] = []

      data.forEach((newRequest) => {
        const existing = documentsRequested.find(
          (r) => r.typeId === newRequest.typeId && r.applicantType === newRequest.applicantType,
        )
        if (!existing) newRequests.push(newRequest)
      })

      if (newRequests.length > 0)
        await dispatchEffect(documentEffects.create(newRequests, creditApplicationId, financingProgramId))

      closeDialog()
    },
    [creditApplicationId, closeDialog, documentsRequested, dispatchEffect, financingProgramId],
  )

  const handleDeleteDocumentRequest = React.useCallback(async () => {
    if (requiredDocumentToDelete) {
      await dispatchEffect(documentEffects.deleteRequiredDocument({ ...requiredDocumentToDelete, financingProgramId }))
      setRequiredDocumentToDelete(null)
    }

    closeDialog()
  }, [closeDialog, requiredDocumentToDelete, dispatchEffect, financingProgramId])

  const closeDeleteDocumentRequestDialog = React.useCallback(() => {
    setOpenDialog(DocumentDialog.None)
    setRequiredDocumentToDelete(null)
  }, [setOpenDialog])

  const sendDocs = React.useCallback(
    async (documentList: Document[], files: File[]) => {
      setIsUploading(true)
      let interval: number

      try {
        const existingDocIds = documents.map((d) => d.id)
        const docsWithToReceive = await dispatchEffect(documentEffects.sendDocument(documentList, files))
        const newIds = docsWithToReceive.map((d) => d.id).filter((id) => !existingDocIds.includes(id))
        closeDialog()
        setFileList([])

        if (docsWithToReceive.length > 0) {
          let timesRun = 0
          interval = window.setInterval(() => {
            if (timesRun < 5) {
              timesRun += 1
              dispatchEffect(documentEffects.getDocumentList(creditApplicationId, financingProgramId))
                .then((ret) => {
                  const receivedIds = ret.map((d) => d.id)
                  if (newIds.every((id) => receivedIds.includes(id))) {
                    clearInterval(interval)
                  }
                })
                .catch(reportErrorToConsole)
            } else {
              clearInterval(interval)
            }
          }, 5000)
        }
      } finally {
        setIsUploading(false)
      }
      return () => {
        clearInterval(interval)
      }
    },
    [closeDialog, documents, dispatchEffect, financingProgramId, creditApplicationId],
  )

  const awaitingDocuments = React.useMemo((): DocumentRequired[] => {
    const documentRequired = documentsRequested.filter(
      (item: DocumentRequired) =>
        documents.find(
          (x: Document) =>
            x.applicantType === item.applicantType &&
            x.status !== EDocumentStatus.Deleted &&
            x.status !== EDocumentStatus.Refused &&
            x.typeId === item.typeId &&
            x.subKey === item.subKey,
        ) === undefined,
    )

    return documentRequired
  }, [documentsRequested, documents])

  const navigateSplitDocs = () => {
    let docs: string[]

    if (selectedDocumentIds.length > 0) {
      docs = selectedDocumentIds
    } else {
      docs = documents.filter((x) => x.status === EDocumentStatus.AwaitingApproval).map((d) => d.id!)
    }

    const docIds = encodeURI(docs.join(';'))

    navigate(`/Applications/${financingProgramId}/${creditApplicationId}/split-documents?docIds=${docIds}`)
  }

  const handleSendRequiredDocumentsMessage = () => {
    return dispatchEffect(messageEffects.sendRequiredDocumentsMessage(creditApplicationId, financingProgramId))
  }

  const handleQuickMerge = () => {
    if (selectedDocumentIds.length > 1)
      return dispatchEffect(
        documentEffects.quickMerge(selectedDocumentIds, creditApplicationId, financingProgramId),
      ).then(() => setSelectedDocumentIds([]))
    return null
  }

  const renderDialog = (param: DocumentDialog) => {
    switch (param) {
      case DocumentDialog.DocumentsRequest:
        return (
          <DocumentsRequestDialog
            creditApplicationId={creditApplicationId}
            open
            onCancel={closeDialog}
            onConfirm={handleDocumentRequest}
          />
        )
        break
      case DocumentDialog.EditDocument:
        return (
          <EditDocumentDialog
            open
            onConfirm={handleUpdateDocumentRequest}
            onCancel={closeDialog}
            defaultValue={editRequiredDocument}
          />
        )
        break
      case DocumentDialog.SendDocument:
        return (
          <SendDocumentsDialog
            open
            creditApplicationId={creditApplicationId}
            onCancel={closeDialog}
            onConfirm={sendDocs}
            awaitingDocument={awaitingDocuments}
            isUploading={isUploading}
            fileList={fileList}
            setFileList={setFileList}
          />
        )
        break
      case DocumentDialog.DeleteDocumentRequest:
        return (
          <DeleteDocumentRequestDialog
            data={requiredDocumentToDelete}
            open
            onCancel={closeDeleteDocumentRequestDialog}
            onConfirm={handleDeleteDocumentRequest}
          />
        )
        break
      default:
        return null
    }
  }

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(DocumentDialog.DocumentsRequest)}
          disabled={isCreatingRequiredDocument || editDisabled || !user?.rights.canManageDocuments}
        >
          {t('document.request')}
          {isCreatingRequiredDocument && <CircularProgress size="1rem" sx={{ marginLeft: '1rem' }} />}
        </Button>
        <Button
          variant="outlined"
          startIcon={<SendIcon />}
          onClick={() => setOpenDialog(DocumentDialog.SendDocument)}
          disabled={
            (isUploading && !openDialog) ||
            editDisabled ||
            !(user?.rights.canUploadDocuments || user?.rights.canUploadPrivateDocuments)
          }
        >
          {t('document.send')}
          {isUploading && !openDialog && <CircularProgress size="1rem" sx={{ marginLeft: '1rem' }} />}
        </Button>
        {financingProgramId === EFinancingProgram.Auto && (
          <Button
            variant="outlined"
            disabled={
              isSendingRequiredDocumentsMessage ||
              editDisabled ||
              !user?.rights.canUseMessaging ||
              areAllDocumentsRequiredApproved
            }
            startIcon={<NotificationActiveIcon />}
            onClick={() => handleSendRequiredDocumentsMessage()}
          >
            {isSendingRequiredDocumentsMessage && <CircularProgress size={14} />}
            {!isSendingRequiredDocumentsMessage && t('document.incompleteDocuments')}
          </Button>
        )}
        <Button
          id="btnReviewDocuments"
          variant="outlined"
          startIcon={<ReviewDocumentsIcon />}
          disabled={
            (!documents.some((x) => x.status === EDocumentStatus.AwaitingApproval) &&
              selectedDocumentIds.length === 0) ||
            editDisabled ||
            !user?.rights.canManageDocuments
          }
          onClick={() => navigateSplitDocs()}
        >
          {selectedDocumentIds.length >= 1 ? t('document.reviewSelected') : t('document.splitDocument')}
        </Button>
        <Button
          variant="outlined"
          startIcon={<Merge />}
          disabled={selectedDocumentIds.length < 2 || isQuickMerging}
          onClick={() => handleQuickMerge()}
        >
          {t('document.quickMerge')}
          {isQuickMerging && <CircularProgress size="1rem" sx={{ marginLeft: '1rem' }} />}
        </Button>
      </Stack>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderBottom: 'none' }}>
                <Typography variant="h6" gutterBottom component="div">
                  {t('document.requestedDocument')}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">{t('common.requiredFor')}</TableCell>
              <TableCell align="left">{t('document.receivedOn')}</TableCell>
              <TableCell align="left">{t('document.requestedOn')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {!isLoading && (
            <TableBody>
              {documentsRequested.map((item: DocumentRequired) => (
                <DocumentTableRow
                  editDisabled={editDisabled}
                  setEditRequiredDocument={setEditRequiredDocument}
                  setOpenDialog={setOpenDialog}
                  documentRequired={item}
                  userRights={user?.rights}
                  documents={documents.filter(
                    (x) =>
                      x.typeId === item.typeId &&
                      (x.applicantType === item.applicantType ||
                        (isEmpty(x.applicantType) && isEmpty(item.applicantType))),
                  )}
                  key={item.id}
                  handleDelete={(data: DocumentRequired) => {
                    setRequiredDocumentToDelete(data)
                    setOpenDialog(DocumentDialog.DeleteDocumentRequest)
                  }}
                  sendDocs={sendDocs}
                  financingProgramId={financingProgramId}
                  userCanEditDocument={user?.rights.canManageDocuments === true}
                  handleDocumentChecked={handleDocumentChecked}
                  selectedDocumentIds={selectedDocumentIds}
                />
              ))}

              <TableRow>
                <TableCell sx={{ borderBottom: 'none' }}>
                  <Typography variant="h6">{t('document.otherDocumentsReceived')}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Type</TableCell>
                <TableCell />
                <TableCell align="left">{t('document.receivedOn')}</TableCell>
                <TableCell align="left">{t('document.requestedOn')}</TableCell>
                <TableCell />
              </TableRow>
              {documents
                .filter(
                  (x) => !documentsRequested.some((y) => y.applicantType === x.applicantType && y.typeId === x.typeId),
                )
                .map((x) => (
                  <DocumentTableRow
                    userRights={user?.rights}
                    editDisabled={editDisabled}
                    key={x?.id}
                    setEditRequiredDocument={setEditRequiredDocument}
                    setOpenDialog={setOpenDialog}
                    documentRequired={
                      {
                        typeId: x?.typeId,
                        note: '',
                        applicantType: x.applicantType,
                        requiredBeforeStep: null,
                        creditApplicationId: x.creditApplicationId,
                        financingProgramId: x.financingProgramId,
                        subKey: x.subKey,
                      } as DocumentRequired
                    }
                    documents={[x] as Document[]}
                    sendDocs={sendDocs}
                    financingProgramId={financingProgramId}
                    userCanEditDocument={user?.rights.canManageDocuments === true}
                    handleDocumentChecked={handleDocumentChecked}
                    selectedDocumentIds={selectedDocumentIds}
                  />
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {renderDialog(openDialog)}
    </>
  )
}

export default DocumentList
