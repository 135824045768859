import { useSideEffect } from '@src/data/store/effects/side-effects'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Breadcrumb, PageSpinner } from '../../components'
import { useAppDispatch, useAppSelector } from '../../data/store'
import { creditActions, creditEffects, creditSelectors } from '../../data/store/CreditApplication'
import { CreditApplication } from '../../data/types'
import { reportErrorToServer } from '../../services/error-logger'
import CreditApplicationForm from './components/CreditApplicationForm/CreditApplicationForm'

const EditCreditApplicationPage = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatchEffect = useSideEffect()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isLoading = useAppSelector(creditSelectors.isLoadingCreditApplication)
  const creditApplication = useAppSelector(creditSelectors.getCurrent)
  const isRefinancing = useAppSelector(creditSelectors.isRefinancing)
  const editDisabled = creditApplication?.editLocked || isRefinancing

  const breadCrumbs = [
    { path: '/', label: t('breadcrumbs.home') },
    { path: '/Applications/browse', label: t('breadcrumbs.creditApplication') },
    {
      path: `/Applications/${creditApplication?.financingProgramId}/${creditApplication?.id}/view`,
      label: t('breadcrumbs.application').concat(` #${creditApplication?.referenceNumber}`),
    },
    { path: '#', label: t('credit.edit') },
  ]

  const onSave = (data: Partial<CreditApplication>) => {
    dispatchEffect(creditEffects.update(data))
      .then((creditApp) => {
        dispatch(creditActions.setCurrent(creditApp))
        navigate(`/Applications/${creditApp.financingProgramId}/${creditApp.id}/view`, { replace: true })
      })
      .catch(reportErrorToServer)
  }

  const getEditLockMessage = () => {
    if (isRefinancing) {
      return t('editCreditApplication.errors.creditApplicationLockedRefinancing')
    }
    return t('editCreditApplication.errors.creditApplicationLocked')
  }

  return (
    <>
      <PageSpinner isLoading={isLoading} />
      {!isLoading && creditApplication && (
        <>
          <Breadcrumb trees={breadCrumbs} />
          {editDisabled && <h1>{getEditLockMessage()}</h1>}
          <CreditApplicationForm creditApplication={creditApplication} onSave={onSave} />
        </>
      )}
    </>
  )
}

export default EditCreditApplicationPage
