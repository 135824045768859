import React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ActionsDialog, InputTextField, DatePicker } from '../../../components'
import { BanReason, banReasonSchema } from '../../../data/types/BanSchema'

interface Props {
  open: boolean
  onConfirm: (banishment: BanReason) => void
  onCancel: () => void
  title: string
  text: string
}

const BanDialog = ({ onConfirm, onCancel, open, title, text }: Props) => {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<BanReason>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    resolver: yupResolver(banReasonSchema),
  })
  return (
    <ActionsDialog onCancel={onCancel} onConfirm={handleSubmit(onConfirm)} open={open} title={title}>
      <div style={{ width: 600 }}>
        <Typography sx={{ mt: 2, mb: 2 }}>{text}</Typography>
      </div>
      <div style={{ width: 300 }}>
        <DatePicker name="expirationDate" control={control} label={t('ban.endPeriod')} error={errors.expirationDate} />
      </div>
      <InputTextField
        sx={{ mt: 3 }}
        label={t('ban.reason')}
        multiline
        {...register('description')}
        error={errors.description}
      />
    </ActionsDialog>
  )
}

export default React.memo(BanDialog)
