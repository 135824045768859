import DownloadIcon from '@mui/icons-material/Download'
import LaunchIcon from '@mui/icons-material/Launch'
import { Box, Divider, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material'
import { useAppSelector } from '@src/data/store'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import reportActEffect from '@src/data/store/report/reportAct-effects'
import reportAllApplicationEffect from '@src/data/store/report/reportAllApplication-effects'
import reportBenoitEffect from '@src/data/store/report/reportBenoit-effects'
import { userSelectors } from '@src/data/store/UserStore'
import { reportErrorToConsole } from '@src/services/error-logger'
import { useTranslation } from 'react-i18next'

const Reports = (): JSX.Element => {
  const { t } = useTranslation()

  const dispatchEffect = useSideEffect()
  const user = useAppSelector(userSelectors.getUser)

  const handleAllCreditApplicationsReportClick = async () =>
    dispatchEffect(reportAllApplicationEffect.getReportAllApplication()).catch(reportErrorToConsole)

  const handleReportBenoit = async () =>
    dispatchEffect(reportBenoitEffect.getReportBenoit()).catch(reportErrorToConsole)

  const handleReportAct = async () => dispatchEffect(reportActEffect.getReportAct()).catch(reportErrorToConsole)

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <List
        sx={{ minWidth: 350, maxWidth: '100%' }}
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {t('report.allReport')}
          </ListSubheader>
        }
      >
        <ListItemButton href="/reports/payoutThatSkippedIncomeValidation">
          <ListItemText primary={t('report.payoutWithoutFlinks.title')} />
          <ListItemIcon sx={{ justifyContent: 'end' }}>
            <LaunchIcon />
          </ListItemIcon>
        </ListItemButton>
        <Divider />
        <ListItemButton onClick={() => handleAllCreditApplicationsReportClick()}>
          <ListItemText primary={t('report.reportAllApplication')} />
          <ListItemIcon sx={{ justifyContent: 'end' }}>
            <DownloadIcon />
          </ListItemIcon>
        </ListItemButton>
        <Divider />
        <ListItemButton onClick={() => handleReportBenoit()}>
          <ListItemText primary={t('report.reportBenoit')} />
          <ListItemIcon sx={{ justifyContent: 'end' }}>
            <DownloadIcon />
          </ListItemIcon>
        </ListItemButton>
        <Divider />
        <ListItemButton onClick={() => handleReportAct()}>
          <ListItemText primary={t('report.reportAct')} />
          <ListItemIcon sx={{ justifyContent: 'end' }}>
            <DownloadIcon />
          </ListItemIcon>
        </ListItemButton>
        <Divider />
        {user?.rights.canReadManagementReports && (
          <ListItemButton href="/reports/agentLog">
            <ListItemText primary={t('report.agentLog.title')} />
            <ListItemIcon sx={{ justifyContent: 'end' }}>
              <LaunchIcon />
            </ListItemIcon>
          </ListItemButton>
        )}
      </List>
    </Box>
  )
}

export default Reports
