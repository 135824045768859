import React, { useRef, useEffect } from 'react'
import { Box, Grid, Stack, Button, Checkbox, FormControlLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SendIcon from '@mui/icons-material/Send'
import Message from './Message'
import { CreditApplicationMessage } from '../../../data/types'
import { InputTextField } from '../../../components'

interface Props {
  messages: CreditApplicationMessage[]
  onSave: (data: Partial<CreditApplicationMessage>) => void
}

const MessageList = ({ messages, onSave }: Props) => {
  const [newMessage, setNewMessage] = React.useState('')
  const [messageIsPrivate, setMessageIsPrivate] = React.useState(false)

  const scrollBottomRef = useRef<null | HTMLDivElement>(null)
  const handleSubmit = () => {
    const messageToAdd: Partial<CreditApplicationMessage> = {
      content: newMessage,
      isPrivate: messageIsPrivate,
    }
    setNewMessage('')
    setMessageIsPrivate(false)
    onSave(messageToAdd)
  }
  const { t } = useTranslation()

  useEffect(() => {
    if (scrollBottomRef.current) {
      scrollBottomRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages])

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <Box
          overflow="auto"
          sx={{
            border: '3px solid grey',
            borderRadius: 2,
            p: 1,
            height: '50vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {messages.map((message) => (
            <Box key={message.id} alignSelf={message.isFromIceberg ? 'flex-end' : 'flex-start'}>
              <Message key={message.id} message={message} />
            </Box>
          ))}
          <div ref={scrollBottomRef} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ mr: 15 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <InputTextField
            value={newMessage}
            multiline
            rows={5}
            onChange={(event) => setNewMessage(event.target.value)}
            onKeyUp={(event) => {
              if (event.ctrlKey && event.key === 'Enter') handleSubmit()
            }}
            label={t('message.newMessage')}
          />
          <FormControlLabel
            control={
              <Checkbox checked={messageIsPrivate} onChange={(event) => setMessageIsPrivate(event.target.checked)} />
            }
            label={t('message.isPrivate')}
          />
          <Button disabled={newMessage === ''} onClick={handleSubmit}>
            <SendIcon />
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )
}
export default MessageList
