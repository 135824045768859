import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import { ApplicantJob, ApplicantOtherIncome, CreditApplicationTask, EOtherIncomeType } from '../../../data/types'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'
import { formatAddress, FormatCurrencyNoDecimals } from '../../../services/Formatter'
import PhoneNumber from './PhoneNumber'
import TaskStatus from './TaskStatus'

interface Props {
  incomes: ApplicantJob[]
  otherIncomes: ApplicantOtherIncome[]
  jobTasks: CreditApplicationTask[]
  otherIncomeTasks: CreditApplicationTask[]
}

const IncomeInformationTable = ({ incomes, otherIncomes, jobTasks, otherIncomeTasks }: Props) => {
  const { t } = useTranslation()
  const jobTypeEnum = useAppSelector(appSelectors.getJobTypeEnum)
  const otherIncomeTypeEnum = useAppSelector(appSelectors.getOtherIncomeTypesEnum)
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum

  let totalIncome = 0
  let totalOtherIncome = 0
  incomes.forEach((item) => {
    totalIncome += item.annualSalary
  })

  otherIncomes.forEach((item) => {
    totalOtherIncome += item.annualAmount
  })

  return (
    <TableContainer component={Paper}>
      <Table aria-label="spanning table">
        <TableBody>
          {incomes.length !== 0 && (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography variant="h6" component="h6">
                  {t('editCreditApplication.income.job.jobs')}:
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {incomes.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <Typography>{`${item.employerName} (${jobTypeEnum[item.jobType.toString()][lang]}) : ${
                  item.jobTitle
                }`}</Typography>
                <Typography>
                  {t('credit.address')}: {formatAddress(item.address)}{' '}
                </Typography>

                <PhoneNumber
                  phone={item.employerPhone || ''}
                  phoneExt={item.employerPhoneExt || ''}
                  applyPhoneFormatting
                />
              </TableCell>
              <TableCell align="right">{FormatCurrencyNoDecimals(item.annualSalary)}</TableCell>
              <TableCell align="right" width="5%">
                <TaskStatus task={jobTasks.find((x) => x.subKey === item.id)} />
              </TableCell>
            </TableRow>
          ))}
          {otherIncomes.length !== 0 && (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography variant="h6" component="h6">
                  {t('editCreditApplication.income.other.label')}:
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {otherIncomes.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                {otherIncomeTypeEnum[item.typeId.toString()][lang]}
                {item.typeId === EOtherIncomeType.other && ` : ${item.description}`}
              </TableCell>
              <TableCell align="right">{FormatCurrencyNoDecimals(item.annualAmount)}</TableCell>
              <TableCell align="right" width="5%">
                <TaskStatus task={otherIncomeTasks.find((x) => x.subKey === item.id)} />
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <Typography variant="h6" component="h6">
                {t('credit.totalIncomes')}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="h6" component="h6">
                {FormatCurrencyNoDecimals(totalIncome + totalOtherIncome)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default IncomeInformationTable
