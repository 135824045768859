import { Delete as DeleteIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ActionsDialog, InputTextField, PageSpinner, SelectComponent } from '../../../components'
import { TRootState, useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import { Constants, EDocumentType, EFinancingProgram, SelectValueListItem } from '../../../data/types'
import { Document, DocumentRequired } from '../../../data/types/DocumentSchema'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'

interface Props {
  open: boolean
  awaitingDocument: DocumentRequired[]
  creditApplicationId: string
  onConfirm: (form: Document[], files: File[]) => void
  onCancel: () => void
  isUploading: boolean
  fileList: File[]
  setFileList: React.Dispatch<React.SetStateAction<File[]>>
}

const SendDocumentsDialog = ({
  open,
  awaitingDocument,
  creditApplicationId,
  onConfirm,
  onCancel,
  isUploading,
  fileList,
  setFileList,
}: Props) => {
  const { t } = useTranslation()
  const { financingProgramId } = useParams()
  const documentTypes = useAppSelector((state: TRootState) =>
    appSelectors.getDocumentTypes(state, financingProgramId as EFinancingProgram),
  )
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum
  const handleOnConfirm = () => {
    const documentList: Document[] = []
    let isValid = true
    fileList.forEach((file, index) => {
      const uniqueFileName = `${index}-${file.name}`
      const typeElement = document.getElementById(`${uniqueFileName}.type`) as HTMLInputElement
      const privacyElement = document.getElementById(`${uniqueFileName}.private`) as HTMLInputElement
      const noteElement = document.getElementById(`${uniqueFileName}.note`) as HTMLInputElement

      if (typeElement.value !== '' && typeElement.value !== null && typeElement.value !== undefined && isValid) {
        const typeId = Number(typeElement.value) as EDocumentType
        let doc: Document | undefined
        const request = awaitingDocument.find((x) => x.id === typeElement.value)
        if (request === undefined && typeId === EDocumentType.Other)
          doc = {
            note: noteElement.value,
            isPrivate: privacyElement.checked,
            creditApplicationId,
            financingProgramId,
            typeId,
            fileName: uniqueFileName,
          } as Document
        else
          doc = {
            applicantType: request?.applicantType,
            typeId: Number(request?.typeId),
            note: noteElement.value,
            creditApplicationId,
            financingProgramId,
            isPrivate: privacyElement.checked,
            subKey: request?.subKey,
            fileName: uniqueFileName,
          } as Document

        documentList.push(doc)
      } else {
        typeElement.setCustomValidity(t('common.errors.required'))
        typeElement.reportValidity()
        isValid = false
      }
    })

    if (isValid) {
      onConfirm(documentList, fileList)
    }
  }

  const selectContent = (): SelectValueListItem[] => {
    const content: SelectValueListItem[] = []

    content.push(
      ...awaitingDocument.map((item) => ({
        label: (documentTypes[item.typeId][lang] as string)
          .concat(item.labelSuffix ? `-${item.labelSuffix}` : '')
          .concat(item.applicantType ? ` (${t(`common.${item.applicantType}`)})` : ''),
        value: item.id!,
      })),
    )

    if (awaitingDocument.find((x) => x.typeId === EDocumentType.Other) === undefined)
      content.push({
        label: documentTypes[EDocumentType.Other][lang],
        value: EDocumentType.Other,
      } as SelectValueListItem)

    return content
  }

  return (
    <ActionsDialog
      title={t('document.send')}
      open={open}
      onCancel={() => {
        onCancel()
        setFileList([])
      }}
      onConfirm={handleOnConfirm}
    >
      <PageSpinner withBackdrop isLoading={isUploading} />
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ minWidth: '100vh', mt: 3 }}>
        <Button variant="contained" component="label" sx={{ width: '80vh' }}>
          {t('document.uploadFile')}
          <input
            type="file"
            accept={Constants.SupportedDocumentTypes}
            hidden
            multiple
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const { files } = event.target
              const listFiles: File[] = []
              if (files)
                for (const file of Array.from(files)) {
                  if (listFiles.every((x) => x.name !== file.name)) {
                    listFiles.push(file)
                  }
                }
              setFileList(fileList.concat(listFiles))
            }}
          />
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ mt: 2, maxHeight: '35rem' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">{t('common.file')}</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Note</TableCell>
              <TableCell align="left">{t('document.private')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {fileList.map((file, index) => (
              <TableRow key={file.name}>
                <TableCell align="left">{file.name}</TableCell>
                <TableCell sx={{ minWidth: '35%' }} align="left">
                  <SelectComponent id={`${index}-${file.name}.type`} items={selectContent()} label="" />
                </TableCell>
                <TableCell align="left" sx={{ width: '35%' }}>
                  <InputTextField id={`${index}-${file.name}.note`} label="" />
                </TableCell>
                <TableCell align="left">
                  <Checkbox id={`${index}-${file.name}.private`} />
                </TableCell>

                <TableCell>
                  <IconButton
                    aria-label="deletre"
                    component="label"
                    onClick={() => {
                      setFileList(fileList.filter((x) => x.name !== `${index}-${file.name}`))
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ActionsDialog>
  )
}

export default React.memo(SendDocumentsDialog)
