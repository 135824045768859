import { Delete as DeleteIcon } from '@mui/icons-material'
import { Grid, IconButton, Typography } from '@mui/material'
import { Control, FieldError, FieldErrorsImpl, Merge, UseFormRegister, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InputTextField, SelectComponent } from '../../../components'
import { useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import { Applicant, ApplicantOtherIncome, SelectValueListItem } from '../../../data/types'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'

interface Props {
  index: number
  register: UseFormRegister<Applicant>
  formControl: Control<Applicant>
  error: Merge<FieldError, (Merge<FieldError, FieldErrorsImpl<ApplicantOtherIncome>> | undefined)[]> | undefined
  onDelete: () => void
}

const OtherIncomeForm = ({ index, register, formControl, error, onDelete }: Props) => {
  const { t } = useTranslation()
  const otherIncomeTypeEnum = useAppSelector(appSelectors.getOtherIncomeTypesEnum)
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum

  const typeId: number = +useWatch({
    control: formControl,
    name: `otherIncomes.${index}.typeId`,
  })

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} md={2} alignItems="center" alignContent="center">
        <Typography variant="body1" component="div" sx={{ paddingTop: 2, paddingBottom: 2 }}>
          {`${t('editCreditApplication.income.other.other')} ${index + 1}`}:
        </Typography>
      </Grid>
      <Grid item xs={12} md={typeId === 6 ? 3 : 4}>
        <InputTextField
          error={error ? error[index]?.annualAmount : undefined}
          label={t('editCreditApplication.income.job.annualSalary')}
          {...register(`otherIncomes.${index}.annualAmount`)}
        />
      </Grid>
      <Grid item xs={12} md={typeId === 6 ? 3 : 5}>
        <SelectComponent
          error={error ? error[index]?.typeId : undefined}
          items={Object.values(otherIncomeTypeEnum).map((item) => {
            const data: SelectValueListItem = { label: item[lang], value: item.id }
            return data
          })}
          label={t('editCreditApplication.income.job.jobType') as string}
          {...register(`otherIncomes.${index}.typeId`)}
        />
      </Grid>
      {typeId === 6 && (
        <Grid item xs={12} md={3}>
          <InputTextField
            label="Description"
            {...register(`otherIncomes.${index}.description`)}
            error={error ? error[index]?.description : undefined}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
      )}
      <Grid item xs={12} md={1}>
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default OtherIncomeForm
