import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProductsWorksheet, ProductsWorksheetRevision } from '@src/data/types/ProductsWorksheetSchema'
import { ProductsComputeResultDto } from '@src/data/types/ProductsComputeResultDto'
import { EWorksheetDecision, NormsMessage } from '@src/data/types'
import { ParameterizedMessage } from '@src/data/types/ParameterizedMessage'
import translate from '@src/services/translate'
import type { TRootState } from '..'
import { apiStateSelectors } from '../ApiStateStore'
import { appSelectors } from '../AppStore'

export const ProductsWorksheetSlice = createSlice({
  name: 'productsWorksheet',
  initialState: {
    current: null as ProductsWorksheet | null,
    computedInfo: {} as ProductsComputeResultDto,
    currentRevisions: [] as ProductsWorksheetRevision[],
  },
  reducers: {
    setCurrent: (state, action: PayloadAction<ProductsWorksheet | null>) => {
      state.current = action.payload
    },
    setLoanComputed: (state, action: PayloadAction<ProductsComputeResultDto | null>) => {
      if (action.payload)
        state.computedInfo = {
          ...state.computedInfo,
          ...action.payload,
        }
    },
    setCurrentRevisions: (state, action: PayloadAction<ProductsWorksheetRevision[]>) => {
      state.currentRevisions = action.payload.sort((a, b) => {
        if (!a.updatedOn) return -1
        if (!b.updatedOn) return 1
        return a.updatedOn < b.updatedOn ? 1 : -1
      })
    },
  },
})

export default ProductsWorksheetSlice.reducer

export const productsWorksheetActions = ProductsWorksheetSlice.actions

export const GET_PRODUCTS_WORKSHEET = 'productsWorksheet/getById'
export const SAVE_PRODUCTS_WORKSHEET = 'productsWorksheet/save'
export const COMPUTE_PRODUCTS_LOAN = 'productsWorksheet/computeProductsLoan'
export const GET_PRODUCTS_WORKSHEET_REVISIONS = 'productsWorksheet/revisions'
export const SAVE_PRODUCTS_WORKSHEET_DECISION = 'productsWorksheet/Decision'
const productsWorksheetSelectorsPartial = {
  getCurrent: (state: TRootState) => state.productsWorksheet.current,
  isComputingProductsLoan: (state: TRootState) => apiStateSelectors.isLoading(state, COMPUTE_PRODUCTS_LOAN),
  getComputedInfo: (state: TRootState) => state.productsWorksheet.computedInfo,
  getCurrentRevisions: (state: TRootState) => state.productsWorksheet.currentRevisions,
  worksheetIsApproved: (state: TRootState) =>
    state.productsWorksheet.current?.finalDecision.decision === EWorksheetDecision.Approved,
}

function getParameterizedNormMessage(message: ParameterizedMessage): string {
  const translatedMessage = translate([`worksheet.norms.${message.id}`])

  let messageSuffix = ' | '
  message.parameters.forEach((p, i) => {
    if (i >= 1) messageSuffix = messageSuffix.concat(', ')

    const suffix = translate(`worksheet.paramNames.${p.name}`, { val: p.value })
    messageSuffix = messageSuffix.concat(suffix)
  })

  return translatedMessage.concat(messageSuffix)
}

const getCurrentWorksheetNormWarningMessages = createSelector(
  [productsWorksheetSelectorsPartial.getCurrent, appSelectors.getCurrentLang],
  (currentWorksheet) => {
    const worksheetNormMessages: NormsMessage[] = []
    currentWorksheet?.normsDecision?.warningMessages.forEach((wr) => {
      const builtMessage = getParameterizedNormMessage(wr)
      const worksheetNormMessage: NormsMessage = {
        id: wr.id,
        message: builtMessage,
        applicantType: null,
      }
      worksheetNormMessages.push(worksheetNormMessage)
    })

    return worksheetNormMessages
  },
)

const getCurrentWorksheetNormRefusalAndWarningMessages = createSelector(
  [productsWorksheetSelectorsPartial.getCurrent, appSelectors.getCurrentLang],
  (currentWorksheet) => {
    const warningsAndRefusals = currentWorksheet?.normsDecision?.warningMessages?.concat(
      currentWorksheet?.normsDecision?.refusalMessages,
    )

    const worksheetNormMessages: NormsMessage[] = []
    warningsAndRefusals?.forEach((wr) => {
      const builtMessage = getParameterizedNormMessage(wr)
      const worksheetNormMessage: NormsMessage = {
        id: wr.id,
        message: builtMessage,
        applicantType: null,
      }
      worksheetNormMessages.push(worksheetNormMessage)
    })
    return worksheetNormMessages
  },
)

export const productsWorksheetSelectors = {
  ...productsWorksheetSelectorsPartial,
  getCurrentWorksheetNormRefusalAndWarningMessages,
  getCurrentWorksheetNormWarningMessages,
}
