import { Edit as EditIcon, History as HistoryIcon } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Divider, IconButton, Link, Paper, Stack, Tooltip, Typography } from '@mui/material'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { reportErrorToConsole } from '@src/services/error-logger'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../data/store'
import { creditSelectors } from '../../../data/store/CreditApplication'
import { personalLoanWorksheetEffects } from '../../../data/store/PersonalLoanWorksheet'
import {
  CreditApplication,
  EContinueFinancingDisableReason,
  EDecision,
  EditPersonalLoanWorksheetDto,
  EMerchantIds,
  EPaymentFrequency,
  EPaymentMethod,
  EPaymentPlan,
  ETermMax,
  EWorksheetStatus,
  PersonalLoanWorksheet,
  User,
} from '../../../data/types'
import { FormatCurrency, formatDate, formatDateTime } from '../../../services/Formatter'
import { computeTotalInsurance } from '../../../services/WorksheetComputer'
import getIFinanceMaxPaymentDateGivenActivationDate from '../viewCreditApplicationPage-selectors'

interface Props {
  creditApplication: CreditApplication
  worksheet: PersonalLoanWorksheet
  onWorksheetHistoryClicked: () => void
  user: User | null
  defaultPaymentMethod: EPaymentMethod
  editDisabled: boolean
  forcePaymentToBorrower: boolean
  continueFinancingDisableReasonList: EContinueFinancingDisableReason[]
  handleRefinanceCreditApplication: () => void
}

const PersonalWorksheetInformationSection = ({
  creditApplication,
  worksheet,
  user,
  onWorksheetHistoryClicked,
  defaultPaymentMethod,
  editDisabled,
  forcePaymentToBorrower,
  continueFinancingDisableReasonList,
  handleRefinanceCreditApplication,
}: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const dispatchEffect = useSideEffect()
  const merchantPaymentPlan = useAppSelector(creditSelectors.getMerchantPaymentPlan)

  const ifinanceMerchantId = EMerchantIds.IfinanceMerchantId
  const medicardMerchantId = EMerchantIds.MedicardMerchantId
  const bankTransferMerchantId = EMerchantIds.PayCustomerByBankTransfer
  const bankPaymentMethod = EPaymentMethod.bankTransfer

  const viewWorksheetDisabled = worksheet === null
  const editWorksheetDisabled =
    creditApplication.finalCreditDecision.decision !== EDecision.accept ||
    !user?.rights.canEditWorksheet ||
    editDisabled

  const getInitialMerchantPayment = (paymentAmount: number) => {
    const isIFinanceOrMedicardMerchant = ([ifinanceMerchantId, medicardMerchantId] as string[]).includes(
      creditApplication.merchantId,
    )
    if (forcePaymentToBorrower)
      return [{ merchantId: bankTransferMerchantId, amount: paymentAmount, paymentMethod: bankPaymentMethod }]
    if (isIFinanceOrMedicardMerchant) return []
    return [
      {
        merchantId: creditApplication.merchantId,
        amount: paymentAmount,
        paymentMethod: defaultPaymentMethod,
      },
    ]
  }

  // event handlers
  const createWorksheet = () => {
    const route = `/Applications/personal/${creditApplication.id}/worksheet/edit`

    if (worksheet === null) {
      const paymentAmount =
        (creditApplication.finalCreditDecision.maxAmountFinanced ?? 0) >= (creditApplication.requestedLoanAmount ?? 0)
          ? creditApplication.requestedLoanAmount
          : creditApplication.finalCreditDecision.maxAmountFinanced

      const newWorksheet: EditPersonalLoanWorksheetDto = {
        amountRequested: paymentAmount,
        deliveryOn: formatDate(new Date()),
        firstPaymentOn: getIFinanceMaxPaymentDateGivenActivationDate(new Date()),
        paymentFrequency: EPaymentFrequency.Monthly,
        term: merchantPaymentPlan ? merchantPaymentPlan.loanTerm : ETermMax.twelve,
        includeInsurance: false,
        addFastPayments: false,
        versionTag: undefined,
        fundConfirmationNote: null,
        paymentPlanId: merchantPaymentPlan ? merchantPaymentPlan.id : EPaymentPlan.regularDailyInterests,
        merchantPayments: getInitialMerchantPayment(paymentAmount ?? 500),
        creditApplicationId: creditApplication.id,
        status: EWorksheetStatus.Draft,
      }
      dispatchEffect(personalLoanWorksheetEffects.create(newWorksheet))
        .then(() => navigate(route))
        .catch(reportErrorToConsole)
    } else {
      navigate(route)
    }
  }

  const tooltipTextContinueFinancing = () => {
    if (continueFinancingDisableReasonList.length > 0) {
      return (
        <div>
          {continueFinancingDisableReasonList.map((r) => (
            <Typography key={r}>{t(`continueFinancing.${r}`)}</Typography>
          ))}
        </div>
      )
    }
    return null
  }

  return (
    <Paper elevation={3} sx={{ borderRadius: 2, p: 2, mb: 3 }}>
      <Box sx={{ m: 1 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={2}>
            <ButtonGroup variant="outlined" size="large">
              <Button
                id="btnEditWorksheet"
                component={Link}
                href={`/Applications/personal/${creditApplication.id}/worksheet`}
                variant="outlined"
                disabled={viewWorksheetDisabled}
              >
                {t('worksheet.financing')}
              </Button>
              <Button onClick={createWorksheet} disabled={editWorksheetDisabled} size="small">
                <EditIcon fontSize="large" />
              </Button>
            </ButtonGroup>
            <Tooltip title={tooltipTextContinueFinancing()}>
              <span>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={handleRefinanceCreditApplication}
                  disabled={continueFinancingDisableReasonList.length !== 0}
                  style={{
                    padding: '10px',
                    fontSize: '13px',
                  }}
                >
                  {t('common.continueFinancing')}
                </Button>
              </span>
            </Tooltip>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            alignContent="center"
            divider={<Divider orientation="vertical" flexItem sx={{ height: 25, alignSelf: 'center' }} />}
          >
            <Typography variant="body1" component="div" gutterBottom>
              {t('common.LastModification')} : {worksheet && formatDateTime(worksheet?.updatedOn)}
            </Typography>

            <IconButton
              onClick={onWorksheetHistoryClicked}
              disabled={worksheet === null}
              sx={{
                color: worksheet === null ? '' : 'primary.main',
                fontSize: 'large',
              }}
            >
              <HistoryIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Divider />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          alignItems="top"
          marginTop={1}
          justifyContent="space-evenly"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <span>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.decision')} :{' '}
              {worksheet?.finalDecision && t(`enum.eWorkSheetDecision.${worksheet.finalDecision.decision}`)}
            </Typography>
            {worksheet !== null && (
              <Typography variant="body1" component="div" gutterBottom>
                Promo :{' '}
                {worksheet?.paymentPlanId !== EPaymentPlan.regularDailyInterests ? t('common.yes') : t('common.no')}
              </Typography>
            )}
          </span>
          <span>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.paymentFrequency')} : {worksheet && t(`worksheet.${worksheet?.paymentFrequency}`)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.term')} : {worksheet?.term} {worksheet?.term !== undefined && t('worksheet.month')}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.paymentAmount')} : {FormatCurrency(worksheet?.paymentAmountForFrequency)}
            </Typography>
          </span>
          <span>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.price')} : {FormatCurrency(worksheet?.amountRequested)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.insurance')} : {FormatCurrency(computeTotalInsurance(worksheet))}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.totalAmountFinanced')} : {FormatCurrency(worksheet?.totalAmountFinanced)}
            </Typography>
          </span>
        </Stack>
      </Box>
    </Paper>
  )
}

export default PersonalWorksheetInformationSection
