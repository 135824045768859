import { Delete as DeleteIcon } from '@mui/icons-material'
import { Grid, IconButton, Typography } from '@mui/material'
import { FieldError, FieldErrorsImpl, Merge, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InputTextField, SelectComponent } from '../../../components'
import { useAppSelector } from '../../../data/store'
import { appSelectors } from '../../../data/store/AppStore'
import { Applicant, ApplicantJob, SelectValueListItem } from '../../../data/types'
import { TranslatedEnum } from '../../../data/types/TranslatedEnum'

interface Props {
  index: number
  register: UseFormRegister<Applicant>
  error: Merge<FieldError, (Merge<FieldError, FieldErrorsImpl<ApplicantJob>> | undefined)[]> | undefined
  onDelete: () => void
}

const IncomeForm = ({ index, register, error, onDelete }: Props) => {
  const { t } = useTranslation()
  const jobTypeEnum = useAppSelector(appSelectors.getJobTypeEnum)
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} md={2} alignItems="center" alignContent="center">
        <Typography variant="body1" component="div" sx={{ paddingTop: 2, paddingBottom: 2 }}>
          {`${t('editCreditApplication.income.job.job')} ${index + 1}`}:
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <InputTextField
          error={error?.[index]?.annualSalary}
          label={t('editCreditApplication.income.job.annualSalary')}
          {...register(`currentJobs.${index}.annualSalary`)}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SelectComponent
          error={error?.[index]?.jobType}
          items={Object.values(jobTypeEnum).map((item) => {
            const data: SelectValueListItem = { label: item[lang], value: item.id }
            return data
          })}
          label={t('editCreditApplication.income.job.jobType') as string}
          {...register(`currentJobs.${index}.jobType`)}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <InputTextField
          error={error?.[index]?.employerName}
          label={t('editCreditApplication.income.job.employerName')}
          {...register(`currentJobs.${index}.employerName`)}
          inputProps={{ maxLength: 100 }}
        />
      </Grid>
      <Grid item xs={12} md={1}>
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default IncomeForm
