import { Box, Divider, Grid, Paper, Typography } from '@mui/material'
import { InputTextField, SelectComponent } from '@src/components'
import { ApplicantExpenses, EFinancingProgram, EHomeStatus, EHomeStatusList } from '@src/data/types'
import { useEffect, useState } from 'react'
import { FieldError, FieldErrorsImpl, Merge, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>
  errors?: Merge<FieldError, FieldErrorsImpl<ApplicantExpenses>>
  name: string
  showDetailedForm: boolean
  financingProgramId: EFinancingProgram
  editDisabled: boolean
}

const ApplicantExpensesSection = ({
  register,
  watch,
  setValue,
  errors,
  name,
  showDetailedForm,
  financingProgramId,
  editDisabled,
}: Props) => {
  const { t } = useTranslation()
  const monthlyOtherExpensePayment = watch(`${name}.monthlyOtherExpensePayment`) as string
  const houseFeeType = watch(`${name}.homeFeeTypeId`) as EHomeStatus
  const [isHouseMarketLocked, setIsHouseMarketLocked] = useState<boolean>(false)
  const [isOwner, setIsOwner] = useState<boolean>(false)
  setValue(`${name}.financingProgramId`, financingProgramId)

  useEffect(() => {
    if (houseFeeType === EHomeStatus.Owner) {
      setIsHouseMarketLocked(false)
      setIsOwner(true)
      setValue(`${name}.housingMonthly`, undefined)
    } else if (
      houseFeeType === EHomeStatus.WithParents ||
      houseFeeType === EHomeStatus.Renter ||
      houseFeeType === EHomeStatus.ReserveHousing
    ) {
      setValue(`${name}.houseMarketValue`, undefined)
      setIsHouseMarketLocked(true)
      setIsOwner(false)
    } else if (houseFeeType) {
      setIsHouseMarketLocked(false)
      setIsOwner(false)
    }
  }, [houseFeeType, name, setValue])

  const isInvalidOtherExpensePayment =
    !monthlyOtherExpensePayment ||
    monthlyOtherExpensePayment.toString().trim() === '' ||
    monthlyOtherExpensePayment.toString().trim() === '0'

  useEffect(() => {
    if (isInvalidOtherExpensePayment) {
      setValue(`${name}.otherExpensePaymentDescription`, '')
    }
  }, [monthlyOtherExpensePayment, setValue, isInvalidOtherExpensePayment, name])

  return (
    <Paper elevation={3} sx={{ borderRadius: 2, p: 2, mb: 3 }}>
      <Divider>
        <Typography variant="h5" component="span" gutterBottom>
          {t('editCreditApplication.expenses.recurringMonthlyHomeRelatedDisbursements')}
        </Typography>
      </Divider>
      <Box component="fieldset" sx={{ my: 1, mx: 2 }}>
        <legend>{t('editCreditApplication.expenses.homeFeesLebel')}</legend>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          alignContent="space-between"
          sx={{ p: 1 }}
          style={{ flexWrap: 'inherit' }}
        >
          <Grid item xs={12} alignContent="space-between">
            <SelectComponent
              items={EHomeStatusList.map((item) => ({
                label: `enum.eHomeStatus.${item}`,
                value: item,
              }))}
              label={t('editCreditApplication.expenses.homeFeeTypeId') as string}
              {...register(`${name}.homeFeeTypeId`)}
              error={errors?.homeFeeTypeId}
              disabled={editDisabled}
            />
          </Grid>
          {!isOwner && (
            <Grid item xs={12}>
              <InputTextField
                error={errors?.housingMonthly}
                label={t('editCreditApplication.expenses.monthlyHomePayment')}
                {...register(`${name}.housingMonthly`)}
                disabled={editDisabled}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <InputTextField
              disabled={isHouseMarketLocked || editDisabled}
              error={errors?.houseMarketValue}
              label={t('editCreditApplication.expenses.houseMarketValue')}
              {...register(`${name}.houseMarketValue`)}
            />
          </Grid>
        </Grid>
      </Box>
      {showDetailedForm && (
        <>
          <Box component="fieldset" sx={{ m: 2 }}>
            <legend>{t('editCreditApplication.expenses.others')}</legend>
            <Grid container item xs={12} spacing={2} alignContent="space-between" sx={{ p: 1 }}>
              <Grid item xs={12} md={3}>
                <InputTextField
                  error={errors?.monthlyOwnerInsuranceFee}
                  label={t('editCreditApplication.expenses.monthlyOwnerInsuranceFee')}
                  {...register(`${name}.monthlyOwnerInsuranceFee`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  error={errors?.monthlyMortgageInsuranceFee}
                  label={t('editCreditApplication.expenses.monthlyMortgageInsuranceFee')}
                  {...register(`${name}.monthlyMortgageInsuranceFee`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  error={errors?.monthlyMunicipalAndSchoolTaxPayment}
                  label={t('editCreditApplication.expenses.monthlyMunicipalAndSchoolTaxPayment')}
                  {...register(`${name}.monthlyMunicipalAndSchoolTaxPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  error={errors?.monthlyCondominiumFee}
                  label={t('editCreditApplication.expenses.monthlyCondominiumFee')}
                  {...register(`${name}.monthlyCondominiumFee`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  error={errors?.monthlyPublicServiceFee}
                  label={t('editCreditApplication.expenses.monthlyPublicServiceFee')}
                  {...register(`${name}.monthlyPublicServiceFee`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  error={errors?.monthlyRrspRefund}
                  label={t('editCreditApplication.expenses.monthlyRrspRefund')}
                  {...register(`${name}.monthlyRrspRefund`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  error={errors?.monthlyOtherHomeFee}
                  label={t('editCreditApplication.expenses.monthlyOtherHomeFee')}
                  {...register(`${name}.monthlyOtherHomeFee`)}
                />
              </Grid>
            </Grid>
          </Box>
          <Divider>
            <Typography variant="h5" component="span" gutterBottom>
              {t('editCreditApplication.expenses.monthlyDisbursementsWithCreditAgreement')}
            </Typography>
          </Divider>
          <Box component="fieldset" sx={{ m: 2 }}>
            <legend>{t('editCreditApplication.expenses.loanAndCreditLabel')}</legend>
            <Grid container item xs={12} spacing={2} alignContent="space-between" sx={{ p: 1 }}>
              <Grid item xs={12} md={3}>
                <InputTextField
                  error={errors?.monthlyInstallmentLoanPayment}
                  label={t('editCreditApplication.expenses.monthlyInstallmentLoanPayment')}
                  {...register(`${name}.monthlyInstallmentLoanPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  error={errors?.monthlyStudentLoanPayment}
                  label={t('editCreditApplication.expenses.monthlyStudentLoanPayment')}
                  {...register(`${name}.monthlyStudentLoanPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  error={errors?.monthlyLineOfCreditPayment}
                  label={t('editCreditApplication.expenses.monthlyLineOfCreditPayment')}
                  {...register(`${name}.monthlyLineOfCreditPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  error={errors?.monthlyCreditCardsPayment}
                  label={t('editCreditApplication.expenses.monthlyCreditCardsPayment')}
                  {...register(`${name}.monthlyCreditCardsPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  error={errors?.monthlyPaydayLoanPayment}
                  label={t('editCreditApplication.expenses.monthlyPaydayLoanPayment')}
                  {...register(`${name}.monthlyPaydayLoanPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  error={errors?.monthlyPersonalLoanPayment}
                  label={t('editCreditApplication.expenses.monthlyPersonalLoanPayment')}
                  {...register(`${name}.monthlyPersonalLoanPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  error={errors?.monthlyOtherLoanPayment}
                  label={t('editCreditApplication.expenses.monthlyOtherLoanPayment')}
                  {...register(`${name}.monthlyOtherLoanPayment`)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputTextField
                  error={errors?.monthlyActiveIFLoanPayment}
                  label={t('editCreditApplication.expenses.monthlyActiveIFLoanPayment')}
                  {...register(`${name}.monthlyActiveIFLoanPayment`)}
                  disabled
                />
              </Grid>
            </Grid>
          </Box>
          <Box component="fieldset" sx={{ m: 2 }}>
            <legend>{t('editCreditApplication.expenses.others')}</legend>
            <Grid container item xs={12} spacing={2} alignContent="space-between" sx={{ p: 1 }}>
              <Grid item xs={12} md={4}>
                <InputTextField
                  error={errors?.monthlyOtherExpensePayment}
                  disabled={editDisabled}
                  label={t('editCreditApplication.expenses.monthlyOtherExpensePayment')}
                  {...register(`${name}.monthlyOtherExpensePayment`)}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <InputTextField
                  disabled={isInvalidOtherExpensePayment}
                  error={errors?.otherExpensePaymentDescription}
                  label={t('editCreditApplication.expenses.otherExpensePaymentDescription')}
                  {...register(`${name}.otherExpensePaymentDescription`)}
                  maxLength={50}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Paper>
  )
}

export default ApplicantExpensesSection
