import { get, sumBy } from 'lodash-es'
import { ApplicantJob, ApplicantOtherIncome, BusinessError, CreditApplication } from '../../types'
import { Constants, EFinancingProgram, EHomeStatus, EProvince, ERelationToApplicant } from '../../types/constants'

function strCompare(param1: string | null | undefined, param2: string | null | undefined): boolean {
  return (param1 ?? '') === (param2 ?? '')
}

function DoesApplicantLiveWithCoapplicant(application: CreditApplication): boolean {
  const applicantAddress = application.applicant.currentAddress
  const coApplicantAddress = application.coApplicant?.currentAddress

  return (
    strCompare(applicantAddress.civicNumber, coApplicantAddress?.civicNumber) &&
    strCompare(applicantAddress.apartment, coApplicantAddress?.apartment) &&
    strCompare(applicantAddress.street, coApplicantAddress?.street) &&
    strCompare(applicantAddress.city, coApplicantAddress?.city) &&
    strCompare(applicantAddress.country, coApplicantAddress?.country) &&
    strCompare(applicantAddress.stateIso, coApplicantAddress?.stateIso) &&
    strCompare(applicantAddress.postalCode, coApplicantAddress?.postalCode)
  )
}
function isHousingExpenseValid(homeFeeTypeId: EHomeStatus | null, housingMonthly: number | null) {
  return (
    homeFeeTypeId === EHomeStatus.Owner ||
    (housingMonthly != null &&
      ((homeFeeTypeId === EHomeStatus.WithParents && housingMonthly >= 0) || housingMonthly > 0))
  )
}

export default function validateCreditApplicationCanBeSubmittedForReview(
  application: CreditApplication,
): BusinessError[] {
  const ret: BusinessError[] = []
  const financingProgramConfig = get(
    Constants.financingProgramConfigs,
    application.financingProgramId?.toLowerCase() ?? 'default',
    Constants.financingProgramConfigs.default,
  )
  const isProductAndNotQcApplicant =
    application.financingProgramId === EFinancingProgram.Products &&
    application.applicant.currentAddress.stateIso !== EProvince.quebec

  const isAutoApplication = application.financingProgramId === EFinancingProgram.Auto

  const isProductAndNotQcCoApplicant =
    application.coApplicant &&
    application.financingProgramId === EFinancingProgram.Products &&
    application.coApplicant.currentAddress.stateIso !== EProvince.quebec

  const hasCoapplicant = !!application?.coApplicant
  const applicantHousingExpensesValid =
    isHousingExpenseValid(
      application.applicant.expenses.homeFeeTypeId,
      application.applicant.expenses.housingMonthly,
    ) || isProductAndNotQcApplicant
  const coApplicantHousingExpensesValid =
    isHousingExpenseValid(
      application.coApplicant?.expenses.homeFeeTypeId ?? null,
      application.coApplicant?.expenses.housingMonthly ?? null,
    ) || isProductAndNotQcCoApplicant

  if (financingProgramConfig.useIncomeSection && !isProductAndNotQcApplicant) {
    const applicantIncomes =
      sumBy(application.applicant.currentJobs, (x: ApplicantJob) => x.annualSalary) +
      sumBy(application.applicant.otherIncomes, (x: ApplicantOtherIncome) => x.annualAmount)

    const coapplicantIncomes =
      sumBy(application.coApplicant?.currentJobs, (x: ApplicantJob) => x.annualSalary) +
      sumBy(application.coApplicant?.otherIncomes, (x: ApplicantOtherIncome) => x.annualAmount)

    const minIncome = Constants.GetMinIncomeForProgram(application.financingProgramId)
    const minCombinedIncome = Constants.GetMinCombinedIncomeForProgram(application.financingProgramId)
    const coapplicantIsSpouse =
      application.coApplicant?.relationWithApplicant === ERelationToApplicant.Spouse ||
      application.coApplicant?.relationWithApplicant === ERelationToApplicant.CommonLaw

    if (isAutoApplication && coapplicantIsSpouse && DoesApplicantLiveWithCoapplicant(application)) {
      if (
        applicantIncomes < minIncome &&
        coapplicantIncomes < minIncome &&
        applicantIncomes + coapplicantIncomes < minCombinedIncome
      )
        ret.push({
          message: 'editCreditApplication.errors.minIncomeNotMetCouple',
          params: { minIncome: minIncome.toString(), minCombinedIncome: minCombinedIncome.toString() },
        })
    } else if (applicantIncomes < minIncome || (hasCoapplicant && coapplicantIncomes < minIncome))
      ret.push({ message: 'editCreditApplication.errors.minIncomeNotMet', params: { minIncome: minIncome.toString() } })
  }
  if (
    financingProgramConfig.useExpenseSection &&
    (applicantHousingExpensesValid === false || (hasCoapplicant && coApplicantHousingExpensesValid === false))
  ) {
    ret.push({ message: 'editCreditApplication.errors.expenses', params: {} })
  }
  return ret
}
