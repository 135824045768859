import { MsalProvider } from '@azure/msal-react'
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import App from './containers/App/App'
import { store } from './data/store'
import './index.css'
import initializeAppInsights from './services/AppInsights'
import initializeMsal from './services/Authentification'
import { reportErrorToConsole, setupTracking } from './services/error-logger'
import i18n, { initializeI18N } from './services/i18n'
import { appActions } from './data/store/AppStore'

const ai = initializeAppInsights()
setupTracking(ai)
const msalInstance = initializeMsal()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})
const container = document.getElementById('root')
const root = createRoot(container!)

initializeI18N()
  .then(() => {
    store.dispatch(appActions.setLanguage(i18n.language))
    root.render(
      <React.StrictMode>
        <Suspense fallback="loading">
          <QueryClientProvider client={queryClient}>
            <I18nextProvider i18n={i18n}>
              <Provider store={store}>
                <MsalProvider instance={msalInstance}>
                  <App />
                </MsalProvider>
              </Provider>
            </I18nextProvider>
          </QueryClientProvider>
        </Suspense>
      </React.StrictMode>,
    )
  })
  .catch(reportErrorToConsole)
