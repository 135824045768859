import React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Stack, Typography } from '@mui/material'

interface RowProps {
  children?: React.ReactNode
  name: string
  id: string
  expended?: boolean
}

const Row = ({ id, children, name, expended = true }: RowProps) => {
  const [open, setOpen] = React.useState(expended)

  return (
    <>
      <TableRow id={id} sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
            <Typography variant="h6" gutterBottom component="div">
              {name}
            </Typography>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>{children}</Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default Row
