import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  TextField,
} from '@mui/material'
import { reportErrorToConsole } from '@src/services/error-logger'
import axios, { AxiosRequestConfig } from 'axios'
import { every } from 'lodash-es'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Applicant, EDecision, EFinancingProgram } from '../../../../data/types'

interface TestCase {
  id: string
  applicantDecisionAuto: EDecision
  applicant: Applicant
  description: string
}

interface DialogProps {
  onTestCaseSelected: (applicant: Applicant) => void
  open: boolean
  onClose: () => void
  financingProgramId: EFinancingProgram
}

function loadTestCases(): Promise<TestCase[]> {
  const payload: AxiosRequestConfig = {
    method: 'GET',
    url: 'https://func-iceberg-proxies-dev.azurewebsites.net/CreditTestCases',
  }
  return axios.request(payload).then((response) => {
    const res = response.data as TestCase[]
    res.forEach((x: TestCase, index) => {
      x.id = index.toString()
    })
    return response.data as TestCase[]
  })
}

interface TestCaseItemProps {
  onTestCaseSelected: (applicant: Applicant) => void
  testcase: TestCase
  financingProgramId: EFinancingProgram
  selected: boolean
}

const TestCaseItem = ({ onTestCaseSelected, testcase, financingProgramId, selected }: TestCaseItemProps) => {
  const { t } = useTranslation()
  const { applicant } = testcase
  const decision =
    financingProgramId === EFinancingProgram.Auto ? t(`credit.${testcase.applicantDecisionAuto}`) : 'TODO'

  const primaryText = `${applicant.firstName} ${applicant.lastName} (${applicant.birthDate}) : ${decision}`
  return (
    <>
      <ListItemButton onClick={() => onTestCaseSelected(testcase.applicant)} selected={selected}>
        <ListItemText primary={primaryText} secondary={testcase.description} />
      </ListItemButton>
      <Divider />
    </>
  )
}

function getCase(cases: TestCase[], financingProgramId: EFinancingProgram, decision: EDecision): Applicant | null {
  const propName = financingProgramId === EFinancingProgram.Auto ? 'applicantDecisionAuto' : 'applicantDecisionAuto'
  const matching = cases.filter((c) => c[propName] === decision)
  return matching.length > 0 ? matching[0].applicant : null
}
const ListTestCaseDialog = ({ open, onClose, onTestCaseSelected, financingProgramId }: DialogProps) => {
  const [selectedApplicant, setSelectedApplicant] = useState<Applicant | null>(null)
  const [testcases, setTestCases] = useState<TestCase[]>([])
  const [filteredTestCases, setFilteredTestCases] = useState<TestCase[]>([])
  const [textFilter, setTextFilter] = useState('')
  const [approvedCase, setApprovedCase] = useState<Applicant | null>(null)
  const [reviewCase, setReviewCase] = useState<Applicant | null>(null)
  const [declineCase, setDeclineCase] = useState<Applicant | null>(null)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (open && testcases.length === 0) {
      setLoading(true)
      loadTestCases()
        .then((values: TestCase[]) => {
          setLoading(false)
          setTestCases(values)
        })
        .catch(reportErrorToConsole)
    }
  }, [open, testcases.length])

  useEffect(() => {
    const filters = textFilter.toLowerCase().split(' ')
    const match = testcases.filter((testcase) => {
      return every(
        filters,
        (f) =>
          testcase.applicant.firstName.toLowerCase().includes(f) ||
          testcase.applicant.lastName.toLowerCase().includes(f) ||
          testcase.description.toLowerCase().includes(f),
      )
    })
    setFilteredTestCases(match)
  }, [textFilter, testcases])

  useEffect(() => {
    setApprovedCase(getCase(testcases, financingProgramId, EDecision.accept))
    setReviewCase(getCase(testcases, financingProgramId, EDecision.manualRevision))
    setDeclineCase(getCase(testcases, financingProgramId, EDecision.declined))
  }, [testcases, financingProgramId])

  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="xl">
      <DialogTitle style={{ borderBottom: '1px solid' }} id="alert-dialog-title">
        Sélectionner un cas de test
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} padding={2}>
          <Grid item>
            <TextField
              name="filter"
              onChange={(e) => setTextFilter(e.target.value)}
              value={textFilter}
              label="Filtre"
            />
          </Grid>
          <Grid item flexGrow={1} />
          {approvedCase && (
            <Grid item>
              <Button
                type="button"
                variant="contained"
                color="success"
                onClick={() => onTestCaseSelected(approvedCase)}
              >
                Approuvé
              </Button>
            </Grid>
          )}
          {reviewCase && (
            <Grid item>
              <Button type="button" variant="contained" color="warning" onClick={() => onTestCaseSelected(reviewCase)}>
                Révision manuelle
              </Button>
            </Grid>
          )}
          {declineCase && (
            <Grid item>
              <Button type="button" variant="contained" color="error" onClick={() => onTestCaseSelected(declineCase)}>
                Refusé
              </Button>
            </Grid>
          )}
        </Grid>

        <Divider />
        <Box height={400} width={900}>
          {isLoading && <CircularProgress />}
          {!isLoading && filteredTestCases.length > 0 && (
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {filteredTestCases.map((testcase) => (
                <TestCaseItem
                  key={testcase.id}
                  testcase={testcase}
                  financingProgramId={financingProgramId}
                  onTestCaseSelected={setSelectedApplicant}
                  selected={testcase.applicant === selectedApplicant}
                />
              ))}
            </List>
          )}
          {!isLoading && filteredTestCases.length === 0 && <p>Aucun cas ne contient tous les mots saisis</p>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', flexDirection: 'row', p: 2 }}>
          <Button type="button" variant="contained" color="error" onClick={onClose}>
            Annuler
          </Button>
        </Box>
        <Box sx={{ flex: '1 1 auto' }} />
        <Box sx={{ display: 'flex', flexDirection: 'row', p: 2 }}>
          <Button
            type="button"
            variant="contained"
            color="success"
            disabled={selectedApplicant === null}
            onClick={() => {
              if (selectedApplicant) onTestCaseSelected(selectedApplicant)
            }}
          >
            Charger
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default ListTestCaseDialog
