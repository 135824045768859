import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EPaymentPlan } from '../../../data/types'
import { FundingComputedDto } from '../../../data/types/FundingComputedDto'
import { IFinanceBaseWorksheet } from '../../../data/types/IFinanceBaseWorksheetSchema'
import { MerchantPaymentPlan } from '../../../data/types/MerchantPaymentPlan'
import { FormatCurrency } from '../../../services/Formatter'

interface Props {
  worksheet: IFinanceBaseWorksheet
  computedInfo: FundingComputedDto
  merchantPaymentPlan: MerchantPaymentPlan | null
}

const LoanSummaryBox = ({ worksheet, computedInfo, merchantPaymentPlan }: Props) => {
  const { t } = useTranslation()

  const totalFees = computedInfo?.lenderFee ?? 0
  const insuranceTotal = (computedInfo?.insuranceFee ?? 0) + (computedInfo?.insuranceTax ?? 0)

  const getMerchantFees = () => {
    if (merchantPaymentPlan && worksheet.amountRequested) {
      return FormatCurrency((merchantPaymentPlan.merchantFeeRate / 100) * worksheet.amountRequested)
    }
    return ''
  }

  return (
    <>
      <Typography variant="h6" component="h3" gutterBottom>
        {t('worksheet.sideSection.loanSummary')}
      </Typography>
      <Stack direction="row" justifyContent="space-between" marginLeft={5} marginRight={5}>
        <Typography variant="subtitle1">{t('worksheetCommon.amountRequested')} :</Typography>
        <Typography variant="subtitle1">{FormatCurrency(worksheet?.amountRequested)}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" marginLeft={5} marginRight={5}>
        <Typography variant="subtitle1">
          {t('worksheetCommon.financeFee')} ({worksheet?.lenderFeeRate}%) :
        </Typography>
        <Typography variant="subtitle1">{FormatCurrency(totalFees)}</Typography>
      </Stack>
      {merchantPaymentPlan && worksheet?.paymentPlanId !== EPaymentPlan.regularDailyInterests && (
        <Stack direction="row" justifyContent="space-between" marginLeft={5} marginRight={5}>
          <Typography variant="subtitle1">
            {t('worksheetCommon.merchantFee')} ({merchantPaymentPlan.merchantFeeRate}%) :
          </Typography>
          <Typography variant="subtitle1">{getMerchantFees()}</Typography>
        </Stack>
      )}
      <Stack direction="row" justifyContent="space-between" marginLeft={5} marginRight={5}>
        <Typography variant="subtitle1">{t('worksheetCommon.insurance')} :</Typography>
        <Typography variant="subtitle1">{FormatCurrency(insuranceTotal)}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" marginLeft={5} marginRight={5}>
        <Typography variant="subtitle1">{t('worksheet.totalAmountFinanced')} :</Typography>
        <Typography variant="subtitle1">{FormatCurrency(worksheet?.totalAmountFinanced)}</Typography>
      </Stack>
    </>
  )
}

export default LoanSummaryBox
