import {
  Brightness4 as Brightness4Icon,
  Brightness7 as Brightness7Icon,
  Checklist as ChecklistIcon,
  ViewList as ViewListIcon,
} from '@mui/icons-material'
import { AppBar, Container, IconButton, Link, Toolbar, useTheme } from '@mui/material'
import { useAppDispatch, useAppSelector } from '@src/data/store'
import { userActions, userSelectors } from '@src/data/store/UserStore'
import AppMenu from './components/AppMenu'
import CompanySelector from './components/CompanySelector'
import LanguagePicker from './components/LanguagePicker'
import ReportMenu from './components/ReportMenu'
import UserMenu from './components/UserMenu'

interface Props {
  hasAlertBanner?: boolean
}

const Layout = ({ hasAlertBanner = false }: Props): JSX.Element => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isDarkMode = useAppSelector(userSelectors.isDarkModeEnabled)

  return (
    <AppBar
      position="sticky"
      sx={{ backgroundColor: 'rgb(0, 0, 89)', color: 'white', top: hasAlertBanner ? 48 : 0, mb: 10 }}
    >
      <Container maxWidth="xl" disableGutters>
        <Toolbar disableGutters>
          <div className="icebergBanner">
            <img src="/images/iceberg-no-bg.png" alt="Iceberg Finance" style={{ width: '110px', height: '40px' }} />
          </div>
          <Link href="/">
            <IconButton>
              <ViewListIcon sx={{ color: '#ffffff' }} />
            </IconButton>
          </Link>
          <Link href="/creditTasksDashboard">
            <IconButton>
              <ChecklistIcon sx={{ color: '#ffffff' }} />
            </IconButton>
          </Link>
          <span style={{ flexGrow: 1 }}>&nbsp;</span>
          <CompanySelector />
          <AppMenu />
          <ReportMenu />
          <UserMenu />
          <LanguagePicker />
          <IconButton onClick={() => dispatch(userActions.setDarkModePreference(!isDarkMode))}>
            {theme.palette.mode === 'dark' ? (
              <Brightness7Icon sx={{ color: '#ffffff' }} />
            ) : (
              <Brightness4Icon sx={{ color: '#ffffff' }} />
            )}
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Layout
