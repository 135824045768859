import { Link, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ApplicantExpenses, CreditApplication } from '../../../data/types'
import { EApplicantRefusalReason, EDecision } from '../../../data/types/constants'
import { FormatCurrency } from '../../../services/Formatter'
import DecisonIcon from './decisonIcon'

interface ResumeProps {
  suggestedLoanAmount: number
  decision: string | undefined
  score: number | null | undefined
  label: string
  interestRate: number | null | undefined
  totalDebtRatio: number | null
  traceId: string | undefined
  decisionText: string
  expenses: Partial<ApplicantExpenses>
  userCanReadTraces: boolean
}

const Resume = (props: ResumeProps): JSX.Element => {
  const {
    decision,
    score,
    interestRate,
    label,
    totalDebtRatio,
    traceId,
    suggestedLoanAmount,
    decisionText,
    expenses,
    userCanReadTraces,
  } = props
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="subtitle1" component="p">
        {label}: {decisionText}
      </Typography>
      {decision && (
        <Typography
          variant="subtitle1"
          sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
          component="p"
        >
          {interestRate !== null && (
            <span style={{ marginRight: 15 }}>
              {t('credit.interestRate')}: {interestRate}%
            </span>
          )}
          Score: {score}
        </Typography>
      )}
      <Typography
        variant="subtitle1"
        sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
        component="p"
      >
        {t('credit.suggestedLoanAmount')}: ${suggestedLoanAmount}
      </Typography>

      {(expenses.rentMonthly ?? 0) > 0 && (
        <Typography
          variant="subtitle1"
          sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
          component="p"
        >
          {t('editCreditApplication.expenses.rentMonthlyPayment')} : {FormatCurrency(expenses.rentMonthly)}
        </Typography>
      )}

      {(expenses.mortgageMonthly ?? 0) > 0 && (
        <Typography
          variant="subtitle1"
          sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
          component="p"
        >
          {t('editCreditApplication.expenses.mortgageMonthlyPayment')} : {FormatCurrency(expenses.mortgageMonthly)}
        </Typography>
      )}

      {totalDebtRatio != null && (
        <Typography
          variant="subtitle1"
          sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
          component="p"
        >
          {t('credit.tds')}: {totalDebtRatio.toFixed(2)}
        </Typography>
      )}

      {decision && traceId && userCanReadTraces && (
        <Typography
          variant="subtitle1"
          sx={{ backgroundColor: theme.palette.action.selected, mt: theme.spacing(1) }}
          component="p"
        >
          <Link href={`https://tree-editor.icebergfinance.ca/decision-tree/trace/${traceId}`}>Trace</Link>
        </Typography>
      )}
    </>
  )
}

interface Props {
  creditApplication: CreditApplication
  userCanReadTraces: boolean
}

const IFiScore = ({ creditApplication, userCanReadTraces }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const refusalReasons = creditApplication.finalCreditDecision.refusalReasonCodes

  const getDecisionText = (applicant: boolean) => {
    const isReportMissing = refusalReasons.includes(
      applicant
        ? EApplicantRefusalReason.ApplicantMustHaveCreditReport
        : EApplicantRefusalReason.CoapplicantMustHaveCreditReport,
    )

    const decision = (applicant ? creditApplication.applicant : creditApplication.coApplicant)?.creditDecision?.decision

    return String(isReportMissing ? t('credit.noReport') : t(`credit.${decision ?? EDecision.pending}`))
  }

  const getScoreIcon = () => {
    if (!creditApplication.applicant.creditDecision) return EDecision.manualRevision

    const applicantRefusalReasons = creditApplication.applicant.creditDecision.refusalReasonCodes

    return applicantRefusalReasons.includes(EApplicantRefusalReason.IceScoreTooLow)
      ? EDecision.declined
      : EDecision.accept
  }

  return (
    <>
      <Typography variant="h6" component="h3" gutterBottom>
        iFi Score
      </Typography>
      <DecisonIcon decision={getScoreIcon()} />
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: theme.spacing(1) }} component="p">
        {t('credit.requestedLoanAmount')}: ${creditApplication?.requestedLoanAmount ?? 0}
      </Typography>
      <Resume
        decision={creditApplication.applicant.creditDecision?.decision}
        suggestedLoanAmount={creditApplication?.applicant?.creditDecision?.maxAmountFinanced ?? 0}
        score={creditApplication.applicant.creditDecision?.score}
        interestRate={
          creditApplication.applicant.creditDecision?.decision !== EDecision.declined
            ? creditApplication.applicant.creditDecision?.interestRate
            : null
        }
        label={t('common.applicant')}
        totalDebtRatio={creditApplication.applicant.creditDecision?.totalDebtService?.totalDebtRatioBeforeNewLoan}
        traceId={creditApplication.applicant.creditDecision?.decisionTraceId}
        decisionText={getDecisionText(true)}
        expenses={creditApplication.applicant.expenses}
        userCanReadTraces={userCanReadTraces}
      />
      {creditApplication.coApplicant && (
        <Resume
          decision={creditApplication.coApplicant.creditDecision?.decision}
          suggestedLoanAmount={creditApplication?.coApplicant?.creditDecision?.maxAdditionalAmount ?? 0}
          score={creditApplication.coApplicant.creditDecision?.score}
          interestRate={null}
          label={t('common.coApplicant')}
          totalDebtRatio={creditApplication.coApplicant.creditDecision?.totalDebtService?.totalDebtRatioBeforeNewLoan}
          traceId={creditApplication.coApplicant.creditDecision?.decisionTraceId}
          decisionText={getDecisionText(false)}
          expenses={creditApplication.coApplicant.expenses}
          userCanReadTraces={userCanReadTraces}
        />
      )}
    </>
  )
}

export default IFiScore
