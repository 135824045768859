import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '../../../services/Formatter'

interface Props {
  totalItems: number
}

const CustomToolBar = ({ totalItems }: Props) => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{ justifyContent: 'space-between', width: '100%', pl: 2, pr: 2 }}
      flexDirection="row"
      display="flex"
      alignItems="center"
    >
      <Typography variant="subtitle2">
        {t('taskManager.totalNumber')}: {formatNumber(totalItems)}
      </Typography>
    </Box>
  )
}

export default CustomToolBar
